import {CLAIM_STATUS} from './circulation-item/circulation-item-claimed-returned';

export class Checkout {
  id: number;

  checkoutId: number;
  catalogRecordId: number;
  checkoutTime: string;
  dueDate: string;
  lastRenewal: string;
  checkoutBranchName: string;
  status: CHECKOUT_STATUS;
  dueDays: number;

  patronFirstName: string;
  patronLastName: string;

  itemTitle: string;
  itemMediaType?: string;
  itemBarcode: string;

  claimId?: number;
  claimType?: CLAIM_STATUS;
  foundDate?: string;

  holdExists?: boolean;
}

export enum CHECKOUT_STATUS {
  OVERDUE = 'OVERDUE',
  DUE = 'DUE',
  CHECKED_IN = 'CHECKED_IN',
  CLAIMED_RETURNED = 'CLAIMED_RETURNED',
  CLAIMED_FOUND = 'CLAIMED_FOUND',
  CLAIMED_LOST = 'CLAIMED_LOST',
  CLAIMED_LOST_PAID = 'CLAIMED_LOST_PAID',
}
