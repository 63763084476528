import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {take} from 'rxjs/operators';
import {GlobalState, Organization, selectOrganization} from '@raven';

@Component({
  selector: 'rn-contact-us-dialog',
  template: `
    <mat-dialog-content class="dialog">
      <h2 mat-dialog-title>Contact Us</h2>
      Call a librarian at {{organization.phoneNumber}}
      <br />
      <br />
      Or, email us at <a href="mailto:{{organization.contactEmail}}">{{organization.contactEmail}}</a>
      <mat-card-actions class="flex-row">
        <button mat-dialog-close="true" mat-raised-button color="primary" class="ButtonSmallBlack save-button-group save-button">
          Close
        </button>
      </mat-card-actions>
    </mat-dialog-content>
  `,
  styles: [
    `
      .mat-dialog-content {
        max-width: 320px;
        padding: 40px 10vw 30px;
        font-family: Maax, Roboto, Franklin Gothic Medium, Tahoma, sans-serif;

        h2 {
          font-family: P22Mackinac-Medium_6;
        }
      }

      mat-card-actions {
        margin-top: 60px;
      }
    `,
  ],
})
export class ContactUsDialogComponent implements OnInit {
  organization: Organization;

  constructor(
    public dialogRef: MatDialogRef<ContactUsDialogComponent>,
    private store: Store<GlobalState>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      message: string;
      confirmButton: string;
      cancelButton: string;
    }
  ) {
  }

  ngOnInit() {
    this.store.select(selectOrganization)
      .pipe(take(1))
      .subscribe((organization) => {
        this.organization = organization;
      });
  }
}
