<div class="registration-page flex-row flex-start-start">
  <div class="main-panel flex-1">
    <div *ngIf="!isLoading; else loading_spinner">
      <form [formGroup]="notificationPreferencesForm" novalidate>
        <mat-stepper [labelPosition]="'bottom'" linear="true" selectedIndex="4">
          <mat-step label="Select Library" completed="true" editable="false"></mat-step>
          <mat-step label="Library Account" completed="true" editable="false"></mat-step>
          <mat-step label="Verify Email" completed="true" editable="false"></mat-step>
          <mat-step label="Personal Information" completed="true" editable="false"></mat-step>
          <mat-step label="Notifications" optional="false" completed="false">
            <div *ngIf="!isLoading; else loading_spinner" style="margin-left: 10px" class="registration-form flex-col">
              <div class="mat-display-1 registration-title">
                Notification Preferences
              </div>
              <h4>
                Please select your notification preferences for your library
                account.<br/>
                Fields marked with an asterisk (*) are requested.
              </h4>
              <br/>
              <p>Select an option for library notifications*</p>
              <p>Email</p>
              <div [ngClass]="[
                    notificationPreferencesForm.value.byEmail
                      ? 'notification-option-checked'
                      : 'notification-option-unchecked']"
                   (click)="toggleNotification('byEmail')" class="pointer">
                <mat-icon>check</mat-icon>
                <span>{{ patron.email }}</span>
              </div>
              <p style="margin-top: 21px">Mobile SMS</p>
              <div [ngClass]="[
                    notificationPreferencesForm.value.bySMS
                      ? 'notification-option-checked'
                      : 'notification-option-unchecked']"
                   (click)="toggleNotification('bySMS')" class="pointer">
                <mat-icon>check</mat-icon>
                <input type="text" maxlength="20" (click)="$event.stopPropagation()" formControlName="phoneNumber"
                       class="notification-phone-input" phone="US" [ngClass]="[
                      notificationPreferencesForm.get('phoneNumber').valid
                        ? ''
                        : 'notification-input-error']"/>
              </div>
              <br/>
              <div class="error-text-container notification-input-error">&nbsp;
                <!--                  {{notificationPreferencesForm.get('phoneNumber').errors | json}}-->
                <div *ngIf="notificationPreferencesForm.get('phoneNumber').invalid">
                  {{getErrorMessage('phone number', notificationPreferencesForm.get('phoneNumber'))}}
                </div>
              </div>
              <br/>
              <div class="spacer hide-gt-xs"></div>
              <button mat-raised-button color="primary" class="complete-registration-button" [disabled]="!isFormValid()" (click)="submit()">
                Complete Registration
              </button>
            </div>
            <ng-template #loading_spinner>
              <div class="flex-container">
                <mat-spinner></mat-spinner>
              </div>
            </ng-template>
          </mat-step>
        </mat-stepper>
      </form>
    </div>
    <ng-template #loading_spinner>
      <div class="flex-container">
        <mat-spinner></mat-spinner>
      </div>
    </ng-template>
  </div>
  <rn-registration-side-panel></rn-registration-side-panel>
</div>
