import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarRef, SimpleSnackBar} from '@angular/material/snack-bar';
import {Environment} from '../model/environment';
import {SnackbarNotificationComponent} from '../core/shared/snackbar-notification.component';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {

  constructor(private snackBar: MatSnackBar,
              private environment: Environment) {
  }

  showSnackbarSuccess(message: string, action?: string): MatSnackBarRef<SimpleSnackBar> {
    return this.snackBar.openFromComponent(SnackbarNotificationComponent, {
      data: {
        message: message,
        type: 'success',
        action: action
      },
      panelClass: ['snackbar-success'],
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: this.environment.snackBarTimeout,
    });
  }

  showSnackbarWarning(message: string, action?: string): MatSnackBarRef<SimpleSnackBar> {
    return this.snackBar.openFromComponent(SnackbarNotificationComponent, {
      data: {
        message: message,
        type: 'warn',
        action: action
      },
      panelClass: ['snackbar-warn'],
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: this.environment.snackBarTimeout,
    });
  }

  showSnackbarError(message: string, action?: string): MatSnackBarRef<SimpleSnackBar> {
    return this.snackBar.openFromComponent(SnackbarNotificationComponent, {
      data: {
        message: message,
        type: 'error',
        action: action
      },
      panelClass: ['snackbar-error'],
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: this.environment.snackBarTimeout,
    });
  }

  showSnackbarInfo(message: string, action?: string): MatSnackBarRef<SimpleSnackBar> {
    return this.snackBar.openFromComponent(SnackbarNotificationComponent, {
      data: {
        message: message,
        type: 'info',
        action: action
      },
      panelClass: ['snackbar-info'],
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: this.environment.snackBarTimeout,
    });
  }
}
