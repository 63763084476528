<div class="facet-collection-wrapper">
  <rn-expansion-panel *ngIf="facets['materialTypeIds'] && facets['materialTypeIds'].values?.length > 0">
    <h4 expansionHeader>Material Type</h4>
    <rn-catalog-facets [facet]="facets['materialTypeIds']" [searchTrigger]="filterChanged"></rn-catalog-facets>
  </rn-expansion-panel>
  <rn-expansion-panel *ngIf="facets['genres.keyword'] && facets['genres.keyword'].values?.length > 0">
    <h4 expansionHeader>Genres</h4>
    <rn-catalog-facets [facet]="facets['genres.keyword']" [searchTrigger]="filterChanged"></rn-catalog-facets>
  </rn-expansion-panel>
  <rn-expansion-panel *ngIf="facets['subjects.keyword'] && facets['subjects.keyword'].values?.length > 0">
    <h4 expansionHeader>Subjects</h4>
    <rn-catalog-facets [facet]="facets['subjects.keyword']" [searchTrigger]="filterChanged"></rn-catalog-facets>
  </rn-expansion-panel>
  <rn-expansion-panel *ngIf="facets['branchIds'] && facets['branchIds'].values?.length > 0">
    <h4 expansionHeader>Branches</h4>
    <rn-catalog-facets [facet]="facets['branchIds']" [searchTrigger]="filterChanged"></rn-catalog-facets>
  </rn-expansion-panel>
  <rn-expansion-panel *ngIf="facets['author.keyword'] && facets['author.keyword'].values?.length > 0">
    <h4 expansionHeader>Author</h4>
    <rn-catalog-facets [facet]="facets['author.keyword']" [searchTrigger]="filterChanged"></rn-catalog-facets>
  </rn-expansion-panel>
  <rn-expansion-panel *ngIf="facets['publicationDate.keyword'] && facets['publicationDate.keyword'].values?.length > 0">
    <h4 expansionHeader>Publication Date</h4>
    <rn-catalog-facets [facet]="facets['publicationDate.keyword']" [searchTrigger]="filterChanged"></rn-catalog-facets>
  </rn-expansion-panel>

  <rn-expansion-panel [isCollapsed]="true">
    <h4 expansionHeader>Expanded Search</h4>
    <rn-catalog-expanded-search-facet [(showExpandedSearch)]="showExpandedSearch"
                                      [facet]="{name: 'Expanded Search', values: [], type: CatalogFacetType.INTERSECTION}"></rn-catalog-expanded-search-facet>
  </rn-expansion-panel>
</div>