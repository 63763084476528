import {NgModule} from '@angular/core';
import {PatronCheckoutsComponent} from './patron-checkouts.component';
import {PatronBillingModule} from '../patron-billing/patron-billing.module';
import {ClaimReturnedDialog} from './dialogs/claims-dialog';
import {PatronCheckoutStatusComponent} from './patron-checkout-status.component';
import {CheckoutStatusFilterComponent} from './components/checkouts-filter-component/checkout-status-filter.component';
import {SharedModule} from "../shared/shared.module";

@NgModule({
  imports: [SharedModule, PatronBillingModule],
  exports: [PatronCheckoutsComponent],
  declarations: [
    PatronCheckoutsComponent,
    PatronCheckoutStatusComponent,
    ClaimReturnedDialog,
    CheckoutStatusFilterComponent,
  ],
})
export class CheckoutsModule {
}
