import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {environment} from '../environments/environment';
import {HttpErrorInterceptor} from './core/http/http-error.interceptor';
import {LoginComponent} from './core/login/login.component';
import {LogoutComponent} from './core/login/logout.component';
import {JWT_OPTIONS, JwtConfig, JwtModule} from '@auth0/angular-jwt';
import {BrowserModule, DomSanitizer} from '@angular/platform-browser';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {StoreModule} from '@ngrx/store';
import {AppLayoutComponent} from './_layout/app-layout.component';
import {AppRoutingModule} from './app.routes';
import {IndexComponent} from './core/index/index.component';
import {RoutesModule} from './core/routes/routes.module';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE,} from '@angular/material/core';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {NonBreakingPipe} from "./core/shared/pipes/non-breaking.pipe";
import {AuthService} from './core/authentication/auth.service';
import {PatronBillingModule} from './core/patron-billing/patron-billing.module';
import {HoldsModule} from './core/holds/holds.module';
import {VerificationModule} from './core/verification/verification.module';
import {RegistrationModule} from './core/registration/registration.module';
import {APOLLO_OPTIONS} from 'apollo-angular';
import {HttpLink} from 'apollo-angular/http';
import {InMemoryCache} from '@apollo/client/core';
import {CurrencyPipe, DatePipe, TitleCasePipe} from '@angular/common';
import {LandingComponent} from './core/login/landing.component';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {AngularFireAuthGuardModule} from '@angular/fire/compat/auth-guard';
import {CheckoutsModule} from './core/patron-checkouts/checkouts.module';
import {ProfileModule} from './core/profile/profile.module';
import {CatalogModule} from './core/catalog/catalog.module';
import {FavoritesModule} from './core/favorites/favorites.module';
import {ErrorModule} from './core/error-pages/error.module';
import {ItemRequestsModule} from './core/item-requests/item-requests.module';
import {SharedModule} from './core/shared/shared.module';
import {PatronNotificationsModule} from './core/patron-notfications/patron-notifications.module';
import {ForgotPasswordDialogComponent} from './core/login/forgot-password-dialog.component';
import {HttpHeaderInterceptor} from './core/http/http.headers.interceptor';
import {SnackbarNotificationComponent} from './core/shared/snackbar-notification.component';
import {Environment} from './model/environment';
import {OrganizationService} from './services/organization.service';
import {Organization} from './model/organization';
import {globalReducer, initialState} from '@raven';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {firstValueFrom} from "rxjs";

// jwt config
export function jwtOptionsFactory(authService: AuthService): JwtConfig {
  return {
    tokenGetter: () => authService.getAsyncToken(),
    allowedDomains: [
      'localhost:9142',
      environment.apiUrl.split('/')[2], // just the domain, remove "https://" and "/api"
    ],
  };
}

export function setupOrganization(organizationService: OrganizationService,
                                  authService: AuthService) {
  return () => {
    return firstValueFrom(organizationService.setupOrganization())
      .then((org: Organization) => {
        return authService.firebaseAuthInitialized.then(() => {
          authService.setFirebaseTenant(org.googlePatronTenantId);
        });
      });
  }
}

export function firebaseAuthInitialized(authService: AuthService) {
  return () => authService.firebaseAuthInitialized;
}

// // sentry config
// Sentry.init({
//     dsn: 'https://9c1e3beaa5754f5d8d14344dbee0722d@o462186.ingest.sentry.io/5647873',
//     environment: environment.name,
//     beforeSend(event: Event, hint?: EventHint): PromiseLike<Event | null> | Event | null {
//         // don't report in the development environments
//         if (isProductionOrStaging()) {
//             // just return the even as is
//             return event;
//         }
//         // else return null to skip sending / processing
//         return event;
//     },
//     maxBreadcrumbs: 25,
//     integrations: [
//         new Integrations.BrowserTracing({
//           tracingOrigins: ['localhost', environment.apiUrl],
//           routingInstrumentation: Sentry.routingInstrumentation,
//         }),
//         // don't log this in development
//         new Sentry.Integrations.Breadcrumbs({console: isProductionOrStaging()})
//     ],
//
//     // We recommend adjusting this value in production, or using tracesSampler
//     // for finer control
//     sampleRate: 0.5,
//     tracesSampleRate: 0.5,
// });

function isProductionOrStaging(): boolean {
  return environment.name === 'stage' || environment.name === 'prod';
}

@NgModule({
  imports: [
    BrowserModule,
    // JWT support
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [AuthService],
      },
    }),
    // ngrx stuff
    // NxModule.forRoot(),
    StoreModule.forRoot({app: globalReducer}, {initialState}),
    !environment.production
      ? StoreDevtoolsModule.instrument({
        maxAge: 25,
      })
      : [],
    HttpClientModule,
    // FIREBASE
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireAuthGuardModule,
    // Google Analytics
    //AngularFireAnalyticsModule,
    //AngularFirePerformanceModule,
    // our stuff
    CatalogModule,
    PatronBillingModule,
    PatronNotificationsModule,
    ItemRequestsModule,
    HoldsModule,
    CheckoutsModule,
    RegistrationModule,
    ProfileModule,
    FavoritesModule,
    RoutesModule,
    AppRoutingModule,
    ErrorModule, // global routing
    VerificationModule,
    SharedModule,
    // Core Modules
    MatIconModule,
  ],
  declarations: [
    AppComponent,
    AppLayoutComponent,
    IndexComponent,
    LandingComponent,
    LoginComponent,
    LogoutComponent,
    ForgotPasswordDialogComponent,
    SnackbarNotificationComponent
    // PageNotFoundComponent
  ],
  providers: [
    //PerformanceMonitoringService,
    //UserTrackingService,
    //ScreenTrackingService,
    DatePipe,
    CurrencyPipe,
    TitleCasePipe,
    NonBreakingPipe,
    {provide: Environment, useValue: environment},
    {provide: HTTP_INTERCEPTORS, useClass: HttpHeaderInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    {
      provide: ErrorHandler,
      // useValue: Sentry.createErrorHandler({showDialog: true}),
    },
    // {provide: Sentry.TraceService, deps: [Router]},
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      // deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: setupOrganization,
      deps: [OrganizationService, AuthService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (authService) => () => authService.initializePatronLogin(),
      deps: [AuthService],
      multi: true,
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: environment.graphUrl,
          }),
        };
      },
      deps: [HttpLink],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(iconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    iconRegistry.addSvgIconSet(
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg')
    );
  }
}
