import {Component, Input, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges, ViewChildren} from '@angular/core';
import {CatalogFacets, CatalogFacetType} from '@raven';
import {CatalogFacetsComponent} from '../catalog-facets/catalog-facets.component';
import {Subject, takeUntil} from 'rxjs';
import {CatalogBrowseService} from '../../catalog-browse.service';

@Component({
  selector: 'rn-catalog-facet-collection',
  templateUrl: './catalog-facet-collection.component.html',
  styleUrls: ['./catalog-facet-collection.component.scss'],
})
export class CatalogFacetCollectionComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  facets: CatalogFacets;

  @ViewChildren(CatalogFacetsComponent)
  facetComponents: QueryList<CatalogFacetsComponent>;

  filterChanged = new Subject<boolean>();
  destroy$ = new Subject();
  showExpandedSearch = false;
  CatalogFacetType = CatalogFacetType;


  constructor(
    private catalogBrowseService: CatalogBrowseService,
  ) {
  }

  ngOnInit(): void {
    this.filterChanged.pipe(
      takeUntil(this.destroy$),
    )
      .subscribe(() => {
        const filters = this.filtersFromFacets();
        this.catalogBrowseService.setPage(0);
        this.catalogBrowseService.updateFilters(filters);
      })
  }

  ngOnChanges(changes: SimpleChanges) {
    CatalogFacetCollectionComponent.sortFacets(this.facets);
  }

  private static sortFacets(facets: CatalogFacets) {
    for (const k of Object.keys(facets)) {
      facets[k].values.sort((a, b) => {
        return (+(b.selected ?? 0) - +(a.selected ?? 0))
          || b.count - a.count
          || a.displayValue.localeCompare(b.displayValue)
      });
    }
  }

  filtersFromFacets(): CatalogFacets {
    const r: CatalogFacets = {};
    if (!this.facetComponents) {
      return r;
    }
    for (const f of this.facetComponents) {
      Object.assign(r, f.toFilterState());
    }
    return r;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
