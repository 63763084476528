<div class="payment-info-container">
  <h3 class="title-text">Payment Methods</h3>
  <div class="cards-row">

    <ng-container *ngIf="(paymentMethods$ | async) as paymentMethods else loadingTemplate">
      <div class="add-payment-method">
        <div class="flex-row flex-center-space-between">
          <span class="add-method-text">Payment Method</span>
          <mat-icon>credit_card</mat-icon>
        </div>
        <button mat-raised-button color="primary" class="add-method-button" (click)="openAddCardDialog()">+ Add</button>
      </div>
      <ng-container *ngFor="let card of paymentMethods; let i = index">
        <rn-payment-method [details]="card" [isPrimary]="i==0"></rn-payment-method>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #loadingTemplate>
  <div class="flex-container">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
