import {distinct, EMPTY, firstValueFrom, of, share, Subject, switchMap, takeUntil} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {Injectable, OnDestroy} from '@angular/core';
import {Store} from '@ngrx/store';
import firebase from 'firebase/compat/app';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {Environment, GlobalState, NotificationService, PatronService} from '@raven';
import User = firebase.User;

/**
 * PATRON specific auth service for OPAC
 * authentication is a two step process
 *
 * 1) first hit the Google IDP end point, which will give use a JWT token
 * 2) if successful, look up the patron profile information with said token
 * 3) store it all in local storage
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // store the URL so we can redirect after logging in
  redirectUrl: string;

  private firebaseUser: User = null;
  private tenant: string;
  public firebaseAuthInitialized: Promise<User>;
  private destroy$ = new Subject<boolean>();

  //  Auth related things that happen during startup
  //* Firebase initialization - Initial setup is synchronous but getting a meaningful
  //  auth state / user info is asynchronous, need to wait for first angularFireAuth.user
  //  value to know that the auth part of firebase is actually initialized. Since this involves
  //  a network call the timing varies significantly
  //* AuthService - This service gets created when it's found in the dependency graph. Setup
  //  includes listening to auth state changes and updating the corresponding patron object.
  //  Also sets up a promise that resolves once the angularFireAuth.user observable emits, aka
  //  firebase.auth() is usable/initialized
  //* APP_INITIALIZERs - These live in app.modules.ts and run before app.component.ts gets created.
  //  firebaseAuthInitialized is used to wait for firebase auth init
  //  setupOrganization fetches the organization for the server based on URL. The resulting organization
  //  object is also used to set the tenantId for firebase. The tenant is set through AuthService since
  //  it can be guaranteed to exist at call time while firebase.auth() may not be setup in time
  //* AppComponent constructor/OnInit - currently unused
  constructor(private environment: Environment,
              private patronService: PatronService,
              private store: Store<GlobalState>,
              private angularFireAuth: AngularFireAuth,
              private notificationService: NotificationService) {

    const firebaseUser$ = angularFireAuth.user.pipe(
      distinct(), // logout spam from the login component init fires multiple nulls
      tap(firebaseUser => {
        if (this.tenant && !firebase.auth().tenantId) { // this should never happen
          console.log('App initialization should have already set the tenant!', firebase.auth().tenantId);
          firebase.auth().tenantId = this.tenant;
        }

        console.log('firebaseUser$: ', firebaseUser);
        this.firebaseUser = firebaseUser;
      }),
      // otherwise, multiple subscriptions run the tap again
      share(),
    );

    this.firebaseAuthInitialized = firstValueFrom(firebaseUser$);

    const patron$ = firebaseUser$.pipe(
      switchMap((firebaseUser) => firebaseUser ? this.patronService.getPatron() : of(null)),
      catchError(() => {
        console.log('Error fetching patron for valid firebaseUser');
        return EMPTY;
      }),
      takeUntil(this.destroy$),
    );
    patron$.subscribe();
  }

  // called during app initialization in app.module.ts
  initializePatronLogin() {
    const patron$ = this.angularFireAuth.user.pipe(
      switchMap((firebaseUser) => firebaseUser ? this.patronService.getPatron() : of(null)),
      catchError(() => {
        console.log('Error fetching patron for valid firebaseUser');
        this.logout().then(() => {
          window.location.href = '/logout';
        });
        return EMPTY;
      }),
    );

    return firstValueFrom(patron$);
  }

  setFirebaseTenant(tenant: string): void {
    this.tenant = tenant;
    // I think this is guaranteed true now that we block on 'firebaseAuthInitialized' in app.module
    if (firebase.apps && firebase.apps.length > 0) { // firebase is setup
      firebase.auth().tenantId = tenant;
    } else {
      console.log('App initialization failed to set firebase tenant!!');
    }
  }

  async login(email: string, password: string): Promise<boolean> {
    const firebaseAuth = await this.angularFireAuth.signInWithEmailAndPassword(email, password)
      .catch(() => {
        this.notificationService.showSnackbarError('Unable to login, please check your password and try again');
      });
    if (!firebaseAuth) {
      return false;
    }

    // Set directly since the angularfire.user observable doesn't fire in time for it to be updated for the next call
    this.firebaseUser = firebase.auth().currentUser;

    // Technically before this here change we were getting PARTWAY logged in (firebase, no patron fetch) then
    // returning a promise to finish fetching the patron, and then MAYBE logging out again if that didn't go well,
    // but only after this function actually returned.  This way we block in here and don't return a promise until
    // AFTER we've had a chance to resolve that inconsistent state.
    try {
      const patron = await firstValueFrom(this.patronService.getPatron());
      this.patronService.patron = patron;
      // probably this belongs here, but the observable chain in the constructor takes care of it for now...
      // this.updatePatron$(patron);
      return !!patron;
    } catch (e) {
      console.log('Failed to fetch patron after successful firebase login, logging out', this.firebaseUser);
      await this.logout();
      return false;
    }
  }

  // --
  async logout(): Promise<void> {
    await this.angularFireAuth.signOut();
  }

  // used by the Auth0 jwt library to set the auth bearer token
  public getAsyncToken(): Promise<string> {
    return Promise.resolve(this.firebaseUser ? this.firebaseUser.getIdToken() : null);
  }

  public isAuthenticated(): boolean {
    return !!this.patronService.patron;
  }

  // Leaving this here for now for comparison to the new version below
  public __updatePassword(
    currentPassword: string,
    newPassword: string
  ): Promise<string> {
    const credential = firebase.auth.EmailAuthProvider.credential(
      this.patronService.patron.email,
      currentPassword
    );
    return new Promise<string>((resolve, reject) => {
      if (this.firebaseUser) {
        this.firebaseUser
          .reauthenticateWithCredential(credential)
          .catch((error) => {
            reject(
              'Could not authenticate, please check your password and try again'
            );
          })
          .then(() => {
            this.firebaseUser
              .updatePassword(newPassword)
              .catch((error) => {
                reject('Unable to update password, please try again later');
              })
              .then(() => {
                resolve('Password successfully updated');
              });
          });
      } else {
        reject('User not logged in');
      }
    });
  }

  public async updatePassword(currentPassword: string, newPassword: string): Promise<string> {
    if (!this.firebaseUser) throw 'User not logged in';

    const credential = firebase.auth.EmailAuthProvider.credential(this.patronService.patron.email, currentPassword);
    try {
      await this.firebaseUser.reauthenticateWithCredential(credential);
    } catch {
      throw 'Could not authenticate, please check your password and try again';
    }

    try {
      await this.firebaseUser.updatePassword(newPassword)
    } catch {
      throw 'Unable to update password, please try again later';
    }

    return 'Password successfully updated';
  }

  async sendPasswordReset(email: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.angularFireAuth.sendPasswordResetEmail(email)
        .then(() => {
          resolve(true)
        })
        .catch((err) => {
          if (err.code === 'auth/user-not-found') {
            this.notificationService.showSnackbarError('Email address does not match an existing account.');
          } else if (err.code === 'auth/invalid-email') {
            this.notificationService.showSnackbarError('Invalid email.');
          } else {
            this.notificationService.showSnackbarError('Internal error');
          }
          resolve(false);
        });
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
