<div *ngIf="mobile" class="flex-row flex-center-start" style="margin-top: 8px">
  <rn-availability-count-pill [available]="branchAvailability.availableCount" [copies]="branchAvailability.totalCopies"></rn-availability-count-pill>
</div>
<table mat-table aria-label="Item Availability" class="teal-background" [dataSource]="branchAvailability.circulationItems">
  <ng-container matColumnDef="call-number">
    <th mat-header-cell *matHeaderCellDef scope="col">Call Number</th>
    <td mat-cell *matCellDef="let c">C123-456-789-0123-987654</td>
  </ng-container>
  <ng-container matColumnDef="shelf">
    <th mat-header-cell *matHeaderCellDef scope="col">Shelf Location</th>
    <td mat-cell *matCellDef="let c">
      Summer Picks
    </td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef scope="col">Status</th>
    <td mat-cell *matCellDef="let c">
      <rn-circulation-item-status-pill [item]="c"></rn-circulation-item-status-pill>
    </td>
  </ng-container>
  <ng-container matColumnDef="mobile-expand">
    <th mat-header-cell *matHeaderCellDef scope="col"></th>
    <td mat-cell *matCellDef="let c">
      <span class="mobile-detail-data-type">Call Number: </span>C123-456-789-0123-987654<br>
      <span class="mobile-detail-data-type">Shelf Location: </span>Summer Picks<br>
      <div class="flex-row">
        <span class="mobile-detail-data-type">Status: </span>
        <rn-circulation-item-status-pill [item]="c" style="margin-left: 8px;"></rn-circulation-item-status-pill>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="defaultColumns" class="mx-bold-underline"></tr>
  <!--  <tr mat-row *matRowDef="let c; columns: ['mobile-expand']; when: mobileWhen" [ngClass]="{'no-border': c.id === lastCircItemId}"></tr>-->
  <tr mat-row *matRowDef="let c; columns: displayColumns" [ngClass]="{'no-border': c.id === lastCircItemId, 'mobile-row': mobile}"></tr>


</table>
