<ng-container
        *ngTemplateOutlet="grid; context: { items: items }"
></ng-container>

<ng-template #grid let-items="items">
  <div class="item-grid">
    <ng-container *ngFor="let item of items">
      <rn-grid-item [catalogRecord]="item"></rn-grid-item>
    </ng-container>
  </div>
</ng-template>
