import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from '@angular/router';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  filter,
  first,
  Observable,
  share,
  Subject,
  switchMap,
  takeUntil
} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import moment from 'moment';
import {
  CommentService,
  ITEM_REQUEST_STATUS,
  ItemRequestService,
  NotificationService,
  PatronItemRequest,
  PatronItemRequestComment,
  PatronItemRequestService,
  PatronService
} from '@raven';
import {DeleteItemRequestDialog} from "../dialogs/delete-item-request-dialog";

@Component({
  selector: 'rn-item-request-details',
  templateUrl: './item-request-details.component.html',
  styleUrls: ['./item-request-details.component.scss'],
})
export class ItemRequestDetailsComponent implements OnInit, OnDestroy {
  ITEM_REQUEST_STATUS = ITEM_REQUEST_STATUS;

  patronItemRequestId: number;
  patronComment: string;

  data$: Observable<object>;
  patronItemRequest$: Observable<PatronItemRequest>;
  comments$: Observable<PatronItemRequestComment[]>;
  commentsSubject = new BehaviorSubject<PatronItemRequestComment[]>([]);
  comments: PatronItemRequestComment[];
  destroy$ = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private patronService: PatronService,
    private itemRequestService: ItemRequestService,
    private patronItemRequestService: PatronItemRequestService,
    private commentService: CommentService,
    private notificationService: NotificationService
  ) {
  }

  ngOnInit(): void {
    const patronItemRequestId$ = this.route.paramMap
      .pipe(takeUntil(this.destroy$))
      .pipe(map(paramMap => Number(paramMap.get('id'))))
      .pipe(filter(id => Number.isInteger(id)))
      .pipe(tap(id => this.patronItemRequestId = id));

    this.patronItemRequest$ = patronItemRequestId$
      .pipe(switchMap(id => this.patronItemRequestService.getPatronRequest(id)))
      .pipe(tap((request: PatronItemRequest) => {
        //sort comments here instead of w/ observable chain because newly added comments don't have a timestamp
        this.comments = request.comments.sort((a, b) => {
          const aVal = new Date(a.created).getTime();
          const bVal = new Date(b.created).getTime();
          return aVal - bVal;
        })
        this.commentsSubject.next(this.comments);
      }))
      .pipe(share());

    this.patronItemRequest$
      .pipe(
        filter(request => request.hasUnreadStaffComments),
        switchMap(request => this.patronItemRequestService.markStaffCommentsRead(request.id)),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.comments$ = this.commentsSubject.asObservable();

    this.data$ = combineLatest([
      this.patronItemRequest$,
      this.comments$,
    ]).pipe(
      map(([patronItemRequest, comments]) => {
        return {
          request: patronItemRequest,
          item: this.itemFromPatronRequest(patronItemRequest),
          comments: comments,
        };
      })
    );
  }

  itemFromPatronRequest(request: PatronItemRequest): object {
    return {
      title: request.itemTitle,
      author: request.author,
      pickupBranch: request.pickupBranch,
      publicationDate: request.publicationDate ? moment(request.publicationDate) : '',
      format: 'Not available', //TODO
      isbn: request.isbn,
      coverImageUrl:
        request.imageLinkLarge || request.imageLinkMedium || request.imageLinkSmall || '/assets/stock-book-image.jpg',
      coverImageUrls: [request.imageLinkLarge || request.imageLinkMedium || request.imageLinkSmall || '/assets/stock-book-image.jpg']
    };
  }

  deleteItemRequest(request: PatronItemRequest): void {
    const dialogRef = this.dialog.open(DeleteItemRequestDialog, {
      data: {itemRequests: [request], cantDelete: 0},
      maxWidth: '95vw',
    });
    dialogRef
      .afterClosed()
      .pipe(
        switchMap((deleteConfirmation: boolean) => {
          if (deleteConfirmation) {
            return this.patronItemRequestService.cancel(request.id);
          }
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.notificationService.showSnackbarSuccess(
          'Request successfully deleted'
        );
        this.router.navigate(['/patron-item-requests-v2']);
      });
  }

  postComment(): void {
    if (!this.patronComment || !this.patronComment.trim()) {
      return;
    }

    this.patronItemRequestService.createPatronComment(this.patronItemRequestId, this.patronComment)
      .pipe(first())
      .subscribe(result => {
        if (result) {
          this.comments.push({
            organizationId: this.patronService.patron.organizationId,
            patronId: this.patronService.patron.id,
            staffId: null,
            patronItemRequestId: this.patronItemRequestId,
            comment: this.patronComment
          });
          this.commentsSubject.next(this.comments);
          this.patronComment = '';
        }
      })
  }

  getPatronInitials(): string {
    const patron = this.patronService.patron;
    if (patron.firstName && patron.firstName.length > 0 && patron.lastName && patron.lastName.length > 0) {
      return (patron.firstName[0] + patron.lastName[0]).toUpperCase();
    }
    if (patron.firstName && patron.firstName.length > 1) {
      return (patron.firstName[0] + patron.firstName[1]).toUpperCase();
    }
    if (patron.lastName && patron.lastName.length > 1) {
      return (patron.lastName[0] + patron.lastName[1]).toUpperCase();
    }
    return 'PA';
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
