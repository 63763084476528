<div class="registration-page flex-row flex-start-start">
  <div class="main-panel flex-1">
    <mat-stepper [labelPosition]="'bottom'" [linear]="true">
      <mat-step label="Lookup Account" [optional]="false" completed="false">
        <div *ngIf="!isLoading; else loading_spinner">
          <form [formGroup]="existingCardForm" novalidate class="registration-form flex-col">
            <div class="mat-display-1 registration-title">
              Lookup Account
            </div>
            <h4 class="registration-subtitle">
              Please enter your barcode and pin to lookup your account and
              start the online account registration process
            </h4>
            <mat-label>Library Card Bar Code</mat-label>
            <mat-form-field appearance="outline">
              <input matInput type="text" formControlName="barcode"/>
              <mat-error *ngIf="existingCardForm.controls['barcode']?.hasError('required')">
                Library Card Number is required.
              </mat-error>
            </mat-form-field>
            <mat-label>PIN Number</mat-label>
            <mat-form-field appearance="outline">
              <input matInput type="password" formControlName="pin"/>
              <mat-error *ngIf="existingCardForm.controls['pin']?.hasError('required')">
                PIN is required.
              </mat-error>
            </mat-form-field>
            <br/>
            <div class="spacer hide-gt-xs"></div>
            <button mat-raised-button color="primary" class="form-submit-button" [disabled]="existingCardForm.invalid" (click)="continue()">
              Lookup Account
            </button>
            <div class="mat-small registration-hint">
              <a class="pointer" (click)="forgotPin()">Forgot Pin?</a>
            </div>
            <div class="mat-small registration-hint-2">
              Already have an online account? <a routerLink="/login">Login</a>
            </div>
          </form>
        </div>
        <ng-template #loading_spinner>
          <div class="loading-spinner flex-row flex-center-center">
            <mat-spinner></mat-spinner>
          </div>
        </ng-template>
      </mat-step>
      <mat-step label="Library Account" [optional]="false" completed="false">
        <div *ngIf="!isLoading; else loading_spinner">
          <form [formGroup]="accountDataForm" novalidate class="registration-form flex-col">
            <div class="mat-display-1 registration-title">
              Library account
            </div>
            <h4 class="registration-subtitle">
              Provide a username and password to create your library account.
            </h4>
            <mat-label>Email</mat-label>
            <mat-form-field [formGroup]="accountDataForm" appearance="outline">
              <input matInput type="email" name="email" formControlName="email" required="true" minlength="6" maxlength="512"/>
              <mat-hint>Your email is used to log into your account</mat-hint>
              <mat-error *ngIf="accountDataForm.get('email').hasError('email') || accountDataForm.get('email').hasError('pattern')">
                Invalid email format
              </mat-error>
              <mat-error *ngIf="accountDataForm.get('email').hasError('emailTaken')">
                An account with this email already exists. Go <a href="/login">here</a> to log-in
              </mat-error>
              <mat-error *ngIf="accountDataForm.get('email').hasError('required')">
                Email is required
              </mat-error>
              <mat-error *ngIf="accountDataForm.get('email').hasError('minlength')">
                Email must be at least 6 characters long
              </mat-error>
            </mat-form-field>
            <!-- Password -->
            <mat-label>Password</mat-label>
            <mat-form-field appearance="outline" [class.multi-line-error]="accountDataForm.get('password').hasError('pattern')">
              <input matInput type="password" formControlName="password" required maxlength="256"/>
              <mat-error *ngIf="accountDataForm.get('password').value.length == 0">Password is required</mat-error>
              <mat-error *ngIf="accountDataForm.get('password').hasError('pattern') && accountDataForm.get('password').value.length > 0">
                Password must be at least 8 characters and contain at
                least one lowercase letter, uppercase letter, and number
              </mat-error>
              <mat-hint align="end">{{ accountDataForm.get('password').value.length }} / 256</mat-hint>
            </mat-form-field>
            <!-- Password Confirmation -->
            <mat-label>Confirm Password</mat-label>
            <mat-form-field appearance="outline">
              <input matInput type="password" formControlName="passwordConfirm" required maxlength="256"/>
              <mat-error *ngIf="accountDataForm.get('passwordConfirm').hasError('required')">
                <ng-container>Please re-enter the password</ng-container>
              </mat-error>
              <mat-error *ngIf="accountDataForm.get('passwordConfirm').hasError('mismatchedPasswords')">
                <ng-container>Passwords do not match</ng-container>
              </mat-error>
            </mat-form-field>
            <br/>
            <div style="padding-left: 10px">
              <mat-checkbox matInput formControlName="acceptance"></mat-checkbox>
              <span style="padding-left: 16px">I accept the
                  <a style="cursor: pointer" (click)="termsAndConditions()">Terms of Service</a>
                  and </span><br/>
              <span style="padding-left: 32px">have read the
                  <a style="cursor: pointer" (click)="privacyPolicy()">Services Privacy Policy</a>
                </span>
            </div>
            <br/>
            <br/>
            <div class="spacer hide-gt-xs"></div>
            <button mat-raised-button color="primary" class="form-submit-button"
                    [disabled]="accountDataForm.invalid || !accountDataForm.value.acceptance" (click)="register()">
              Create Online Account
            </button>
            <br/>
            <br/>
            <br/>
          </form>
        </div>
        <ng-template #loading_spinner>
          <div class="loading-spinner flex-row flex-center-center">
            <mat-spinner></mat-spinner>
          </div>
        </ng-template>
      </mat-step>
      <mat-step label="Verify Email" [optional]="false" completed="false">
        <div *ngIf="!isLoading; else loading_spinner" style="width: 320px; margin: 50px auto 0 auto; text-align: center">
          <img src="https://storage.googleapis.com/millonex-assets/img/registration/mail.png"
               alt="Check Email" style="width: 100px; height: 82px"/>
          <h4 style="margin-top: 22px; font-weight: bold">
            {{ accountDataForm.value.email }}
          </h4>
          <div class="mat-display-1" style="margin: 45px 0 10px 0">
            Check your Inbox
          </div>
          <h4>
            Please check your inbox for a verification link. You need to click
            the verification link in the email to access your online library
            account.
          </h4>
          <button mat-raised-button class="form-submit-button resend-button" style="margin-top: 199px" (click)="resendEmailVerification()">
            Resend verification email
          </button>
          <br/>
          <br/>
          <br/>
        </div>
        <ng-template #loading_spinner>
          <div class="loading-spinner center-center">
            <mat-spinner></mat-spinner>
          </div>
        </ng-template>
      </mat-step>
      <mat-step label="Personal Information" [optional]="false" completed="false"></mat-step>
      <mat-step label="Notifications" [optional]="false" completed="false">
      </mat-step>
    </mat-stepper>
  </div>
  <rn-registration-side-panel></rn-registration-side-panel>
</div>
