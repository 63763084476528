<div *ngIf="catalogRecord" class="item">
  <rn-book-cover class="item-img mat-elevation-z6" [catalogRecord]="catalogRecord"></rn-book-cover>
  <a routerLink="/catalog-item/{{ catalogRecord.id }}">
    <h3 class="item-text limit-length-title" style="margin-bottom: 2px;" [title]="catalogRecord.title">{{ catalogRecord.title }}</h3>
    <div class="item-text item-text-secondary">{{ catalogRecord.author }}</div>
    <div class="item-text item-text-secondary">
      {{ catalogRecord.marcDetails?.clean?.publicationDate | date:'yyyy'}}
      <rn-material-type-icon [materialType]="catalogRecord.materialType"></rn-material-type-icon>
      {{ catalogRecord.materialType?.description }}
    </div>
  </a>
</div>