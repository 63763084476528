import {Component} from '@angular/core';
import {take} from 'rxjs';
import {tap} from 'rxjs/operators';
import {AuthService, OrganizationService, PatronService} from '@raven';

@Component({
  selector: 'rn-index',
  templateUrl: 'index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class IndexComponent {
  constructor(
    private authService: AuthService,
    public organizationService: OrganizationService
  ) {
  }

  ngOnInit(): void {
    if(this.authService.isAuthenticated()) {
      window.location.href = '/dashboard';
    }
  }
}
