import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {PatronVerificationService} from '@raven';

/**
 * EMAIL UPDATE DIALOG COMPONENT
 */
@Component({
  selector: 'fn-email-update-dialog',
  template: `
    <div mat-dialog-content>
      <mat-card-title> Update Email</mat-card-title>
      <mat-card-content>
        <form [formGroup]="emailUpdateForm">
          <div class="flex-col flex-start-end" style="padding-top: 20px">
            <!-- newEmail -->
            <mat-label>New Email</mat-label>
            <mat-form-field appearance="outline">
              <input matInput type="text" formControlName="newEmail" required maxlength="256"/>
              <mat-error *ngIf="emailUpdateForm.get('newEmail').hasError('required')">New Email is required
              </mat-error>
              <mat-error *ngIf="emailUpdateForm.get('newEmail').hasError('pattern')">
                You must provide a valid email address
              </mat-error>
            </mat-form-field>
          </div>
          <mat-card-actions align="end" style="margin-bottom: 0; margin-top: 15px;" class="button-row-right">
            <button mat-stroked-button class="ButtonSmallBlack save-button-group button-cancel" (click)="onNoClick()"
                    data-cy="on-hold-button">
              Cancel
            </button>
            <button mat-raised-button color="primary" class="ButtonSmallBlack save-button-group save-button"
                    [disabled]="!this.emailUpdateForm.valid" (click)="onSubmit(); $event.stopPropagation()">
              Update Email
            </button>
          </mat-card-actions>
        </form>
      </mat-card-content>
    </div>
  `,
  styles: [``],
})
export class EmailUpdateDialog {

  emailUpdateForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<EmailUpdateDialog>,
    private patronVerificationService: PatronVerificationService,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.emailUpdateForm = this.fb.group(
      {
        newEmail: ['', {validators: [Validators.email]}],
      },
    );
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onSubmit(): void {
    if (!this.emailUpdateForm.valid) {
      return;
    }
    const newEmail = this.emailUpdateForm.value.newEmail;

    this.patronVerificationService.sendChangeEmailVerificationRequest(newEmail, {
      onSuccess: () => {
        console.log('Verification email sent');
        this.dialogRef.close(true);
      }
    });
  }
}
