import {Component, OnInit} from '@angular/core';
import {AuthService, Patron} from '@raven';
import * as cloneDeep from 'lodash/cloneDeep';

@Component({
  selector: 'rn-registration-age-validation',
  templateUrl: './registration-age-validation.component.html',
  styleUrls: ['./registration-age-validation.component.scss'],
})
export class RegistrationAgeValidationComponent implements OnInit {

  constructor(private authService: AuthService) {
  }

  ngOnInit(): void {
  }
}
