import {Component, Input, OnInit} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {CatalogRecord, CatalogRecordService, ChiliFreshProReview, ChiliFreshService} from '@raven';

@Component({
  selector: 'rn-catalog-details-reviews-tab',
  templateUrl: './catalog-details-reviews-tab.component.html',
  styleUrls: ['./catalog-details-reviews-tab.component.scss'],
})
export class CatalogDetailsReviewsTabComponent implements OnInit {
  @Input()
  catalogRecord: CatalogRecord;
  proReviews$: Observable<ChiliFreshProReview[]>;
  loadingSubject = new BehaviorSubject(true);
  loading$ = this.loadingSubject.asObservable();

  constructor(
    private catalogRecordService: CatalogRecordService,
    private chiliFreshService: ChiliFreshService,
  ) {
  }

  ngOnInit(): void {
    if (!this.catalogRecord || !this.catalogRecord.identifier) {
      return;
    }
    this.proReviews$ = this.chiliFreshService.getProReviews(this.catalogRecord.identifier)
      .pipe(tap(() => this.loadingSubject.next(false)));
  }
}
