import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {AuthService, NotificationService, passwordConfirmValidator} from '@raven';

/**
 * PASSWORD UPDATE DIALOG COMPONENT
 */
@Component({
  selector: 'fn-password-update-dialog',
  template: `
    <div mat-dialog-content>
      <mat-card-title> Update Password</mat-card-title>
      <mat-card-content>
        <form [formGroup]="passwordUpdateForm" (ngSubmit)="!formSubmitted && onSubmit()">
          <div class="flex-col flex-start-end" style="padding-top: 20px">
            <!-- currentPassword -->
            <mat-label>Current Password</mat-label>
            <mat-form-field appearance="outline">
              <input matInput type="password" formControlName="currentPassword" required maxlength="256"/>
              <mat-error *ngIf="passwordUpdateForm.get('currentPassword').invalid">Current Password is required</mat-error>
            </mat-form-field>
            <!-- newPassword -->
            <mat-label>New Password</mat-label>
            <mat-form-field appearance="outline">
              <input matInput type="password" formControlName="newPassword" required maxlength="256"/>
                          <mat-error *ngIf="passwordUpdateForm.get('newPassword').hasError('required')">New Password is required</mat-error>
                          <mat-error *ngIf="passwordUpdateForm.get('newPassword').hasError('pattern')">
                              Password must be at least 8 characters and contain at
                              least one lowercase letter, uppercase letter, and number
                          </mat-error>
                      </mat-form-field>
                      <!--  newPasswordConfirmation -->
                    <mat-label>Confirm New Password</mat-label>
                    <mat-form-field appearance="outline">
                          <input matInput type="password" formControlName="newPasswordConfirm" required maxlength="256"/>
                          <mat-error *ngIf="passwordUpdateForm.get('newPasswordConfirm').hasError('required')">
                              <ng-container>Please re-enter the new password</ng-container>
                          </mat-error>
                          <mat-error *ngIf="passwordUpdateForm.get('newPasswordConfirm').hasError('mismatchedPasswords') && !passwordUpdateForm.get('newPasswordConfirm').pristine">
                              <ng-container>Passwords do not match</ng-container>
                          </mat-error>
                      </mat-form-field>
                  </div>
                  <mat-card-actions align="end" style="margin-bottom: 0; margin-top: 15px;" class="button-row-right">
                      <button mat-stroked-button class="ButtonSmallBlack save-button-group button-cancel" (click)="onNoClick()" data-cy="on-hold-button">
                          Cancel
                      </button>
                      <button mat-raised-button color="primary" class="ButtonSmallBlack save-button-group save-button"
                              [disabled]="!this.passwordUpdateForm.valid" (click)="onSubmit(); $event.stopPropagation()">
                          Update Password
                      </button>
                  </mat-card-actions>
              </form>
          </mat-card-content>
      </div>
  `,
  styles: [``],
})
export class PasswordUpdateDialog {
  passwordUpdateForm: FormGroup;
  formSubmitted = false;

  constructor(public dialogRef: MatDialogRef<PasswordUpdateDialog>,
              private auth: AuthService,
              private fb: FormBuilder,
              private notificationService: NotificationService) {
  }

  ngOnInit() {
    this.passwordUpdateForm = this.fb.group(
      {
        currentPassword: ['', {validators: [Validators.required]}],
        newPassword: [
          '',
          {
            validators: [
              Validators.required,
              Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$'),
            ],
          },
        ],
        newPasswordConfirm: ['', {validators: [Validators.required]}],
      },
      {
        validators: passwordConfirmValidator(
          'newPassword',
          'newPasswordConfirm'
        ),
      }
    );
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onSubmit(): void {
    if (!this.passwordUpdateForm.valid || this.formSubmitted) {
      return;
    }
    this.formSubmitted = true;
    const newPassword = this.passwordUpdateForm.value.newPassword;
    const currentPassword = this.passwordUpdateForm.value.currentPassword;
    this.auth
      .updatePassword(currentPassword, newPassword)
      .catch((error) => {
        if (error) {
          this.dialogRef.close(false);
          this.notificationService.showSnackbarError(error);
        }
      })
      .then((result) => {
        if (result) {
          this.dialogRef.close(true);
          this.notificationService.showSnackbarSuccess(result);
        }
      });
  }
}
