<div class="dialog-wrapper">
  <div class="dialog-close-row flex-row flex-center-end">
    <mat-icon class="pointer" (click)="close()">close</mat-icon>
  </div>

  <ng-container *ngIf="(loading$ | async)">
    <div class="flex-container">
      <mat-spinner></mat-spinner>
    </div>
  </ng-container>
  <div class="payment-form-colum flex-col flex-gap-18" [class.hidden]="(loading$ | async)">
    <div class="flex-row flex-center-space-between">
      <h3 class="dialog-title">Add a Credit/Debit Card</h3>
      <mat-icon>credit_card</mat-icon>
    </div>
    <span>We accept all major credit and debit cards. Your card will be saved.</span>
    <div class="card-row flex-row flex-gap-4">
      <img src="assets/visa-dark@3x.png" alt="Visa Card">
      <img src="assets/master-card-dark@3x.png" alt="Mastercard Card">
      <img src="assets/american-express-dark@3x.png" alt="American Express Card">
      <img src="assets/discover-dark@3x.png" alt="Discover Card">
    </div>
    <div class="card-details">
      <form [formGroup]="saveCardForm">
        <mat-label>Cardholder Name</mat-label>
        <mat-form-field appearance="outline">
          <input matInput type="text" formControlName="cardholderName" placeholder="Cardholder Name">
          <mat-error *ngIf="saveCardForm.controls['cardholderName']?.hasError('required')">
            Cardholder name is required
          </mat-error>
        </mat-form-field>
        <!--      <mat-label>Billing Address</mat-label>-->
        <!--      <mat-form-field appearance="outline">-->
        <!--        <input matInput type="text" formControlName="address" placeholder="Billing Address">-->
        <!--        <mat-error *ngIf="saveCardForm.controls['address']?.hasError('required')">-->
        <!--          Street Address is required.-->
        <!--        </mat-error>-->
        <!--      </mat-form-field>-->
        <!--      <mat-label>Apartment, unit, suite, or floor #</mat-label>-->
        <!--      <mat-form-field appearance="outline">-->
        <!--        <input matInput type="text" formControlName="address2" placeholder="Apartment, unit, suite, or floor #">-->
        <!--      </mat-form-field>-->
        <!--      <div class="flex-row flex-gap-12">-->
        <!--        <div style="max-width: 60%">-->
        <!--          <mat-label>City</mat-label>-->
        <!--          <mat-form-field appearance="outline">-->
        <!--            <input matInput type="text" formControlName="city" placeholder="City">-->
        <!--            <mat-error *ngIf="saveCardForm.controls['city']?.hasError('required')">-->
        <!--              City is required.-->
        <!--            </mat-error>-->
        <!--          </mat-form-field>-->
        <!--        </div>-->
        <!--        <div style="max-width: 20%">-->
        <!--          <mat-label>State/Province</mat-label>-->
        <!--          <br/>-->
        <!--          <mat-form-field appearance="outline">-->
        <!--            <mat-select formControlName="state">-->
        <!--              <mat-option *ngFor="let state of STATES" [value]="state.value">{{-->
        <!--                  state.label-->
        <!--                }}</mat-option>-->
        <!--            </mat-select>-->
        <!--            <mat-error *ngIf="saveCardForm.controls['state']?.hasError('required')">-->
        <!--              <ng-container>State/Province is required</ng-container>-->
        <!--            </mat-error>-->
        <!--          </mat-form-field>-->
        <!--        </div>-->
        <!--        <div style="max-width: 20%">-->
        <!--          <mat-label>Zip Code</mat-label>-->
        <!--          <br/>-->
        <!--          <mat-form-field appearance="outline">-->
        <!--            <input matInput type="text" formControlName="zip" placeholder="Zip Code">-->
        <!--            <mat-error *ngIf="saveCardForm.controls['zip']?.hasError('required')">-->
        <!--              Zip Code is required.-->
        <!--            </mat-error>-->
        <!--          </mat-form-field>-->
        <!--        </div>-->
        <!--      </div>-->
      </form>
      <div id="card-container"></div>
      <button [disabled]="!enableSubmit || !saveCardForm.valid" (click)="submit()" mat-raised-button color="primary" class="save-button">Add Card</button>
    </div>
  </div>
</div>
