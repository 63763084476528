<div class="index-page">
    <div class="mobile-banner"></div>
    <div class="landing-banner">
        <div class="welcome">Welcome!</div>
        <div class="welcome-subtitle">
            <h4>
                Welcome to the {{ (organizationService.organization$ | async)?.name }} Library.
            </h4>
        </div>
        <h4 class="welcome-list-text">
            <ul>
                <li>Please login to access your account.</li>
                <li>Or, register for a new library card.</li>
                <li>
                    If you have a library card
                    <a class="pointer" routerLink="/register-existing-card">sign up here.</a>
                </li>
            </ul>
        </h4>
    </div>
    <div class="flex-col">
      <button class="index-buttons login" color="primary" mat-raised-button routerLink="/login">
        <div class="index-button-text">Login</div>
        <mat-icon class="index-button-icon">arrow_forward</mat-icon>
      </button>
      <button class="index-buttons register-button" mat-raised-button routerLink="/register">
        <span class="index-button-text">Register</span>
        <mat-icon class="index-button-icon">arrow_forward</mat-icon>
      </button>
      <div class="already-have-card mat-small">
        Already have a library card?
            <a class="pointer" routerLink="/register-existing-card">Sign up here</a>
        </div>
    </div>
</div>
