export class PatronTransactions {
  charges: PatronCharge[] = [];
  payments: PatronPayment[] = [];
}

export class PatronCharge {
  id: number;
  patronId: number;
  assessedByUserId?: number;
  assessedAtBranchId?: number;
  checkoutId?: number;
  chargeType: CHARGE_TYPE;
  chargeNotes?: string;
  amount: number;
  status: CHARGE_STATUS;
  statusReason?: string;
  created: string;
  patronFirstName?: string;
  patronLastName?: string;
  itemTitle?: string;
  itemDueDate?: string;
  circulationItemBarcode?: string;
}

export class PatronPayment {
  id: number;
  patronId: number;
  processedByUserId?: number;
  processedAtBranchId?: number;
  paymentMethod: PATRON_PAYMENT_METHOD;
  amount: number;
  created: string;
}

export class PatronCardPaymentMethod {
  cardBrand: string;
  last4: string;
  cardholderName: string;
  expMonth: number;
  expYear: number;
  cardType: string;
  id: string; //square card ID
}

export enum PATRON_PAYMENT_METHOD {
  CASH = 'CASH',
  CHECK = 'CHECK',
  STRIPE = 'STRIPE',
  SQUARE = 'SQUARE',
  VENMO = 'VENMO',
  APPLE = 'APPLE',
  GPAY = 'GPAY',
}

export enum CHARGE_TYPE {
  OVERDUE = 'OVERDUE',
  DAMAGED = 'DAMAGED',
  LOST = 'LOST',
  OTHER = 'OTHER',
}

export enum CHARGE_STATUS {
  OPEN = 'OPEN',
  PAID = 'PAID',
  WAIVED = 'WAIVED',
}

export class PaymentMethodSavePayload {
  squareToken: string;
  // billingAddress: PatronBillingAddress; //billing zip already in token
  cardholderName: string;
}

export class SquareAppDetails {
  appId: string;
  locationId: string;
}
