import {Component, Input, OnInit} from '@angular/core';
import {CIRCULATION_ITEM_STATUS, CirculationItem} from '@raven';

@Component({
  selector: 'rn-circulation-item-status-pill',
  templateUrl: './circulation-item-status-pill.component.html',
  styleUrls: ['./circulation-item-status-pill.component.scss'],
})
export class CirculationItemStatusPillComponent implements OnInit {
  @Input() item: CirculationItem;
  CIRCULATION_ITEM_STATUS = CIRCULATION_ITEM_STATUS;

  constructor() {
  }

  ngOnInit(): void {
  }
}
