import {TitleCasePipe} from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {ApplePay, Card, GooglePay, Payments, payments, TokenResult} from '@square/web-sdk';
import {PATRON_PAYMENT_METHOD, PatronCardPaymentMethod, PatronPaymentService} from '@raven';

@Component({
  selector: 'rn-payment-setup-dialog',
  templateUrl: './payment-setup-dialog.component.html',
  styleUrls: ['./payment-setup-dialog.component.scss'],
})
export class PaymentSetupDialogComponent implements OnInit {

  loadingSubject = new BehaviorSubject(true);
  loading$ = this.loadingSubject.asObservable();
  enableSubmit = true;
  cardForm: FormGroup;
  squareCardForm: Card;
  appId: string;
  locationId: string;
  sq: Payments;
  PATRON_PAYMENT_METHOD = PATRON_PAYMENT_METHOD;
  applePay: ApplePay;

  cardFormStyles = {
    input: {
      fontSize: '14px',
      fontWeight: '400',
    },
  }

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<PaymentSetupDialogComponent>,
    private patronPaymentService: PatronPaymentService,
    private titleCasePipe: TitleCasePipe,
    @Inject(MAT_DIALOG_DATA) public data: {
      source: PatronCardPaymentMethod | string;
      paymentType: PATRON_PAYMENT_METHOD;
      savedCard: boolean;
      paymentAmount: string;
      token: string;
      sourceDisplayString: string;
      paymentDate: string;
      organizationName: string;
    }
  ) {
  }

  async ngOnInit() {
    this.cardForm = this.fb.group({
      cardholderName: ['', {validators: [Validators.required]}],
    });

    if (this.data.paymentType == PATRON_PAYMENT_METHOD.SQUARE && this.data.savedCard && typeof this.data.source != 'string') {
      this.data.token = this.data.source.id;
      this.data.sourceDisplayString = this.titleCasePipe.transform(this.data.source.cardBrand) + ' **** ' + this.data.source.last4;
      setTimeout(() => this.dialogRef.close(this.data), 300);
      return;
    }

    //other payment types require square library setup
    await this.squareInit();

    if (this.data.paymentType == PATRON_PAYMENT_METHOD.APPLE) {
      this.data.sourceDisplayString = 'Apple Pay';
      this.data.token = 'to be replaced at confirmation';
      setTimeout(() => this.dialogRef.close(this.data), 300);
      return;
    }

    if (this.data.paymentType == PATRON_PAYMENT_METHOD.SQUARE && !this.data.savedCard) {
      this.setupCardForm();
    } else if (this.data.paymentType == PATRON_PAYMENT_METHOD.GPAY) {
      this.setupGooglePay();
    }
  }

  async squareInit() {
    this.appId = this.patronPaymentService.getSquareAppId();
    this.locationId = this.patronPaymentService.getSquareLocationId();
    try {
      this.sq = await payments(this.appId, this.locationId);
    } catch (e) {
      console.error('Could not initialize square library', e);
    }
  }

  async setupGooglePay() {
    if(!this.sq){
      return;
    }
    const paymentRequest = this.sq.paymentRequest({
      countryCode: 'US',
      currencyCode: 'USD',
      total: {amount: this.data.paymentAmount, label: 'Total'},
    });
    let googlePay: GooglePay;
    try {
      googlePay = await this.sq.googlePay(paymentRequest);
    } catch (e) {
      console.error('Initializing Google Pay failed', e);
      return;
    }
    try {
      const tokenResult: TokenResult = await googlePay.tokenize();
      this.data.sourceDisplayString = 'Google Pay';
      this.data.token = this.tokenValid(tokenResult) ? tokenResult.token : null;
    } catch (e) {
      console.error('google pay error ', e);
    }
    this.dialogRef.close(this.data);
  }

  async setupCardForm() {
    if(!this.sq){
      return;
    }
    this.squareCardForm = await this.sq.card({style: this.cardFormStyles});
    await this.squareCardForm.attach('#card-container');

    this.loadingSubject.next(false);
  }

  tokenValid(tokenResult: TokenResult): boolean {
    if (tokenResult.errors) {
      console.error(tokenResult.errors);
    }
    return tokenResult.status === 'OK';
  }

  async submitCardForm() {
    if (!this.enableSubmit || !this.cardForm.valid) {
      return;
    }
    try {
      const tokenResult: TokenResult = await this.squareCardForm.tokenize();
      this.data.sourceDisplayString = 'Credit/Debit Card';
      this.data.token = this.tokenValid(tokenResult) ? tokenResult.token : null
    } catch (e) {
      console.error('Card entry error', e);
    }
    this.dialogRef.close(this.data);
  }

  close(): void {
    this.dialogRef.close(this.data);
  }
}
