export class PatronLedger {
  id: number;
  organizationId: number;
  patronId: number;
  patronAccountId?: number;
  assessedByUserId?: number;
  assessedAtBranchId?: number;
  circulationItemId?: number;
  circulationItemCheckinId?: number;
  amount: number;
  type: PATRON_LEDGER_TYPE;
  status: PATRON_LEDGER_STATUS;
  statusReason?: string;
  chargeType?: string;
  paymentType?: PATRON_LEDGER_PAYMENT_TYPE;

  circulationItemBarcode?: string;
  catalogRecordTitle?: string;
  dueDate?: Date;
  patronFirstName?: string;
  patronLastName?: string;

  change?: number;
  balance?: number;
}

export enum PATRON_LEDGER_TYPE {
  CHARGE = 'CHARGE',
  PAYMENT = 'PAYMENT',
}

export enum PATRON_LEDGER_STATUS {
  OPEN = 'OPEN',
  PAID = 'PAID',
  WAVED = 'WAVED',
  PARTIAL = 'PARTIAL',
  CONVERTED = 'CONVERTED',
  PENDING = 'PENDING',
}

export enum PATRON_LEDGER_PAYMENT_TYPE {
  CASH = 'CASH',
  CHECK = 'CHECK',
  STRIPE = 'STRIPE',
  SQUARE = 'SQUARE',
  VENMO = 'VENMO',
  APPLE = 'APPLE',
  GOOGLE = 'GOOGLE',
}

export enum PATRON_LEDGER_CHARGE_TYPE {
  REPLACE = 'Material Replacement Fee',
  CARD_RES = 'Card Resident Fee',
  CARD_NR = 'Card Non-resident Fee',
  CARD_REPLACE = 'Card Replacement Fee',
  COPY = 'Photocopier fee',
  PRINT_BW = 'Print B/W Fee',
  PRINT_COLOR = 'Print Color Fee',
  MAIL = 'Mail Fee',
}
