<div class="form-wrapper">
  <form class="activityFilters" [formGroup]="checkoutStatusForm">
    <mat-form-field appearance="outline">
      <mat-select formControlName="checkoutStatus">
        <mat-option value="">All Items</mat-option>
        <mat-option value="{{CHECKOUT_STATUS.DUE}}">Future Due</mat-option>
        <mat-option value="{{CHECKOUT_STATUS.OVERDUE}}">Overdue</mat-option>
        <mat-option value="{{CHECKOUT_STATUS.CLAIMED_RETURNED}}">Claimed Returned</mat-option>
        <mat-option value="{{CHECKOUT_STATUS.CLAIMED_LOST}}">Claimed Lost</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>