import {
  AfterContentChecked,
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {animate, AnimationBuilder, AnimationFactory, AnimationPlayer, style,} from '@angular/animations';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {CarouselItemDirective} from '@raven';

@Component({
  selector: 'rn-grid-carousel',
  template: `
    <div>
      <div class="carousel-title-bar">
        <div class="mat-display-2" style="display: inline-block; margin-bottom: 40px">
          {{ title }}
          <img alt="arrow-right" src="/assets/chevron-bottom.svg" class="chevron hide-xs"/>
        </div>
        <div class="mat-subheading-1 carousel-view-all hide-gt-xs">
          View all
          <img alt="" src="/assets/chevron-bottom.svg" class="small-chevron">
        </div>
        <div *ngIf="showTopButtons && numPages() > 0" class="carousel-top-buttons hide-xs">
          <button mat-raised-button color="primary" class="ButtonSmallBlack" [disabled]="currentPage === 0 || !canScrollLeft" (click)="prev()">
            <img alt="arrow-left" src="/assets/ui-elements-arrow-left.svg" class="arrow"/>
          </button>
          <button mat-raised-button color="primary" class="ButtonSmallBlack" [disabled]="currentPage + 1 === numPages() || !canScrollRight" (click)="next()">
            <img alt="arrow-right" src="/assets/ui-elements-arrow-right.svg" class="arrow"/>
          </button>
        </div>
      </div>
      <section *ngIf="numItems() > 0" class="carousel-wrapper" #wrapper (scroll)="onScroll()">
        <div class="carousel-inner" #carousel>
          <div *ngFor="let page of pagesOfRows" class="flex-col">
            <div *ngFor="let row of page" class="flex-row flex-gap-20">
              <ng-container *ngFor="let item of row">
                <ng-container [ngTemplateOutlet]="item.tpl"></ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </section>
      <ng-container *ngIf="numItems() === 0" [ngTemplateOutlet]="emptyTemplateRef"></ng-container>
      <div *ngIf="showBottomPageDots && numPages() > 1" class="carousel-button-box">
        <a *ngFor="let item of [].constructor(numPages()); let i = index" (click)="clickPage(i)"
           [class]="i === currentPage ? 'carousel-button-selected' : 'carousel-button'">
          <span>{{ i }}</span>
        </a>
      </div>
    </div>
  `,
  styleUrls: ['./grid-carousel.component.scss'],
})
export class GridCarouselComponent implements AfterContentInit, AfterContentChecked, OnInit {
  @ContentChildren(CarouselItemDirective)
  items: QueryList<CarouselItemDirective>;
  @ContentChild('emptyTemplate') emptyTemplateRef: TemplateRef<any>;
  @ViewChild('carousel') private carousel: ElementRef;
  @ViewChild('wrapper') private wrapper: ElementRef;
  @Input() title = '';
  @Input() timing = '250ms ease-in';
  @Input() showTopButtons = true;
  @Input() showBottomPageDots = false;
  @Input() itemWidth = 300; //includes margin on each item
  @Input() maxGridWidth = 4;
  @Input() gridHeight = 2;
  public canScrollLeft = false;
  public canScrollRight = true;

  private player: AnimationPlayer;
  currentPage = 0;

  pagesOfRows: CarouselItemDirective[][][] = [];
  gridWidth: number;

  ngOnInit(): void {
    this.gridWidth = this.maxGridWidth;
  }

  ngAfterContentInit(): void {
    this.breakpointObserver
      .observe([
        '(min-width: 300px)',
        '(min-width: 600px)',
        '(min-width: 900px)',
        '(min-width: 1200px)',
      ])
      .subscribe((state: BreakpointState) => {
        this.handleResponsiveBreakpoint(state);
      });
    this.setupItemArrays();
  }

  ngAfterContentChecked(): void {
    this.onScroll();
  }

  onScroll(): void {
    if (!this.wrapper || !this.wrapper.nativeElement) {
      return;
    }

    const viewWidth = this.wrapper.nativeElement.offsetWidth;
    const xScroll = this.wrapper.nativeElement.scrollLeft;
    const xScrollMax = this.wrapper.nativeElement.scrollWidth;
    this.canScrollRight = (xScroll + viewWidth) < xScrollMax;
    this.canScrollLeft = this.wrapper.nativeElement.scrollLeft > 0;

    //approx correct in case the page buttons return
    this.currentPage = Math.floor((xScroll + viewWidth) / this.itemWidth);
  }

  private buildAnimation(offset) {
    return this.builder.build([
      animate(this.timing, style({transform: `translateX(-${offset}px)`})),
    ]);
  }

  animate(): void {
    if (!this.carousel) {
      return;
    }
    const offset = this.currentPage * this.itemWidth * this.gridWidth;
    const myAnimation: AnimationFactory = this.buildAnimation(offset);
    this.player = myAnimation.create(this.carousel.nativeElement);
    this.player.play();
  }

  next(): void {
    const newScrollPos = this.calcScrollPosition(true);
    this.wrapper.nativeElement.scroll({top: 0, left: newScrollPos, behavior: 'smooth'});
  }

  prev(): void {
    const newScrollPos = this.calcScrollPosition(false);
    this.wrapper.nativeElement.scroll({top: 0, left: newScrollPos, behavior: 'smooth'});
  }

  numItems(): number {
    return this.items?.length ? this.items.length : 0;
  }

  numPages(): number {
    return Math.ceil(this.numItems() / this.itemsPerPage());
  }

  clickPage(index: number): void {
    if (this.currentPage === index) return;
    this.currentPage = index;
    this.animate();
  }

  isPage(index: number): boolean {
    const slide = Math.floor(this.currentPage / this.itemsPerPage());
    return slide == index;
  }

  getWidth(): number {
    return this.itemWidth * this.gridWidth;
  }

  itemsPerPage(): number {
    return this.gridWidth * this.gridHeight;
  }

  constructor(
    private builder: AnimationBuilder,
    private breakpointObserver: BreakpointObserver,
    private ref: ChangeDetectorRef
  ) {
  }

  handleResponsiveBreakpoint(state: BreakpointState): void {
    const minPageWidth = this.getMinWidthFromBreakpointState(state);
    const newGridWidth = Math.min(
      Math.floor(minPageWidth / this.itemWidth),
      this.maxGridWidth
    );
    if (newGridWidth != this.gridWidth) {
      this.gridWidth = newGridWidth;
      if (this.items) {
        this.setupItemArrays();
        this.ref.markForCheck();
        // resets to beginning on width change, not ideal but any other behavior is complicated
        this.currentPage = 0;
        this.animate();
      }
    }
  }

  getMinWidthFromBreakpointState(state: BreakpointState): number {
    // should be determined from itemWidth ideally
    const breakpointToSizeMap = {
      '(min-width: 300px)': 300,
      '(min-width: 600px)': 600,
      '(min-width: 900px)': 900,
      '(min-width: 1200px)': 1200,
    };
    let size = 300;
    for (const key of Object.keys(state.breakpoints)) {
      if (state.breakpoints[key]) {
        size = breakpointToSizeMap[key];
      }
    }
    return size;
  }

  setupItemArrays(): void {
    this.pagesOfRows = [];
    const items = this.items.toArray();
    const itemPages = [];
    const perPage = this.gridWidth * this.gridHeight;
    const pages = Math.ceil(this.numItems() / perPage);
    for (let i = 0; i < pages; i++) {
      const start = i * perPage;
      itemPages[i] = items.slice(start, start + perPage);
    }
    for (let page = 0; page < itemPages.length; page++) {
      const pageRows = [];
      for (let i = 0; i < this.gridHeight; i++) {
        const start = i * this.gridWidth;
        pageRows[i] = itemPages[page].slice(start, start + this.gridWidth);
      }
      this.pagesOfRows[page] = pageRows;
    }
  }

  calcScrollPosition(scrollRight: boolean): number {
    if (!this.wrapper || !this.wrapper.nativeElement) {
      return 0;
    }

    const viewWidth = this.wrapper.nativeElement.offsetWidth;
    const xScroll = this.wrapper.nativeElement.scrollLeft;

    if (scrollRight) {
      const lastFullVisible = Math.floor((xScroll + viewWidth) / this.itemWidth);
      return lastFullVisible * this.itemWidth;
    } else {
      const firstFullVisible = Math.ceil(xScroll / this.itemWidth);
      return (firstFullVisible * this.itemWidth) - viewWidth;
    }
  }
}
