import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, shareReplay} from 'rxjs';
import {catchError, map, take} from 'rxjs/operators';
import {Environment, NotificationService, PATRON_PAYMENT_METHOD, PatronCardPaymentMethod, PaymentMethodSavePayload, SquareAppDetails} from '@raven';

@Injectable({
  providedIn: 'root'
})
export class PatronPaymentService {

  patronPaymentCards = new BehaviorSubject<PatronCardPaymentMethod[]>([]);
  savedCards: PatronCardPaymentMethod[] = [];
  private appId = '';
  private locationId = '';

  constructor(private environment: Environment,
              private http: HttpClient,
              private notificationService: NotificationService) {
    this.http.get<SquareAppDetails>(`${this.environment.apiUrl}/v1/payments/square/app-details`)
      .pipe(catchError((error: unknown) => {
        throw new Error('Could not fetch square setup details: ' + error.toString());
      }))
      .subscribe(details => {
        this.appId = details.appId;
        this.locationId = details.locationId;
      });
  }

  getAllPaymentMethods(): Observable<PatronCardPaymentMethod[]> {
    const paymentMethods$ = this.http.get<PatronCardPaymentMethod[]>(
      `${this.environment.apiUrl}/v1/payments/cards`
    );
    paymentMethods$
      .pipe(
        catchError((err: unknown) => {
          this.notificationService.showSnackbarError('There was a problem retrieving your saved payment information');
          console.error(err);
          return [];
        })
      )
      .subscribe(paymentMethods => {
        this.savedCards = paymentMethods;
        this.patronPaymentCards.next(paymentMethods);
      });

    return this.patronPaymentCards.asObservable();
  }

  deletePaymentMethod(toDelete: PatronCardPaymentMethod): Observable<boolean> {
    const cardDeleted$ = this.http.delete<boolean>(`${this.environment.apiUrl}/v1/payments/cards`, {body: toDelete})
      .pipe(
        catchError((err: unknown) => {
          console.error(err);
          return of(false);
        }),
        shareReplay({refCount: false, bufferSize: 1}),
        take(1)
      );

    cardDeleted$.subscribe(result => {
      if (result) {
        this.savedCards = this.savedCards.filter(card => card.id != toDelete.id);
        this.patronPaymentCards.next(this.savedCards);
        this.notificationService.showSnackbarSuccess('Card deleted');
      } else {
        this.notificationService.showSnackbarError('There was a problem deleting your card');
      }
    });
    return cardDeleted$;
  }

  setPrimaryPaymentMethod(card: PatronCardPaymentMethod): Observable<boolean> {
    const cardSet$ = this.http.patch<boolean>(`${this.environment.apiUrl}/v1/payments/cards`, card)
      .pipe(
        catchError((err: unknown) => {
          console.error(err);
          return of(false);
        }),
        shareReplay({refCount: false, bufferSize: 1}),
        take(1)
      );
    cardSet$.subscribe(result => {
      if (result) {
        this.sortPrimaryCardFirst(card);
        this.patronPaymentCards.next(this.savedCards);
        this.notificationService.showSnackbarSuccess('Primary payment method saved');
      } else {
        this.notificationService.showSnackbarError('There was a problem saving your changes');
      }
    });
    return cardSet$;
  }

  private sortPrimaryCardFirst(newPrimary: PatronCardPaymentMethod): void {
    if (!newPrimary || !this.savedCards || this.savedCards.length < 2) {
      return;
    }
    const notPrimary = this.savedCards.filter(savedCard => savedCard.id != newPrimary.id);
    this.savedCards = [newPrimary].concat(notPrimary);
  }

  savePaymentMethod(toSave: PaymentMethodSavePayload): Observable<boolean> {
    const cardSaved$ = this.http.post<PatronCardPaymentMethod>(`${this.environment.apiUrl}/v1/payments/cards`, toSave)
      .pipe(
        catchError((err: unknown) => {
          console.error(err);
          return of(null);
        }),
        shareReplay({refCount: false, bufferSize: 1}),
        take(1)
      );
    cardSaved$.subscribe(result => {
      if (result) {
        this.savedCards.push(result);
        this.patronPaymentCards.next(this.savedCards);
        this.notificationService.showSnackbarSuccess('Card saved');
      } else {
        this.notificationService.showSnackbarError('There was a problem saving your card');
      }
    });
    return cardSaved$.pipe(map(saved => !!saved));
  }

  makePayment(paymentType: PATRON_PAYMENT_METHOD, token: string, amount: number, date: string): Observable<boolean> {
    const paymentCreated$ = this.http.post<boolean>(`${this.environment.apiUrl}/v1/payments`, {
      paymentMethod: paymentType,
      token: token,
      amount: amount,
      date: date,
    })
      .pipe(
        catchError((err: unknown) => {
          console.error(err);
          return of(false);
        }),
        shareReplay({refCount: false, bufferSize: 1}),
        take(1)
      );
    paymentCreated$.subscribe(result => {
      if (result) {
        this.notificationService.showSnackbarSuccess('Payment successful');
      } else {
        this.notificationService.showSnackbarError('Your payment could not be completed');
      }
    });
    return paymentCreated$;
  }

  refreshPaymentMethods(): void {
    this.getAllPaymentMethods();
  }

  getSquareAppId(): string {
    return this.appId;
  }

  getSquareLocationId(): string {
    return this.locationId;
  }
}
