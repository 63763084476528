import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'rn-availability-count-pill',
  templateUrl: './availability-count-pill.component.html',
  styleUrls: ['./availability-count-pill.component.scss'],
})
export class AvailabilityCountPillComponent implements OnInit {
  @Input()
  available: number;
  @Input()
  copies: number;
  @Input()
  holds: number;

  constructor() {
  }

  ngOnInit(): void {
  }
}
