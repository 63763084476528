import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Environment, NotificationService, PlatformException, RoutesService} from '@raven';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private environment: Environment,
    private notificationService: NotificationService,
    public routesService: RoutesService
  ) {
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          // Check for login/registration workflow and handle errors differently
          if (!this.loginOrRegistrationWorkflow(request)) {
            // Route errors to appropriate landing page - only include error codes that don't need to be handled in the service layer
            if (error.status === 400) {
              // BAD REQUEST
              this.notificationService.showSnackbarError(
                'Unable to complete request - Error code 400'
              );
            } else if (error.status === 401) {
              // UNAUTHORIZED
              this.routesService.goTo401().then();
            } else if (error.status === 404) {
              // NOT FOUND
              this.routesService.goTo404().then();
            } else if (error.status === 405) {
              // METHOD NOT ALLOWED
              this.routesService.goTo404().then();
            } else if (error.status === 415) {
              // UNSUPPORTED MEDIA TYPE
              this.notificationService.showSnackbarError(
                'Unsupported media type - contact system administrator'
              );
            } else if (error.status === 500) {
              // INTERNAL SERVER ERROR
              this.routesService.goTo500().then();
            }
          }
          return next.handle(request);
        }
      })
    );
  }

  /**
   * Check if we are in the login/registration workflow - these errors should not be universally caught as they are handled in the login/registration flow
   * @param request HttpRequest
   */
  loginOrRegistrationWorkflow(request: HttpRequest<unknown>): boolean {
    return (
      request.url.includes('api/authenticate') ||
      request.url.includes('api/v1/patron') ||
      request.url.includes('email/verify') ||
      request.url.includes('validate-barcode') ||
      request.url.includes('register-existing-card')
    );
  }

  handlePlatformException(exception: PlatformException): void {
    // TODO this is a temp. fix, need to figure out a better way to do this
    if (exception.errorType === 'ITEM_CONFIGURATION') {
      this.handleFixNotification(exception);
    } else if (exception.errorType === 'GOOGLE_CLOUD_STORE') {
      const message =
        'Checkout Complete. Unable to connect to Google Cloud Storage to save PDF receipt. Contact System Administrator';
      this.notificationService.showSnackbarError(message);
    }
  }

  handleFixNotification(exception: PlatformException): void {
    const errorMessage =
      exception.errorType + ' error on ' + exception.entityType;
    const snackBarRef = this.notificationService.showSnackbarError(
      errorMessage,
      'Fix Configuration'
    );
    snackBarRef.onAction().subscribe(() => {
      this.fixConfiguration(exception);
    });
  }

  fixConfiguration(exception: PlatformException): void {
    // TODO this is a temp. fix, need to figure out a better way to do this
    if (exception.entityType === 'CIRCULATION_ITEM') {
      this.routesService.goToCirculationItemEdit(exception.entityId).then();
    } else if (exception.entityType === 'CATALOG_RECORD') {
      this.routesService
        .goToCatalogRecordEdit(exception.organizationId, exception.entityId)
        .then();
    } else if (exception.entityType === 'MEDIA_TYPE') {
      this.routesService
        .goToCatalogRecordEdit(exception.organizationId, exception.entityId)
        .then();
    } else if (exception.entityType === 'CIRCULATION_RULE') {
      this.routesService.goToCirculationRules(exception.organizationId).then();
    }
  }
}
