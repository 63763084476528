import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  // {
  //   path: '',
  //   canActivate: [OrganizationGuard, AuthGuard, BranchGuard, FireGuard],
  //   canActivateChild: [OrganizationGuard, BranchGuard],
  //   // wrap this in the app layout component, which provides the nav + header + footer
  //   component: AppLayoutComponent,
  //   children: [
  //     // {path: 'profile', component: ProfileComponent, data: {footerVisible: false}},
  //     // {path: 'settings', component: SettingsComponent},
  //     // {path: 'communication-preferences', component: CommunicationPreferencesComponent},
  //     // {path: 'my-card', component: MyCardComponent},
  //   ],
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProfileRoutingModule {
}
