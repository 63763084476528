import {Component, Input} from '@angular/core';
import {CatalogRecord, RoutesService} from '@raven';

@Component({
  selector: 'rn-popular-subject-carousel',
  template: `
    <rn-grid-carousel [title]="title" [itemWidth]="300">
      <ng-container *ngFor="let subject of subjects; let last = last">
        <div class="carousel-item pointer flex-col" *carouselItem routerLink="/catalog-item/{{ subject.catalogRecord.id }}" [ngClass]="{ 'last' : last }">
          <div class="catalog-item-box center-center">
            <rn-book-cover style="width: 112px; height: 172px" [catalogRecord]="subject.catalogRecord" [showFavoriteStatus]="false"></rn-book-cover>
          </div>
          <h3 style="margin: 0">{{ subject.subject }}</h3>
        </div>
      </ng-container>
      <ng-template #emptyTemplate>
        <div class="empty-content flex-col flex-center-center">
          <h1 style="margin: auto auto">Check back later for popular subjects</h1>
        </div>
      </ng-template>
    </rn-grid-carousel>
  `,
  styleUrls: ['./popular-subject-carousel.component.scss'],
})
export class PopularSubjectCarouselComponent {
  @Input() title = '';
  @Input() subjects: { subject: string; catalogRecord: CatalogRecord }[];

  constructor(public routesService: RoutesService) {
  }
}
