import {Component, Inject} from '@angular/core';
import {Location as BrowserLocation} from '@angular/common';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'rn-terms',
  template: `
    <div
      style="overflow: scroll; max-width: 60vh; max-height: 80vh"
      class="mat-small"
    >
      <h2 class="mat-display-2" style="margin: 0">{{ data }}</h2>
      <br />
      <p>Effective January 5, 2022</p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Turpis egestas
        pretium aenean pharetra magna. Sem viverra aliquet eget sit amet tellus
        cras adipiscing enim. Malesuada fames ac turpis egestas integer eget
        aliquet nibh praesent. Sit amet consectetur adipiscing elit duis
        tristique. Tristique risus nec feugiat in. Ac turpis egestas maecenas
        pharetra convallis posuere. Aliquam eleifend mi in nulla posuere. Nullam
        vehicula ipsum a arcu cursus vitae congue mauris. At quis risus sed
        vulputate odio. Dolor morbi non arcu risus quis varius quam quisque.
        Metus aliquam eleifend mi in nulla. In est ante in nibh mauris cursus.
        Duis convallis convallis tellus id interdum velit laoreet. Id velit ut
        tortor pretium viverra suspendisse potenti nullam ac. Id nibh tortor id
        aliquet. Sed sed risus pretium quam vulputate dignissim suspendisse.
        Libero id faucibus nisl tincidunt eget. Adipiscing commodo elit at
        imperdiet dui accumsan sit amet. Leo a diam sollicitudin tempor id.
      </p>
      <p>
        Non consectetur a erat nam at. Condimentum lacinia quis vel eros donec.
        Aenean pharetra magna ac placerat vestibulum. Iaculis nunc sed augue
        lacus. Vulputate eu scelerisque felis imperdiet. Porttitor lacus luctus
        accumsan tortor. Et leo duis ut diam quam nulla porttitor massa id.
        Consequat mauris nunc congue nisi vitae suscipit tellus mauris a.
        Maecenas volutpat blandit aliquam etiam. Consectetur adipiscing elit
        duis tristique sollicitudin nibh sit. Nibh mauris cursus mattis molestie
        a iaculis. Orci dapibus ultrices in iaculis nunc sed augue. Mauris
        commodo quis imperdiet massa tincidunt. Etiam tempor orci eu lobortis
        elementum. Mauris rhoncus aenean vel elit scelerisque mauris
        pellentesque pulvinar pellentesque. Hendrerit dolor magna eget est lorem
        ipsum. Nunc aliquet bibendum enim facilisis gravida. Consequat mauris
        nunc congue nisi vitae suscipit tellus. Massa enim nec dui nunc mattis
        enim.
      </p>
      <p>
        Diam quis enim lobortis scelerisque. Volutpat diam ut venenatis tellus
        in metus. Ultricies mi eget mauris pharetra et ultrices neque. Praesent
        elementum facilisis leo vel fringilla est ullamcorper eget. Volutpat
        odio facilisis mauris sit amet massa vitae tortor condimentum. Proin
        nibh nisl condimentum id venenatis a condimentum vitae sapien. Viverra
        maecenas accumsan lacus vel facilisis volutpat est velit. Consectetur
        adipiscing elit pellentesque habitant morbi tristique. Sollicitudin nibh
        sit amet commodo. Proin nibh nisl condimentum id venenatis a
        condimentum. Feugiat nibh sed pulvinar proin gravida hendrerit lectus a.
        Sed tempus urna et pharetra pharetra massa massa ultricies mi. Felis
        imperdiet proin fermentum leo vel. Volutpat lacus laoreet non curabitur
        gravida arcu ac. Curabitur vitae nunc sed velit dignissim sodales. Lacus
        luctus accumsan tortor posuere. Suscipit tellus mauris a diam.
      </p>
      <p>
        Placerat vestibulum lectus mauris ultrices eros in. Convallis aenean et
        tortor at risus viverra adipiscing. Duis at tellus at urna condimentum
        mattis pellentesque id. Sit amet venenatis urna cursus. Cursus sit amet
        dictum sit amet justo donec. Ultrices gravida dictum fusce ut placerat.
        Condimentum lacinia quis vel eros donec ac odio. Gravida in fermentum et
        sollicitudin ac orci phasellus egestas. Vestibulum sed arcu non odio
        euismod. Vitae congue mauris rhoncus aenean vel elit scelerisque. A diam
        sollicitudin tempor id eu nisl. Morbi tristique senectus et netus et.
        Arcu cursus vitae congue mauris rhoncus aenean vel elit.
      </p>
      <p>
        Massa enim nec dui nunc mattis. Rhoncus urna neque viverra justo nec. Ut
        sem viverra aliquet eget. Vel orci porta non pulvinar neque. Faucibus
        turpis in eu mi bibendum neque. Malesuada nunc vel risus commodo viverra
        maecenas. Integer vitae justo eget magna fermentum iaculis eu non diam.
        Faucibus purus in massa tempor nec feugiat nisl. Enim facilisis gravida
        neque convallis. Porttitor lacus luctus accumsan tortor. Leo vel
        fringilla est ullamcorper eget nulla. Donec massa sapien faucibus et
        molestie ac feugiat sed. Posuere sollicitudin aliquam ultrices sagittis
        orci a scelerisque.
      </p>
    </div>
  `,
})
export class TermsComponent {
  constructor(
    private browserLocation: BrowserLocation,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {
  }

  cancel(): void {
    // head back to whence we came
    this.browserLocation.back();
  }
}
