import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FlexModule} from '@angular/flex-layout';
import {PatronNotificationCenterComponent} from './patron-notification-center.component';
import {SharedModule} from "../shared/shared.module";

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    FlexModule
  ],
  exports: [
    PatronNotificationCenterComponent,
  ],
  declarations: [
    PatronNotificationCenterComponent,
  ],
})
export class PatronNotificationsModule {
}
