import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PatronItemRequest, RoutesService} from '@raven';

/**
 * DELETE ITEM REQUEST DIALOG COMPONENT
 */
@Component({
  selector: 'rn-delete-item-request-dialog',
  template: `
    <div class="flex-col">
      <h3>
        Cancel Item Request{{ data.itemRequests.length | plural }} ({{ data.itemRequests.length }})
      </h3>
      <div class="flex-col flex-start-start">
        <ng-container *ngIf="data.cantDelete > 0">
          <div class="warn-box dialog-warn-box flex-row flex-center-start">
            <span>{{ data.cantDelete }} of the selected items cannot be deleted.</span>
          </div>
        </ng-container>
        <span class="Text-Style dialog-body">
                Are you sure you want to cancel {{ data.itemRequests.length }} item request{{data.itemRequests.length | plural}}?
              </span>
        <span class="Text-Style dialog-body">
                Canceling {{data.itemRequests.length > 1 ? 'these items' : 'this item'}}
          is not a reversible action. Once it is canceled {{data.itemRequests.length > 1 ? 'they' : 'it'}} cannot be recovered.
              </span>
      </div>
      <div class="dialog-button-row">
        <button mat-stroked-button class="save-button-group button-cancel" (click)="onNoClick()">
          No
        </button>
        <button mat-raised-button color="warn" class="save-button-group save-button" (click)="onDelete()">
          Yes, Cancel
        </button>
      </div>
    </div>
  `,
  styles: [``],
})
export class DeleteItemRequestDialog {
  constructor(
    public dialogRef: MatDialogRef<DeleteItemRequestDialog>,
    public routesService: RoutesService,
    @Inject(MAT_DIALOG_DATA) public data: { itemRequests: PatronItemRequest[]; cantDelete: number }
  ) {
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onDelete(): void {
    this.dialogRef.close(true);
  }
}
