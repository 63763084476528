<div class="registration-page flex-row flex-start-start">
  <div class="main-panel flex-1">
    <div *ngIf="!isLoading; else loading_spinner">
      <form [formGroup]="personalInfoForm" novalidate>
        <mat-stepper [labelPosition]="'bottom'" linear="true" selectedIndex="3">
          <mat-step completed="true" editable="false" label="Select Library"></mat-step>
          <mat-step completed="true" editable="false" label="Library Account"></mat-step>
          <mat-step completed="true" editable="false" label="Verify Email"></mat-step>
          <mat-step completed="false" label="Personal Information" optional="false">
            <div class="mat-display-1 registration-title">
              Personal Information
            </div>
            <h4 class="registration-subtitle">
              Please enter your personal details for your library account.<br/>
              Fields marked with an asterisk (*) are requested.
            </h4>
            <div *ngIf="formStep == 'personalInfo'" class="account-column">
              <mat-label>First Name</mat-label>
              <mat-form-field [formGroup]="personalInfoForm" appearance="outline">
                  <input matInput type="text" formControlName="firstName" required="true" maxlength="256"/>
                  <mat-error *ngIf="personalInfoForm.get('firstName').hasError('required')">
                    Email is required
                  </mat-error>
                </mat-form-field>
              <mat-label>Last Name</mat-label>
              <mat-form-field [formGroup]="personalInfoForm" appearance="outline">
                  <input matInput type="text" formControlName="lastName" required="true" maxlength="256"/>
                  <mat-error *ngIf="personalInfoForm.get('lastName').hasError('required')">
                    Last Name is required
                  </mat-error>
                </mat-form-field>
              <mat-label>Date of Birth</mat-label>
              <mat-form-field [formGroup]="personalInfoForm" appearance="outline">
                  <input matInput [matDatepicker]="picker" formControlName="dateOfBirth" type="text" required="true" [max]="today()"/>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker touchUi="true" #picker></mat-datepicker>
                  <mat-error *ngIf="personalInfoForm.controls['dateOfBirth']?.invalid">
                    {{getErrorMessage('date of birth', personalInfoForm.controls['dateOfBirth'])}}
                  </mat-error>
                </mat-form-field>
              <mat-label>Phone Number</mat-label>
              <mat-form-field [formGroup]="personalInfoForm" appearance="outline">
                  <input matInput type="text" formControlName="phoneNumber"/>
                  <mat-error *ngIf="personalInfoForm.controls['phoneNumber']?.invalid">
                    {{getErrorMessage('phone number', personalInfoForm.controls['phoneNumber'])}}
                  </mat-error>
                </mat-form-field>
              <ng-container [formGroup]="personalInfoForm">
                <mat-radio-group class="mx-radio-group" formControlName="phoneType">
                  <mat-radio-button [value]="PATRON_PHONE_TYPE.MOBILE" class="mx-radio-button">Mobile</mat-radio-button>
                  <mat-radio-button [value]="PATRON_PHONE_TYPE.HOME" class="mx-radio-button">Home</mat-radio-button>
                </mat-radio-group>
              </ng-container>
              <br/>
              <button (click)="continue()" [disabled]="!isPersonalInfoValid()" class="form-submit-button" color="primary" mat-raised-button>
                Continue
              </button>
            </div>
            <div *ngIf="formStep == 'address'" class="account-column">
              <mat-label>Street Address</mat-label>
              <mat-form-field [formGroup]="personalInfoForm" appearance="outline">
                <input matInput type="text" formControlName="address"/>
                <mat-error *ngIf="personalInfoForm.controls['address']?.invalid">
                  {{getErrorMessage('address', personalInfoForm.controls['address'])}}
                </mat-error>
              </mat-form-field>
              <mat-label>Apartment, unit, suite, or floor #</mat-label>
              <mat-form-field [formGroup]="personalInfoForm" appearance="outline">
                <input matInput type="text" formControlName="address2"/>
                <mat-error *ngIf="personalInfoForm.controls['address2']?.invalid">
                  {{getErrorMessage('this field', personalInfoForm.controls['address2'])}}
                </mat-error>
              </mat-form-field>
              <mat-label>City</mat-label>
              <mat-form-field [formGroup]="personalInfoForm" appearance="outline">
                <input matInput type="text" formControlName="city"/>
                <mat-error *ngIf="personalInfoForm.controls['city']?.invalid">
                  {{getErrorMessage('city', personalInfoForm.controls['city'])}}
                </mat-error>
              </mat-form-field>
              <div class="flex-row flex-start-space-between">
                <div [style]="{ width: '62%' }">
                  <mat-label>State</mat-label>
                  <mat-form-field [formGroup]="personalInfoForm" appearance="outline">
                    <mat-select formControlName="state">
                      <mat-option *ngFor="let state of STATES" [value]="state.value">{{
                          state.label
                        }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="personalInfoForm.controls['state']?.invalid">
                      {{getErrorMessage('state', personalInfoForm.controls['state'])}}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div [style]="{'margin-right': '0','padding-right': '0','width': '35%'}">
                  <mat-label>Zip Code</mat-label>
                  <mat-form-field [formGroup]="personalInfoForm" appearance="outline">
                    <input matInput type="text" formControlName="zipCode"/>
                    <mat-error *ngIf="personalInfoForm.controls['zipCode']?.invalid">
                      {{getErrorMessage('zip code', personalInfoForm.controls['zipCode'])}}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <br/><br/>
              <button (click)="submit()" [disabled]="!isAddressValid()" class="form-submit-button" color="primary" mat-raised-button>
                Continue
              </button>
            </div>
          </mat-step>
          <mat-step [optional]="false" completed="false" label="Notifications"></mat-step>
        </mat-stepper>
      </form>
    </div>
    <ng-template #loading_spinner>
      <div class="flex-container">
        <mat-spinner></mat-spinner>
      </div>
    </ng-template>
  </div>
  <rn-registration-side-panel></rn-registration-side-panel>
</div>
