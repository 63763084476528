import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {MatTable} from '@angular/material/table';
import {Observable, Subject, takeUntil} from 'rxjs';
import {map} from 'rxjs/operators';
import {CatalogRecord, CatalogRecordAvailabilityDetails, CatalogRecordAvailabilityForBranch, CatalogRecordService, Patron, PatronService} from '@raven';

@Component({
  selector: 'rn-catalog-details-availability-tab',
  templateUrl: './catalog-details-availability-tab.component.html',
  styleUrls: ['./catalog-details-availability-tab.component.scss'],
})
export class CatalogDetailsAvailabilityTabComponent implements OnInit, OnDestroy {
  @Input()
  catalogRecord: CatalogRecord;
  availability$: Observable<CatalogRecordAvailabilityDetails>;
  patron$: Observable<Patron>;
  mobile = false;
  @ViewChild(MatTable) table: MatTable<CatalogRecordAvailabilityForBranch>;
  destroy$ = new Subject<boolean>();

  defaultColumns = ['library', 'location', 'availability', 'expand-trigger'];
  mobileColumns = ['library', 'location', 'expand-trigger'];
  displayColumns = this.defaultColumns;

  constructor(
    private patronService: PatronService,
    private catalogRecordService: CatalogRecordService,
    private breakpointObserver: BreakpointObserver,
  ) {
  }

  ngOnInit(): void {
    this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.mobile = true;
          this.displayColumns = this.mobileColumns
        } else {
          this.mobile = false;
          this.displayColumns = this.defaultColumns;
        }
      });

    this.patron$ = this.patronService.patron$;
    //deal with null catalogRecord
    this.availability$ = this.catalogRecordService.getAvailabilityDetails(this.catalogRecord.id)
      .pipe(map((a) => {
        //sort home branch first?
        console.log(a.branchAvailability);
        for (const b of a.branchAvailability) {
          b.expanded = false;
        }
        return a;
      }))
  }

  expanded(index, data) {
    return data.expanded;
  }

  expansionClick(b: CatalogRecordAvailabilityForBranch) {
    b.expanded = !b.expanded;
    this.table.renderRows();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
