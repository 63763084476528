import {Component} from '@angular/core';
import {ListDataService} from "@raven";

@Component({
  selector: 'rn-favorites-page-size-select',
  template: `
    <span class="page-length">
      <button [ngClass]="{ selected: getPageSize() === 25 }" (click)="setPageSize(25)">25</button>
      <button [ngClass]="{ selected: getPageSize() === 50 }" (click)="setPageSize(50)">50</button>
    </span>
  `,
  styles: [`
      .page-length {
          display: inline-block;
          vertical-align: middle;
      }

      button {
          cursor: pointer;
          width: 30px;
          height: 30px;
          box-sizing: border-box;
          border: 1px solid var(--med-gray-2);
          background-color: white;
          color: var(--dk-gray);
      }

      button.selected {
          color: white;
          background-color: var(--dk-gray);
          border: 1px solid var(--dk-gray);
      }
  `],
})
export class PageSizeSelectComponent {

  constructor(public listDataService: ListDataService,) {
  }

  getPageSize(): number {
    return this.listDataService.pageState.limit;
  }

  setPageSize(newSize: number): void {
    this.listDataService.pageState.page = 0;
    this.listDataService.pageState.limit = newSize;
    this.listDataService.pageEvent(this.listDataService.pageState);
  }
}
