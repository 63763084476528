<div *ngIf="!isLoading; else loading_spinner">
  <form [formGroup]="notificationPreferencesForm" novalidate>
    <div class="mat-display-1 preferences-title">Communication Preferences</div>
    <h3 class="preferences-subtitle">Library Communications</h3>
    <div class="preferences-text mat-small">
      Communication preferences related to your library account.
    </div>
    <h3 class="preferences-subtitle">Email: {{this.patron?.email}}</h3>
    <h3 *ngIf="this.patron?.phone1" class="preferences-subtitle">SMS/Phone: {{this.patron?.phone1 }}</h3>
    <h3 *ngIf="!this.patron?.phone1" class="preferences-subtitle">SMS/Phone: <a (click)="routingService.goToProfile()" class="pointer">none provided</a></h3>
    <hr/>
    <h3 class="preferences-category">
      Account related notices
    </h3>
    <mat-checkbox matInput formControlName="accountByEmail">Email</mat-checkbox>
    <br/>
    <mat-checkbox matInput formControlName="accountBySMS">SMS</mat-checkbox>
    <h3 class="preferences-category">
      Receipts
    </h3>
    <mat-checkbox matInput formControlName="receiptByEmail">Email</mat-checkbox>
    <br/>
    <mat-checkbox matInput formControlName="receiptBySMS">SMS</mat-checkbox>
    <h3 class="preferences-category">
      Almost overdue/auto-renew
    </h3>
    <mat-checkbox matInput formControlName="cardExpireByEmail">Email</mat-checkbox>
    <br/>
    <mat-checkbox matInput formControlName="cardExpireBySMS">SMS</mat-checkbox>
    <h3 class="preferences-category">
      Library card expiration
    </h3>
    <mat-checkbox matInput formControlName="autoRenewByEmail">Email</mat-checkbox>
    <br/>
    <mat-checkbox matInput formControlName="autoRenewBySMS">SMS</mat-checkbox>
    <hr/>
    <mat-checkbox matInput formControlName="unsubscribe">Unsubscribe from all communications</mat-checkbox>
    <p>
      By unsubscribing from all communications, you will still receive account
      related communications and notifications.
    </p>
    <div class="save-footer mat-elevation-z6">
      <div class="save-footer-content flex-row flex-center-end">
        <div class="spacer"></div>
        <div class="button-row-right">
          <button mat-stroked-button class="ButtonSmallBlack save-button-group button-cancel"
                  (click)="cancel()" data-cy="on-hold-button">
            Cancel
          </button>
          <button mat-raised-button color="primary" class="ButtonSmallBlack save-button-group save-button"
                  [disabled]="notificationPreferencesForm.pristine || notificationPreferencesForm.invalid" (click)="submit()">
            Save Changes
          </button>
        </div>
      </div>
    </div>
  </form>
  <br/>
</div>
<div class="clear-for-footer"></div>
<ng-template #loading_spinner>
  <div class="flex-container">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
