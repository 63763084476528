export class MxConstants {
  public static AUTH_TOKEN = 'mx_access_token';
  public static AUTH_USER_OBJECT = 'mx_user';
  public static AUTH_PATRON_OBJECT = 'mx_raven_patron';
  public static AUTH_ORGANIZATION_OBJECT = 'mx_organization';
  public static AUTH_TENANT = 'mx_tenant';
  public static AUTH_BRANCH_OBJECT = 'mx_branch';
  public static REDIRECT_URL = 'mx_redirect_url';
  public static AUTH_EXPIRES_AT = 'mx_expires_at';
  public static THEME_FIELD = 'theme';
  public static CATALOG_SAVED_FILTERS_KEY = 'mx.saved-catalog-filers';
}
