import {Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {BehaviorSubject, Observable, switchMap} from 'rxjs';
import {DOCUMENT} from '@angular/common';
import {MxConstants} from "@raven";

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  allThemes = [
    {label: 'Metropolitan', value: 'mx-metropolitan'},
    {label: 'Simplicity', value: 'theme-simplicity'},
  ];
  _currentTheme = 'theme-simplicity';

  private _themeSubject$: BehaviorSubject<string> = new BehaviorSubject(
    this._currentTheme
  );
  theme$: Observable<string> = this._themeSubject$.asObservable();

  private _renderer: Renderer2;
  private head: HTMLElement;
  private themeLinks: HTMLElement[] = [];

  constructor(
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) document: Document
  ) {
    this.head = document.head;
    this._renderer = rendererFactory.createRenderer(null, null);
    this.theme$
      .pipe(
        switchMap((themeStr) => {
          this.loadCss(themeStr).then(() => {
            if (this.themeLinks.length == 2) {
              this._renderer.removeChild(this.head, this.themeLinks.shift());
            }
          });
          return themeStr;
        })
      )
      .subscribe();

    // set the theme from localStorage if it is set, otherwise fallback to the default
    const storedTheme = localStorage.getItem(MxConstants.THEME_FIELD);
    this.setTheme(storedTheme || this.currentTheme);
  }

  get currentTheme(): string {
    return this._currentTheme;
  }

  set currentTheme(val) {
    if (this._currentTheme === val) {
      return;
    }

    this._currentTheme = val;
    localStorage.setItem(MxConstants.THEME_FIELD, val);
    this._themeSubject$.next(val);
  }

  getTheme(): string {
    return this.currentTheme;
  }

  setTheme(name: string) {
    this.currentTheme = name;
  }

  private async loadCss(filename: string) {
    return new Promise((resolve) => {
      const href = `${filename}.css`;
      const linkEl: HTMLElement = this._renderer.createElement('link');
      this._renderer.setAttribute(linkEl, 'rel', 'stylesheet');
      this._renderer.setAttribute(linkEl, 'type', 'text/css');
      this._renderer.setAttribute(linkEl, 'href', href);
      this._renderer.setProperty(linkEl, 'onload', resolve);

      // keep the 'link' element in the same place in the document even when replacing to avoid style precedence issues
      if (this.themeLinks.length) {
        // "insertAfter" by inserting before the nextSibling.  The internet says this should work even if nextSibling is null:
        // https://stackoverflow.com/questions/4793604/how-to-insert-an-element-after-another-element-in-javascript-without-using-a-lib
        this._renderer.insertBefore(this.head, linkEl, this.themeLinks[0].nextSibling);
      } else {
        this._renderer.appendChild(this.head, linkEl);
      }
      this.themeLinks = [...this.themeLinks, linkEl];
    });
  }
}
