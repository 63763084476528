<ng-container *ngIf="(availability$ | async ) as availability; else loading_template">
  <ng-container *ngIf="availability.branchAvailability.length > 0; else emptyAvailability">
    <h3>
      {{availability.totals.allBranchCount}} {{availability.totals.allBranchCount === 1 ? 'copy' : 'copies'}} at {{availability.branchAvailability.length}}
      branch{{availability.branchAvailability.length > 1 ? 'es' : ''}}
    </h3>
    <table mat-table aria-label="Item Availability" multiTemplateDataRows [dataSource]="availability.branchAvailability">
      <ng-container matColumnDef="library">
        <th mat-header-cell *matHeaderCellDef scope="col">Library</th>
        <td mat-cell *matCellDef="let b" [ngClass]="{'no-border': b.expanded}">{{b.branchName}}</td>
      </ng-container>
      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef scope="col">Location</th>
        <td mat-cell *matCellDef="let b">
          <mat-chip-list>
            <mat-chip *ngIf="b.patronHomeBranch" class="small-pill blue-pill">
              Home Branch
            </mat-chip>
            <mat-chip *ngIf="!b.patronHomeBranch" class="small-pill white-pill">
              In Network
            </mat-chip>
          </mat-chip-list>
        </td>
      </ng-container>
      <ng-container matColumnDef="availability">
        <th mat-header-cell *matHeaderCellDef scope="col">Item Availability</th>
        <td mat-cell *matCellDef="let b">
          <rn-availability-count-pill [available]="b.availableCount" [copies]="b.totalCopies"></rn-availability-count-pill>
        </td>
      </ng-container>
      <ng-container matColumnDef="expand-trigger">
        <th mat-header-cell *matHeaderCellDef scope="col"></th>
        <td mat-cell *matCellDef="let b">

          <a class="expand-trigger" (click)="expansionClick(b)" *ngIf="!b.expanded">+</a>
          <a class="expand-trigger" (click)="expansionClick(b)" *ngIf="b.expanded">-</a>
        </td>
      </ng-container>

      <ng-container matColumnDef="expanded">
        <td [attr.colspan]="displayColumns.length" class="teal-background" mat-cell *matCellDef="let b">
          <rn-catalog-details-branch-availability [branchAvailability]="b" style="width: 100%"></rn-catalog-details-branch-availability>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayColumns" class="mx-bold-underline" [ngClass]="{'no-padding': mobile}"></tr>
      <tr mat-row *matRowDef="let b; columns: displayColumns"
          [ngClass]="{'teal-background': false && b.expanded && !mobile, 'no-border': b.expanded, 'mx-taller-row': !mobile, 'no-padding': mobile}"></tr>
      <tr mat-row *matRowDef="let b; columns: ['expanded']; when: expanded"></tr>

    </table>
  </ng-container>
  <ng-template #emptyAvailability>
    <div class="empty-list center-center">
      This catalog entry does not have any items associated with it.
    </div>
  </ng-template>
</ng-container>

<ng-template #loading_template>
  <div class="flex-container">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
