import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {take} from 'rxjs/operators';
import moment, {Moment} from 'moment';
import {Hold, HOLD_STATUS} from '../model/hold';
import {Environment} from '../model/environment';
import {RoutesService} from '../core/routes/routes.service';
import {ResponseBase} from '../model/response-base/response-base';
import {NotificationService} from './notification.service';
import {PatronService} from './patron.service';
import {ServiceOptions, ServiceUtilService} from './service-util.service';
import {DatePipe} from "@angular/common";
import {NonBreakingPipe} from "../core/shared/pipes/non-breaking.pipe";

@Injectable({
  providedIn: 'root',
})
export class HoldService {

  constructor(
    private serviceUtil: ServiceUtilService,
    private environment: Environment,
    private http: HttpClient,
    private notificationService: NotificationService,
    private patronService: PatronService,
    private routingService: RoutesService,
    private datePipe: DatePipe,
    private nonBreakingPipe: NonBreakingPipe,
  ) {
  }

  /* ************** PATRON UI METHODS ************** */

  /**
   * Raven Service - Get Hold by Hold ID
   * @param holdId Hold ID of Hold record to pull
   * @param options Configuration for error/success messaging
   */
  getById(holdId: number, options?: ServiceOptions<Hold>): Observable<Hold> {
    const url = `${this.environment.apiUrl}/v1/holds/${holdId}`;
    const httpCall = this.http.get<Hold>(url);
    return this.serviceUtil.httpInvoker(httpCall, Object.assign({
      returnOnError: null,
      errorMessage: {
        401: 'Patron data not found. Please log in and try again.',
        'default': 'Unable to load hold record'
      }
    }, options));
  }

  /**
   * Raven Service - Get list of Holds for authenticated patron
   * @param filter Hold status for search filter
   * @param page Page number for search
   * @param limit Number of results per page for search
   * @param options Configuration for error/success messaging
   */
  getPatronHolds(filter: string, page: number, limit: number, options?: ServiceOptions<Hold>): Observable<ResponseBase<Hold>> {
    const url = `${this.environment.apiUrl}/v1/holds?`;
    let params = new HttpParams();
    params = params.append('page', '' + ((page >= 0) ? page : 0));
    params = params.append('limit', '' + ((limit > 0) ? limit : 5));
    params = params.append('filter', filter || '');
    const httpCall = this.http.get<ResponseBase<Hold>>(url, {params: params});
    return this.serviceUtil.httpInvoker(httpCall, Object.assign({
      returnOnError: null,
      errorMessage: {
        401: 'Patron data not found. Please log in and try again.',
        'default': 'Unable to load hold records'
      }
    }, options));
  }

  /**
   * Raven Service - Get active Holds for authenticated patron for given catalog record
   * @param catalogRecordId Catalog Record ID of holds to find
   * @param options Configuration for error/success messaging
   */
  getPatronActiveHoldsByCatalogRecord(catalogRecordId: number, options?: ServiceOptions<Hold>): Observable<Hold[]> {
    if (!this.patronService.patron) return of([]);
    const url = `${this.environment.apiUrl}/v1/holds/active/catalog-records/${catalogRecordId}`;
    const httpCall = this.http.get<Hold[]>(url);
    return this.serviceUtil.httpInvoker(httpCall, Object.assign({
      returnOnError: null,
      errorMessage: {
        401: 'Patron data not found. Please log in and try again.',
        'default': 'Unable to load hold records'
      }
    }, options));
  }

  /**
   * Raven Service - Create Hold record for authenticated Patron
   * @param catalogRecordId Catalog Record ID of item to place on hold
   * @param options Configuration for error/success messaging
   */
  create(catalogRecordId: number, options?: ServiceOptions<Hold>): Observable<Hold> {
    const url = `${this.environment.apiUrl}/v1/holds`;
    const httpCall = this.http.post<Hold>(url, catalogRecordId);
    return this.serviceUtil.httpInvoker(httpCall, Object.assign({
      returnOnError: null,
      successMessage: 'Hold has been placed',
      errorMessage: {
        400: 'Item does not exist or cannot be placed on hold.',
        403: 'Holds cannot be placed on items that are not within your library district.',
        424: () => {
          this.notificationService
            .showSnackbarError(
              'Currently you cannot place holds - either account is not in good standing or you have reached the hold limit',
              'View Account'
            )
            .onAction()
            .pipe(take(1))
            .subscribe(() => {
              return this.routingService.goToPatronBilling();
            });
        }
      }
    }, options));
  }

  /**
   * Raven Service - Pause Hold(s) by Hold ID(s)
   * @param holdIds Hold ID(s) of Hold record(s) to PAUSE
   * @param notWantedBefore Date (inclusive) record should be paused until
   * @param options Configuration for error/success messaging
   */
  pause(holdIds: number[], notWantedBefore: string | Moment, options?: ServiceOptions<boolean>): Observable<boolean> {
    notWantedBefore = moment(notWantedBefore, 'YYYY-MM-DD').startOf('day');
    const url = `${this.environment.apiUrl}/v1/holds/pause`;
    const httpCall = this.http.patch<never>(url, {holdIds: holdIds, notWantedBefore: notWantedBefore});
    const l = holdIds.length;
    return this.serviceUtil.httpInvoker(httpCall, Object.assign({
      returnOnError: false,
      returnOnSuccess: true,
      successMessage: `${l} ${l === 1 ? 'hold has' : 'holds have'} been paused until ${this.getNonBreakingDate(notWantedBefore.format())}`,
      errorMessage: {
        404: 'Invalid hold record(s), please refresh and try again.',
        403: 'Hold record(s) not found or you are not the owner of the hold, please refresh and try again.',
        424: 'Hold\'s can only be paused if they are in REQUESTED status',
      }
    }, options));
  }

  getNonBreakingDate(date: string) {
    return this.nonBreakingPipe.transform(
      this.datePipe.transform(date)
    );
  }

  /**
   * Raven Service - Resume Hold by Hold ID
   * @param holdIds HOld ID(s) of the Hold record(s) to RESUME
   * @param options Configuration for error/success messaging
   */
  resume(holdIds: number[], options?: ServiceOptions<boolean>): Observable<boolean> {
    const url = `${this.environment.apiUrl}/v1/holds/resume`;
    const httpCall = this.http.patch<never>(url, holdIds);
    const l = holdIds.length;
    return this.serviceUtil.httpInvoker(httpCall, Object.assign({
      returnOnError: false,
      returnOnSuccess: true,
      successMessage: `${l} ${l === 1 ? 'hold' : 'holds'} have been un\u2011paused/resumed.`,
      errorMessage: {
        404: 'Invalid hold record(s), please refresh and try again.',
        403: 'Hold record(s) not found or you are not the owner of the hold, please refresh and try again.',
        424: 'Hold\'s can only be resumed if they are in PAUSED status',
      }
    }, options));
  }

  /**
   * Raven Service - Cancel holds based on list of Hold Ids
   * @param holdIds List of Hold Ids to cancel
   * @param options Configuration for error/success messaging
   */
  cancel(holdIds: number[], options?: ServiceOptions<boolean>): Observable<boolean> {
    const url = `${this.environment.apiUrl}/v1/holds`;
    const httpCall = this.http.patch<never>(url, {}, {params: {itemHoldIds: holdIds}});
    const l = holdIds.length;
    return this.serviceUtil.httpInvoker(httpCall, Object.assign({
      returnOnError: false,
      returnOnSuccess: true,
      successMessage: `${l} ${l === 1 ? 'hold' : 'holds'} have been cancelled.`,
      errorMessage: {
        403: 'Hold record(s) not found or you are not the owner of the hold, please refresh and try again.',
        404: 'Invalid hold record(s), please refresh and try again.',
        417: 'Unable to cancel holds. Try again or contact library staff for assistance',
        424: 'Hold\'s can only be cancelled if they are in REQUESTED or PAUSED statuses',
      }
    }, options));
  }

  /**
   * Raven Service - Delete holds based on list of Hold Ids
   * @param holdIds List of Hold Ids to delete
   * @param options Configuration for error/success messaging
   */
  delete(holdIds: number[], options?: ServiceOptions<boolean>): Observable<boolean> {
    const url = `${this.environment.apiUrl}/v1/holds`;
    const httpCall = this.http.delete<never>(url, {params: {itemHoldIds: holdIds}});
    const l = holdIds.length;
    return this.serviceUtil.httpInvoker(httpCall, Object.assign({
      returnOnError: false,
      returnOnSuccess: true,
      successMessage: `${l} ${l === 1 ? 'hold' : 'holds'} have been deleted.`,
      errorMessage: {
        404: 'Invalid hold record(s), please refresh and try again.',
        403: 'Hold record(s) not found or you are not the owner of the hold, please refresh and try again.',
        417: 'Unable to delete holds. Try again or contact library staff for assistance',
        424: 'Please select Hold(s) to delete first',
      }
    }, options));
  }

  static getPauseableHolds(holds: Hold[]): Hold[] {
    return holds ? holds.filter(hold => HoldService.isRequestedStatus(hold)) : [];
  }

  static getCancellableHolds(holds: Hold[]): Hold[] {
    return holds ? holds.filter(hold => HoldService.isCancellableStatus(hold)) : [];
  }

  static getDeletableHolds(holds: Hold[]): Hold[] {
    return holds ? holds.filter(hold => HoldService.isDeletableStatus(hold)) : [];
  }

  static getUnpausableHolds(holds: Hold[]): Hold[] {
    return holds ? holds.filter(hold => HoldService.isPausedStatus(hold)) : [];
  }

  static isReadyForPickupStatus(hold: Hold): boolean {
    return hold && hold.status == HOLD_STATUS.PULLED;
  }

  static isInTransitStatus(hold: Hold): boolean {
    return hold && hold.status == HOLD_STATUS.TRANSIT;
  }

  static isRequestedStatus(hold: Hold): boolean {
    return hold && hold.status == HOLD_STATUS.REQUESTED && hold.notWantedBefore == null;
  }

  static isPausedStatus(hold: Hold): boolean {
    return hold && (hold.status == HOLD_STATUS.PAUSED || (hold.status == HOLD_STATUS.REQUESTED && hold.notWantedBefore != null));
  }

  static isCancellableStatus(hold: Hold): boolean {
    return hold && (hold.status == HOLD_STATUS.REQUESTED || hold.status == HOLD_STATUS.PAUSED);
  }

  static isDeletableStatus(hold: Hold): boolean {
    return hold && (hold.status == HOLD_STATUS.CANCELED);
  }

  static hasPausableHolds(holds: Hold[]): boolean {
    return holds && (holds.filter(hold => HoldService.isRequestedStatus(hold))).length > 0;
  }

  static hasUnpausableHolds(holds: Hold[]): boolean {
    return holds && (holds.filter(hold => HoldService.isPausedStatus(hold))).length > 0;
  }

  static hasCancellableHolds(holds: Hold[]): boolean {
    return holds && (holds.filter(hold => HoldService.isCancellableStatus(hold))).length > 0;
  }

  static hasDeletableHolds(holds: Hold[]): boolean {
    return holds && (holds.filter(hold => HoldService.isDeletableStatus(hold))).length > 0;
  }
}
