import {Component, OnInit} from '@angular/core';
import {AuthService} from './core/authentication/auth.service';
import {MxConstants, OrganizationService} from '@raven';
import {Title} from "@angular/platform-browser";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {combineLatest, filter, of, switchMap} from "rxjs";
import {map} from "rxjs/operators";

@Component({
  selector: 'rn-component',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private organizationService: OrganizationService,
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute.firstChild;
        while (child) {
          if (child.firstChild) {
            child = child.firstChild;
          } else if (child.snapshot.data &&    child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          } else {
            return null;
          }
        }
        return null;
      }),
      switchMap(title => {
        return combineLatest([of(title), this.organizationService.organization$])
      })
    ).subscribe( ([title, org]) => {
      const setTitle = title ? (title + ' - ' + org.name) : org.name;
      this.titleService.setTitle(setTitle);
    });
  }

  async ngOnInit(): Promise<void> {
    localStorage.removeItem(MxConstants.CATALOG_SAVED_FILTERS_KEY);
  }
}
