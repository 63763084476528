import {Component, Input, OnInit} from '@angular/core';
import {CatalogRecord} from '@raven';

@Component({
  selector: 'rn-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
})
export class ListItemComponent implements OnInit {

  @Input() catalogRecord: CatalogRecord;
  @Input() topBorder: boolean;

  constructor() {}

  ngOnInit(): void {}
}
