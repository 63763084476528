import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'rn-grid-list-select',
  template: `
    <div class="display-mode">
      <button
        (click)="setDisplayMode('grid')"
        [ngClass]="{ selected: displayMode !== 'list' }"
      >
        <i class="ri-layout-grid-line"></i>
      </button>
      <button
        (click)="setDisplayMode('list')"
        [ngClass]="{ selected: displayMode === 'list' }"
      >
        <i class="ri-list-unordered"></i>
      </button>
    </div>
  `,
  styles: [
    `
      .display-mode {
        display: inline-block;
        vertical-align: middle;

        button {
          cursor: pointer;
          width: 30px;
          height: 30px;
          box-sizing: border-box;
          border: 1px solid #dfe0e1;
          background-color: #ffffff;
          color: #202828;

          mat-icon {
            font-size: 20px;
            width: 20px;
            height: 20px;
          }
        }

        button {
          color: #000;
          background-color: #fff;
        }

        button.selected {
          color: #fff;
          background-color: #000;
          border: 1px solid #000;
        }
      }
    `,
  ],
})
export class GridListSelectComponent {
  @Input() displayMode: string;
  @Output() displayModeChange: EventEmitter<string> = new EventEmitter();

  setDisplayMode(mode: string) {
    this.displayMode = mode;
    this.displayModeChange.emit(mode);
  }
}
