import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'rn-expansion-panel',
  styleUrls: [
    '../../catalog/catalog-browse/components/catalog-facets/catalog-facets.component.scss',
  ],
  styles: [
    `
      // ***** original styles below ****

      .header {
        cursor: pointer;
      }
      .expand-indicator {
        color: var(--active);
        font-weight: 800;
        margin: 0 5px auto auto;
      }
      .collapse > .content {
        display: none;
      }
    `,
  ],
  template: `
    <div class="facet" [ngClass]="{ collapse: isCollapsed }">
      <div class="header flex-row flex-start-start" (click)="isCollapsed = !isCollapsed">
        <ng-content select="[expansionHeader]"></ng-content>
        <div class="expand-indicator" style="margin-left:auto;margin-right:5px;font-size:20px;">
          <div *ngIf="isCollapsed">+</div>
          <div *ngIf="!isCollapsed">-</div>
        </div>
      </div>
      <div class="content">
        <ng-content></ng-content>
      </div>
    </div>
  `,
})
export class ExpansionPanelComponent implements OnInit {
  @Input() isCollapsed = false;

  ngOnInit(): void {}
}
