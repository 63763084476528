<ng-container *ngIf="showFavoriteStatus && favoriteService?.favorites | async as favorites; else plainCover">
    <ng-container *ngTemplateOutlet="favoriteEnabledCover; context: { isFavorite: !!favorites[catalogRecord.id] }"></ng-container>
</ng-container>

<ng-template #plainCover>
  <a routerLink="/catalog-item/{{ catalogRecord.id }}">
      <rn-fallback-image [imageUrls]="imageUrls" [alt]="catalogRecord.title"></rn-fallback-image>
  </a>
</ng-template>

<ng-template #favoriteEnabledCover let-isFavorite="isFavorite">
    <a routerLink="/catalog-item/{{ catalogRecord.id }}">
        <rn-fallback-image [imageUrls]="imageUrls" [alt]="catalogRecord.title"></rn-fallback-image>
    </a>
    <ng-container *ngTemplateOutlet="favoriteToggle; context: { isFavorite: isFavorite }"></ng-container>
</ng-template>

<ng-template #favoriteToggle let-isFavorite="isFavorite">
    <div class="favorite-control" (click)="$event.stopPropagation()">
        <mat-icon *ngIf="!isFavorite" (click)="addFavorite()" class="favorite-heart">favorite_border</mat-icon>
        <mat-icon *ngIf="isFavorite" (click)="deleteFavorite()" class="favorite-heart">favorite</mat-icon>
    </div>
</ng-template>
