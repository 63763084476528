import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, filter, Observable, of, Subject, switchMap, take} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {PatronAccountStatus} from '../model/patron/patron-account-status';
import {Organization} from '../model/organization';
import {Patron, PATRON_STATUS} from '../model/patron/patron';
import {Environment} from '../model/environment';
import {GlobalState, PatronService, selectOrganization, selectPatron} from '@raven';
import {NotificationService} from './notification.service';
import {PatronLedger} from '../model/patron/patron-ledger';
import {PatronTransactions} from '../model/patron/patron-transactions';
import {error} from 'ng-packagr/lib/utils/log';

@Injectable({
  providedIn: 'root',
})
export class PatronLedgerService {
  patronLedgersIn = new BehaviorSubject<PatronTransactions>(null);
  patronLedgers = this.patronLedgersIn.asObservable();
  patronStatusSubject = new Subject<PatronAccountStatus>();

  organization: Organization;
  patron: Patron;

  constructor(private environment: Environment,
              private notificationService: NotificationService,
              private patronService: PatronService,
              private http: HttpClient,
              private theStore: Store<GlobalState>) {

    theStore.select(selectOrganization).subscribe((organization) => {
      this.organization = organization;
    });

    theStore.select(selectPatron).subscribe((patron) => {
      this.patron = patron;
    });

    patronService.patron$.subscribe(() => {
      this.getLedger().subscribe(ledgers => this.patronLedgersIn.next(ledgers));
    });
  }

  getById(patronLedgerId: number): Observable<PatronLedger> {
    const url = `${this.environment.apiUrl}/v1/patron-ledgers/${patronLedgerId}`;

    if (!patronLedgerId) {
      return of(null);
    }

    return this.http.get<PatronLedger>(url).pipe(
      catchError(() => {
        this.notificationService.showSnackbarError(
          'Unable to load patron ledger record'
        );
        return of(null);
      })
    );
  }

  getLedger(): Observable<PatronTransactions> {
    if (!this.patronService.patron) return of(new PatronTransactions())
    return this.http.get<PatronTransactions>(`${this.environment.apiUrl}/v1/patrons/account/transactions`);
  }

  refreshLedgers(): void {
    this.getLedger().subscribe((_) => this.patronLedgersIn.next(_));
  }

  refreshStatus(): void {
    if (!this.patronService.patron) return;
    this.http.get<PatronAccountStatus>(`${this.environment.apiUrl}/v1/patrons/account/status`)
      .subscribe(status => this.patronStatusSubject.next(status));
  }

  getAccountStatus(): Observable<PatronAccountStatus> {
    this.refreshStatus();
    return this.patronStatusSubject.asObservable();
  }

  static canCheckout$(accountStatus$: Observable<PatronAccountStatus>): Observable<boolean> {
    return accountStatus$.pipe(map((accountStatus) => {
      return PatronLedgerService.canCheckout(accountStatus);
    }));
  }

  static canPlaceHold$(accountStatus$: Observable<PatronAccountStatus>): Observable<boolean> {
    return PatronLedgerService.canCheckout$(accountStatus$);
  }

  static canRenew$(accountStatus$: Observable<PatronAccountStatus>): Observable<boolean> {
    return PatronLedgerService.canCheckout$(accountStatus$);
  }

  static canRequestItems$(accountStatus$: Observable<PatronAccountStatus>): Observable<boolean> {
    return PatronLedgerService.canCheckout$(accountStatus$);
  }

  static canCheckout(accountStatus: PatronAccountStatus): boolean {
    return accountStatus.accountStatus != PATRON_STATUS.SUSPENDED
      && accountStatus.accountStatus != PATRON_STATUS.EXPIRED
      && accountStatus.currentAccountBalance < accountStatus.maximumAccountBalance;
  }

  static canPlaceHold(accountStatus: PatronAccountStatus): boolean {
    return PatronLedgerService.canCheckout(accountStatus);
  }

  static canRenew(accountStatus: PatronAccountStatus): boolean {
    return PatronLedgerService.canCheckout(accountStatus);
  }

  static underMaxBalance(accountStatus: PatronAccountStatus): boolean {
    return accountStatus.currentAccountBalance < accountStatus.maximumAccountBalance;
  }

  makeAPayment(paymentMetadata: any): Observable<any> {
    const result = this.http
      .post<PatronLedger>(
        `${this.environment.apiUrl}/organizations/${this.organization.id}/patron-ledgers`,
        {
          organizationId: this.organization.id,
          patronId: this.patron.id,
          amount: paymentMetadata.amount,
        }
      )
      .pipe(
        tap((_response) => {
          // we just wrote a new entry, re-fetch from the server
          // if we get this data model figured out maybe we won't need a full re-fetch
          this.getLedger().subscribe((_) => this.patronLedgersIn.next(_));
        })
      );

    result.subscribe();
    return result;
  }

  delete(patronLedgerId: number): Observable<PatronLedger> {
    return new Observable<PatronLedger>(null);
  }
}
