<ng-container *ngIf="data$ | async as data">
  <div class="flex-row-wrap">
    <div class="mat-display-1 item-requests-title">Active Requests ({{ data.activeRequests.length }})</div>
    <span class="five-per-month">There is a maximum of 5 requested items in a calendar month</span>
  </div>
  <!--  Visually this looks like 2 separate tables, but to get the columns to align it needs to be -->
  <!--  one table with a header row partway down-->
  <ng-container *ngTemplateOutlet="requestTable; context: {
        requests: data.activeRequests.concat(['CLOSEDHEADER']).concat(data.closedRequests),
        closedCount: data.closedRequests.length }">
  </ng-container>
</ng-container>

<ng-template #requestTable let-requests="requests" let-closedCount="closedCount">
  <table mat-table aria-label="Item Requests" [dataSource]="requests">
    <ng-container matColumnDef="mobile">
      <th mat-header-cell *matHeaderCellDef scope="col"></th>
      <td mat-cell *matCellDef="let request">
        <ng-container *ngTemplateOutlet="mobileTableEntry; context:{request: request}"></ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef scope="col">Comment</th>
      <td mat-cell *matCellDef="let request" class="comment-cell">
        <ng-container *ngTemplateOutlet="comment; context: {request: request}"></ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef scope="col">Title</th>
      <td mat-cell *matCellDef="let request">
        {{ request.title }}
      </td>
    </ng-container>
    <ng-container matColumnDef="author">
      <th mat-header-cell *matHeaderCellDef scope="col">Author</th>
      <td mat-cell *matCellDef="let request">
        {{ request.author }}
      </td>
    </ng-container>
    <ng-container matColumnDef="publicationDate">
      <th mat-header-cell *matHeaderCellDef scope="col">Publication Date</th>
      <td mat-cell *matCellDef="let request">
        {{ request.publicationDate.isValid()
          ? (request.publicationDate | date: 'YYYY')
          : '' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="requestDate">
      <th mat-header-cell *matHeaderCellDef scope="col">Request Date</th>
      <td mat-cell *matCellDef="let request">
        {{ request.requestDate.isValid()
          ? request.requestDate.toDate().toLocaleDateString()
          : '' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef scope="col">Status</th>
        <td mat-cell *matCellDef="let request">
          <ng-container *ngTemplateOutlet="statusPill; context: { status: request.status }"></ng-container>
        </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef scope="col">Actions</th>
      <td mat-cell *matCellDef="let request" (click)="$event.stopPropagation()">
        <span class="ri-close-circle-line pointer" style="margin-right: 8px"
              (click)="deleteItemRequest(request)" matTooltip="Cancel" matTooltipPosition="above"></span>
      </td>
    </ng-container>
    <!-- This "column" is the only one in the mid-table "header" row so make it full width-->
    <ng-container matColumnDef="closedRequestsHeader">
      <td mat-cell *matCellDef="let heading" [attr.colspan]="columnsToDisplay.length" style="padding: 0; border: none">
        <div class="mat-display-1 item-requests-title">Closed Requests ({{ closedCount }})</div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay" class="mx-bold-underline"></tr>
    <tr mat-row *matRowDef="let request; columns: columnsToDisplay" class="mx-taller-row pointer" (click)="goToRequest(request)"></tr>
    <tr mat-row *matRowDef="let request; columns: ['closedRequestsHeader']; when: renderMidHeader"></tr>
  </table>
</ng-template>

<ng-template #comment let-request="request">
  <!--  Once we have un-read status by request we'll display one of these conditionally-->
  <span class="has-comment" *ngIf="request.hasUnreadStaffComments">
    <em class="ri-message-3-fill"></em>
  </span>
  <span class="no-comment" *ngIf="!request.hasUnreadStaffComments">
    <em class="ri-message-3-line"></em>
  </span>
</ng-template>

<ng-template #mobileTableEntry let-request="request">
  <div class="mobile-table-entry flex-col">
    <a class="mobile-title-link">{{ request.title }}</a>
    <span>Author: {{ request.author }}</span>
    <span>Publication Date: {{ request.publicationDate.isValid()
        ? (request.publicationDate | date: 'YYYY') : '' }}
    </span>
    <span>Request Date: {{ request.requestDate.isValid()
        ? request.requestDate.toDate().toLocaleDateString() : '' }}
    </span>
    <div class="mobile-status">
      <ng-container *ngTemplateOutlet="statusPill; context: { status: request.status }"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #statusPill let-status="status">
  <mat-chip-list *ngIf="true">
    <mat-chip class="gray-pill small-pill" *ngIf="status === ITEM_REQUEST_STATUS.OPEN">Requested</mat-chip>
    <mat-chip class="red-pill small-pill" *ngIf="status === ITEM_REQUEST_STATUS.CLOSED">Closed</mat-chip>
    <mat-chip class="green-pill small-pill" *ngIf="status === ITEM_REQUEST_STATUS.REVIEW">In Review</mat-chip>
  </mat-chip-list>
</ng-template>
