<div class="registration-page flex-row flex-start-start">
  <div class="main-panel flex-1">
    <div class="mat-display-1 registration-title">
      In Person Registration
    </div>
    <h4>
      Thank you for your application. Unfortunately you cannot be approved
      online because you are under 18 years old. Please visit your local
      library branch with a guardian to complete your registration.
    </h4>
  </div>
  <rn-registration-side-panel></rn-registration-side-panel>
</div>
