<div class="mycard-header">
  <div class="flex-row-wrap-col-responsive flex-center-start flex-gap-30">
    <div class="mycard-title">
      <div class="mat-display-1" style="margin-bottom: 16px">My Library Card</div>
      <h4 style="margin-bottom: 0; display: inline">Status:&nbsp;&nbsp;&nbsp;</h4>
      <mat-chip-list style="display: inline-block; transform: translateY(-1px)">
        <mat-chip class="green-pill small-pill" selected *ngIf="patron.status == PATRON_STATUS.ACTIVE">Active</mat-chip>
        <mat-chip class="yellow-pill small-pill" selected *ngIf="patron.status == PATRON_STATUS.NEW">Pending</mat-chip>
        <mat-chip class="red-pill small-pill" selected *ngIf="patron.status == PATRON_STATUS.DENIED">Denied</mat-chip>
        <mat-chip class="red-pill small-pill" selected *ngIf="patron.status == PATRON_STATUS.EXPIRED">Expired</mat-chip>
        <mat-chip class="yellow-pill small-pill" selected *ngIf="patron.status == PATRON_STATUS.SUSPENDED">Suspended</mat-chip>
      </mat-chip-list>
    </div>
    <div *ngIf="patron.underBalanceLimit && patron.status == PATRON_STATUS.EXPIRED" class="mycard-message">
      <div class="mat-small">Your account is in good standing.</div>
      <button class="mat-raised-button mat-primary" (click)="renewAccount()">
        Renew Account
      </button>
    </div>
    <div *ngIf="patron.underBalanceLimit != null && (!patron.underBalanceLimit) && patron.status == PATRON_STATUS.EXPIRED" class="mycard-message">
      <div class="mat-small">You must settle your account before you may renew.</div>
      <a class="pointer" (click)="routingService.goToPatronBilling()">Go to billing</a>
    </div>
  </div>
</div>
<div class="mycard-section flex-row-wrap-col-responsive flex-center-start flex-gap-30">
  <mat-card class="mycard-front mat-elevation-z3">
    <h3 class="mycard-lib-name" *ngIf="(organizationService.organization$ | async) as organization">
      <ng-container *ngIf="organization.iconUrl; else placeholderOrgIcon">
        <img [alt]="organization.name" [src]="organization.iconUrl"/>
      </ng-container>
      <ng-template #placeholderOrgIcon>
        <img alt="Millonex Logo" src="/assets/mx-logo.png"/>
      </ng-template>
      {{ organization.name }}
    </h3>
    <div class="mycard-details mat-small" *ngIf="branch$ | async as branch">
      <ng-container *ngIf="branch.address && branch.phone; else placeholderBranchInfo">
        <span>{{branch.address}}</span><br/>
        <span *ngIf="branch.address2">{{ branch.address2 }}<br/></span>
        <span>{{branch.city}}, {{branch.state}} {{branch.zipCode}}</span><br/>
        <span>{{branch.phone}}</span>
      </ng-container>
      <ng-template #placeholderBranchInfo>
        <span>1234 Street Name</span><br/>
        <span>City, ST, 12345</span><br/>
        <span>123-456-7790</span>
      </ng-template>
    </div>
    <h2 class="mycard-patron-name">
      {{ patron.firstName }} {{ patron.lastName }}
    </h2>
  </mat-card>
  <mat-card class="mycard-back mat-elevation-z3">
    <div class="mycard-barcode" [ngClass]="{ 'mycard-blank-barcode': !isActiveOrExpired() }">
      <div *ngIf="isActiveOrExpired()">
        <ngx-barcode [bc-value]="patron.barcode" [bc-display-value]="false"></ngx-barcode>
      </div>
    </div>
    <div class="mat-small">
      {{
        this.patron.status == PATRON_STATUS.ACTIVE
            ? this.patron.barcode
            : '0000 0000 0000 0000'
      }}
    </div>
    <div class="mycard-expiration mat-small">
      <ng-container *ngIf="!isActiveOrExpired()">Valid Thru: 00/00</ng-container>
      <ng-container *ngIf="patron.status == PATRON_STATUS.EXPIRED">
                Expired on: {{ this.patron.accountExpiration | date: 'M/d/y' }}
            </ng-container>
            <ng-container *ngIf="patron.status == PATRON_STATUS.ACTIVE">
                Valid Thru: {{ this.patron.accountExpiration | date: 'M/d/y' }}
            </ng-container>
        </div>
  </mat-card>
</div>
