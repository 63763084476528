import {Component, Input} from '@angular/core';
import {CatalogRecord, RoutesService} from '@raven';

@Component({
  selector: 'rn-book-carousel',
  template: `
    <carousel [title]="title">
      <ng-container *ngFor="let catalogRecord of catalogRecords; let last = last">
        <div class="carousel-item" *carouselItem [ngClass]="{ 'last' : last }">
          <rn-grid-item [catalogRecord]="catalogRecord"></rn-grid-item>
        </div>
      </ng-container>
      <ng-template #emptyTemplate>
        <div class="empty-content flex-col flex-center-center">
          <h1 style="margin: auto auto">Check back later for more titles</h1>
        </div>
      </ng-template>
    </carousel>
  `,
  styleUrls: ['./book-carousel.component.scss'],
})
export class BookCarouselComponent {
  @Input() title = '';
  @Input() catalogRecords: CatalogRecord[];

  constructor(public routesService: RoutesService) {

  }
}
