import {CatalogRecord} from '../catalog-record';
import {CatalogFacets} from './catalog-facets';

export class CatalogSearchResponse {
  resultsPage: CatalogRecord[];
  totalResults: number;
  facets: CatalogFacets
}

export class FacetValue {
  displayValue?: string;
  filterValue: string;
  count?: number;
  selected?: boolean;
}

export class CatalogFacet {
  type?: CatalogFacetType;
  values: FacetValue[];
  name: string;
}

export enum CatalogFacetType {
  INTERSECTION = 'INTERSECTION',
  UNION = 'UNION',
}