<div class="login-container">
  <div class="login-column flex-col">
    <div class="title-text">Login</div>
    <h4 class="secondary-text">Welcome back! Please enter your username and password to proceed.</h4>
    <form [formGroup]="loginForm" *ngIf="loginForm">
      <mat-label>Email address*</mat-label>
      <mat-form-field appearance="outline">
        <input matInput type="email" formControlName="email" name="email" required maxlength="256" placeholder="Enter Email address">
        <mat-error *ngIf="loginForm.get('email').invalid">
          <ng-container>Please enter a valid email</ng-container>
        </mat-error>
      </mat-form-field>
      <mat-label>Password*</mat-label>
      <mat-form-field appearance="outline">
        <input matInput type="password" formControlName="password" required maxlength="256" placeholder="Enter password">
        <mat-error *ngIf="loginForm.get('password').invalid">Password is required</mat-error>
      </mat-form-field>
      <div class="mat-small"><a class="pointer" (click)="forgotPassword()">Forgot password?</a></div>
      <button mat-raised-button color="primary" class="form-submit-button"
              [disabled]="loginForm.invalid"
              (click)="login()">Login
      </button>
      <div class="mat-small center-center">Don't have an account?&nbsp;<a class="pointer" (click)="navRegister()">Register</a></div>
    </form>
  </div>
</div>
