export class ChiliFreshRating {
  isbn: string;
  reviewCount: number;
  suggestCount: number;
  ratingCount: number;
  averageRating: number;
  permanentUrlSecure: string

  constructor(json: RawChiliFreshRating) {
    this.isbn = json.isbn;
    this.reviewCount = parseInt(json.review_count);
    this.suggestCount = parseInt(json.suggest_count);
    this.ratingCount = parseInt(json.rating_count);
    this.averageRating = parseFloat(json.average_rating);
    this.permanentUrlSecure = json.permanent_url_secure;
  }

  // combines ratings for multiple ISBNs/identifiers into one for display in CatalogRecord details
  static combineRatings(ratings: ChiliFreshRating[]): ChiliFreshRating {
    if (!ratings || ratings.length === 0) {
      return null;
    }
    ratings = ratings.map(r => Object.assign(r, {averageRating: r.averageRating,}))
    let combined = {} as ChiliFreshRating;
    for (const i in ratings) {
      if (i === '0') {
        combined = Object.assign(combined, ratings[i]);
      } else {
        combined.averageRating = (combined.averageRating * combined.ratingCount + ratings[i].ratingCount * ratings[i].averageRating) / (combined.ratingCount + ratings[i].ratingCount);
        combined.ratingCount += ratings[i].ratingCount;
      }
    }
    return combined;
  }
}

export type RawChiliFreshRating = {
  isbn: string;
  review_count: string;
  suggest_count: string;
  rating_count: string;
  average_rating: string;
  permanent_url_secure: string
}