import {GlobalState} from '@raven';

export const initialState: GlobalState = {
  app: {
    // the initial state is essentially null
    patron: null,
    organization: null,
    branch: null,
    adminPage: false,
    tenant: null,
  },
};
