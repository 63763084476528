import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DatePipe} from '@angular/common';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {MatDialog} from '@angular/material/dialog';
import {BehaviorSubject, combineLatest, delay, EMPTY, filter, mergeMap, Observable, of, shareReplay, Subject, switchMap, takeUntil} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {
  AuthService,
  BowkerMetadata,
  CancelHoldDialog,
  CatalogRecord,
  CatalogRecordService,
  ChiliFreshProReview,
  ChiliFreshRating,
  ChiliFreshService,
  ConfirmationDialogComponent,
  FavoriteService,
  Hold,
  HoldService,
  IdentifierType,
  NotificationService,
  OrganizationService,
  PatronLedgerService,
  PatronService,
  PauseHoldDialog,
  UnpauseHoldDialog
} from '@raven';

@Component({
  selector: 'rn-catalog-record-n',
  templateUrl: './catalog-item-details.component.html',
  styleUrls: ['./catalog-item-details.component.scss'],
})
export class CatalogItemDetailsComponent implements OnInit, OnDestroy {
  loading = true;

  catalogRecordId: number;
  catalogRecord$: Observable<CatalogRecord>;
  item$: Observable<object>;
  chiliFreshRating$: Observable<ChiliFreshRating>;
  chiliFreshProReviews$: Observable<ChiliFreshProReview[]>;
  hold: Hold;
  relatedTitles$: Observable<Array<CatalogRecord>>;
  refreshHoldsSubject = new BehaviorSubject(true);
  patronCanPlaceHold = false;
  destroy$ = new Subject<boolean>();
  meta$: Observable<BowkerMetadata>;
  CatalogRecord = CatalogRecord;
  mobile = false;


  // mock data for now
  meta: object;
  otherFormats: Array<object>;
  otherFormatCount = 1;

  constructor(
    private authService: AuthService,
    public patronService: PatronService,
    public organizationService: OrganizationService,
    private catalogRecordService: CatalogRecordService,
    private holdService: HoldService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private patronLedgerService: PatronLedgerService,
    private chiliFreshService: ChiliFreshService,
    public favoriteService: FavoriteService,
    private dialog: MatDialog,
    public datePipe: DatePipe,
    private breakpointObserver: BreakpointObserver
  ) {
  }

  ngOnInit(): void {
    this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: BreakpointState) => {
        this.mobile = state.matches;
      });

    const catalogRecordId$ = this.route.paramMap
      .pipe(takeUntil(this.destroy$))
      .pipe(map(paramMap => Number(paramMap.get('id'))))
      .pipe(filter(id => Number.isInteger(id)))
      .pipe(tap(x => {
        this.catalogRecordId = x;
        this.loading = true;
      }))
      .pipe(shareReplay({bufferSize: 1, refCount: false}));

    this.catalogRecord$ = catalogRecordId$
      .pipe(mergeMap(catalogRecordId => {
        return this.catalogRecordService.getById(catalogRecordId);
      }))
      .pipe(shareReplay({bufferSize: 1, windowTime: undefined, refCount: false}));

    this.chiliFreshRating$ = this.catalogRecord$.pipe(
      switchMap(cr => cr.identifier ? this.chiliFreshService.getCombinedRating([cr.identifier]) : EMPTY),
    );

    this.relatedTitles$ = catalogRecordId$.pipe(
      switchMap(id => this.catalogRecordService.getRelatedTitles(id)),
    );

    this.patronLedgerService.getAccountStatus()
      .pipe(PatronLedgerService.canPlaceHold$)
      .pipe(takeUntil(this.destroy$))
      .subscribe((canPlaceHold) => this.patronCanPlaceHold = canPlaceHold);

    this.meta$ = this.catalogRecord$.pipe(
      switchMap(cr => cr.identifier && cr.identifierType == IdentifierType.ISBN ? this.catalogRecordService.getMetadata(cr.identifier) : of({} as BowkerMetadata))
    )
    
    const holds$ = this.authService.isAuthenticated() ? this.getPatronHolds$() : of(null);

    this.setupMockData();

    combineLatest([this.catalogRecord$, holds$])
      .pipe(
        tap(() => window.scrollTo(0, 0)),
        delay(100),
        tap(([item, holds]) => {
          this.loading = false;
        })
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  setupMockData(): void {
    this.meta = {
      thumbnailImageUrl:
        'https://images-na.ssl-images-amazon.com/images/I/81XWWgotqvL.jpg',
      rating: 2.5,
      ratingCount: 88,
      favorite: false,
      otherFormatCount: 4,
    };
    this.otherFormats = [];
    for (let i = 0; i < 4; i++) {
      this.otherFormats.push({
        title: 'The Paper Palace 2',
        narrator: 'Jerry',
        publisher: 'McNamara',
        duration: '14 hours',
        publicationYear: '2021',
        format: 'Audiobook',
      });
    }
  }

  getPatronHolds$(): Observable<Hold[]>{
    return combineLatest([this.catalogRecord$, this.refreshHoldsSubject.asObservable()])
      .pipe(
        switchMap(([catalogRecord,]) => this.holdService.getPatronActiveHoldsByCatalogRecord(catalogRecord.id)),
        tap(holds => {
          if (holds && holds.length > 0) {
            this.hold = holds[0];
          } else {
            this.hold = null;
          }
        })
      );
  }

  addFavorite(): void {
    this.favoriteService.addFavorite(this.catalogRecordId).subscribe();
  }

  deleteFavorite(): void {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {title: `Favorites`, message: 'Are you sure you want to un-favorite this item?'},
      })
      .afterClosed()
      .pipe(
        switchMap((confirm) => {
          console.log(confirm);
          if (confirm) {
            return this.favoriteService.deleteFavorite(this.catalogRecordId)
          }
        })
      )
      .subscribe();
  }

  placeHold(): void {
    this.holdService.create(this.catalogRecordId)
      .subscribe((hold) => {
        if (hold) {
          this.hold = hold;
        }
      });
  }

  onHoldRemoved(): void {
    this.hold = null;
    this.refreshHold();
  }

  refreshHold(): void {
    this.refreshHoldsSubject.next(true);
  }

  pauseHold(): void {
    this.dialog.open(PauseHoldDialog, {
      maxWidth: '95vw',
      data: {holds: [this.hold], refreshHolds: this.refreshHold.bind(this)}
    });
  }

  unpauseHold(): void {
    this.dialog.open(UnpauseHoldDialog, {
      maxWidth: '95vw',
      data: {holds: [this.hold], refreshHolds: this.refreshHold.bind(this)}
    });
  }

  cancelHold(): void {
    this.dialog.open(CancelHoldDialog, {
      maxWidth: '95vw',
      data: {holds: [this.hold], refreshHolds: this.onHoldRemoved.bind(this)}
    });
  }

  async copyLink() {
    const url = window.location.href;
    await navigator.clipboard.writeText(url);
    this.notificationService.showSnackbarSuccess('Link copied to clipboard');
  }

  isReadyForPickupStatus(hold: Hold): boolean {
    return HoldService.isReadyForPickupStatus(hold);
  }

  isInTransitStatus(hold: Hold): boolean {
    return HoldService.isInTransitStatus(hold);
  }

  isRequestedStatus(hold: Hold): boolean {
    return HoldService.isRequestedStatus(hold);
  }

  isPausedStatus(hold: Hold): boolean {
    return HoldService.isPausedStatus(hold);
  }

  isCancellableStatus(hold: Hold): boolean {
    return HoldService.isCancellableStatus(hold);
  }

  isDeletableStatus(hold: Hold): boolean {
    return HoldService.isDeletableStatus(hold);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
}
