import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree,} from '@angular/router';
import {combineLatest, Observable, of, switchMap} from 'rxjs';
import {map, take} from 'rxjs/operators';
import moment from 'moment';
import {AuthService, Patron, PATRON_STATUS, PatronService} from '@raven';

@Injectable({
  providedIn: 'root',
})
export class RegistrationGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private patronService: PatronService,
    private router: Router,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean | Promise<boolean | UrlTree> | Observable<boolean | UrlTree> {

    return combineLatest([
      this.patronService.patron$.pipe(take(1)),
      this.patronService.patron$.pipe(take(1)).pipe(switchMap(this.hasNotificationPrefs.bind(this)))
    ])
      .pipe(map(([patron, hasNotificationPrefs]) => {
        // If they are not logged in then let AuthGuard handle things, we don't care
        if(!patron) { return true; }

        const isRegistrationUrl = (state.url.indexOf('register') >= 0)
        const registrationComplete = patron && patron.emailVerified && !this.missingPersonalInfo(patron) && hasNotificationPrefs && !this.isUnderage(patron);

        if (registrationComplete && !isRegistrationUrl) {
          // standard user, registration complete
          return true;
        } else if (registrationComplete && isRegistrationUrl) {
          // the user is done registering, kick them out of registration
          return this.router.createUrlTree(['/']);
        } else if (!registrationComplete) {
          // the user needs to finish registration, let's get them to the right page
          const patronWithNotificationPrefState = Object.assign(new Patron(), {
            ...patron,
            hasNotificationPreferences: hasNotificationPrefs
          });
          return this.routeToCorrectRegistrationStep(patronWithNotificationPrefState, state.url);
        } else {
          // this should never happen, the above cases should be everything
          return true;
        }
      }));
  }

  routeToCorrectRegistrationStep(patron, url): boolean | UrlTree {
    // the user needs to finish registration, let's get them to the right page
    if(!patron || !patron.emailVerified) {
      return this.ensureUrl(url, ['/register', 'register-existing-card']);
    } else if(this.missingPersonalInfo(patron)) {
      return this.ensureUrl(url, ['/register/patron-info']);
    } else if(!patron.hasNotificationPreferences) {
      return this.ensureUrl(url, ['/register/notifications']);
    } else if (this.isUnderage(patron)) {
      return this.ensureUrl(url, ['/register/in-person']);
    }
  }

  ensureUrl(currentUrl: string, targetUrls: Array<string>) {
    // Make sure we're on one of the Urls in the target array, redirect to [0] if not
    return targetUrls.indexOf(currentUrl) >= 0 || this.router.createUrlTree([targetUrls[0]]);
  }

  missingPersonalInfo(patron: Patron): boolean {
    return (
      !patron.firstName ||
      !patron.firstName.trim() ||
      !patron.lastName ||
      !patron.lastName.trim()
    );
  }

  isUnderage(patron: Patron): boolean {
    // check if this is a self registration
    if (patron.status && (patron.status != PATRON_STATUS.NEW)) {
      return false;
    }
    // determine age
    const d = moment(patron.dateOfBirth, 'YYYY-M-D');
    const age = Math.floor(moment.duration(moment().diff(d)).years());
    return age < 18;
  }

  hasNotificationPrefs(patron): Observable<boolean> {
    // not authenticated, obviously no preferences
    if (!patron || patron.id === 0) {
      return of(false);
    }
    // we've already made this check, let them pass
    if (patron.hasNotificationPreferences) {
      return of(true);
    }

    return this.patronService.hasCommunicationPreferences();
  }
}
