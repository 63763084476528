import {MAT_SNACK_BAR_DATA, MatSnackBarRef, SimpleSnackBar,} from '@angular/material/snack-bar';
import {Component, Inject} from '@angular/core';

@Component({
  selector: 'mx-snackabar-banner',
  template: `
    <div class="notification-bar-message">
      <span class="notification-icon ri-error-warning-fill" *ngIf="data.type == 'error'"></span>
      <span class="notification-icon ri-information-fill" *ngIf="data.type == 'info'"></span>
      <span class="notification-icon ri-checkbox-circle-fill" *ngIf="data.type == 'success'"></span>
      <span class="notification-icon ri-error-warning-fill" *ngIf="data.type == 'warn'"></span>
      <span>{{ data.message }}</span>
      <div class="spacer"></div>
      <mat-button class="action-button" *ngIf="data.action" (click)="snackBarRef.dismissWithAction()">{{data.action}}</mat-button>
      <span *ngIf="!data.action" class="notification-icon ri-close-fill" (click)="snackBarRef.dismiss()"></span>
    </div>
  `,
  styles: [
    `
      .notification-bar-message {
        min-height: 60px;
        color: white;
        display: flex;
        align-items: center;
      }

      .notification-bar-message > span {
        padding-left: 25px;
      }

      .notification-bar-message > span.ri-close-fill {
        padding-right: 20px;
      }

      .notification-bar-message > .notification-icon {
        font-size: 24px;
      }

      .action-button {
        margin: 0 25px;
      }
    `,
  ],
})
export class SnackbarNotificationComponent extends SimpleSnackBar {

  constructor(public snackBarRef: MatSnackBarRef<SimpleSnackBar>,
              @Inject(MAT_SNACK_BAR_DATA) public data: any) {
    super(snackBarRef, data);
  }
}
