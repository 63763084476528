import {Component, OnDestroy, OnInit} from '@angular/core';
import {ReplaySubject, takeUntil, tap} from "rxjs";
import {ListDataService} from "@raven";

@Component({
  selector: 'rn-pager',
  templateUrl: './pager.component.html',
  styleUrls: ['./pager.component.scss'],
})
export class PagerComponent implements OnInit, OnDestroy {

  pageNums = [1, 2, 3, 4, 5];
  pageSizeOptions = [3, 5, 10, 12, 15, 20];

  defaultPageSize = 10;
  selectedPageSize = 10;

  private destroyed$ = new ReplaySubject<boolean>(1)

  constructor(public listDataService: ListDataService,) {
  }

  ngOnInit(): void {
    this.listDataService.data$.pipe(
      tap(() => {
        this.ngOnChanges(null);
      }),
      takeUntil(this.destroyed$)
    )
      .subscribe();
  }

  ngOnChanges(changes) {
    if (changes) {
      console.log(changes);
    }
    this.selectedPageSize = this.listDataService.pageState.limit || this.defaultPageSize;

    this.calculatePageOptionsToDisplay();
  }

  get page(): number {
    return this.listDataService.pageState.page + 1;
  }

  get maxPage(): number {
    return Math.ceil(this.listDataService.totalCount / this.listDataService.pageState.limit);
  }

  calculatePageOptionsToDisplay() {
    const currentPage = this.listDataService.pageState.page + 1;
    this.pageNums = [-2, -1, 0, 1, 2]
      .map(offset => {
        return this.validPageNumber(currentPage + offset) && (currentPage + offset);
      })
      .filter(pageNum => pageNum);
  }

  pageSizeUpdated(update: any) {
    this.listDataService.pageState.page = 0;
    this.listDataService.pageState.limit = this.selectedPageSize;
    this.listDataService.pageEvent(this.listDataService.pageState);
  }

  validPageNumber(page: number) {

    if (page < 1) return false;
    if (page > this.maxPage) return false;

    return true;
  }

  clampPage(page: number): number {
    const maxPage = this.maxPage;

    if (page < 1) return 1;
    if (page > maxPage) return maxPage;

    return page;
  }

  forward() {
    const newPage = this.clampPage(this.listDataService.pageState.page + 2);
    if (newPage === this.listDataService.pageState.page + 1) return;
    this.listDataService.pageState.page = newPage - 1;
    this.listDataService.pageEvent(this.listDataService.pageState);
  }

  backward() {
    const newPage = this.clampPage(this.listDataService.pageState.page);
    if (newPage === this.listDataService.pageState.page + 1) return;
    this.listDataService.pageState.page = newPage - 1;
    this.listDataService.pageEvent(this.listDataService.pageState);
  }

  goToPage(pageNum: number) {
    const newPage = this.clampPage(pageNum);
    if (newPage === this.listDataService.pageState.page + 1) return;
    this.listDataService.pageState.page = newPage - 1;
    this.listDataService.pageEvent(this.listDataService.pageState);
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
