import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {PATRON_STATUS, PatronAccountStatus, PatronLedgerService, RoutesService} from '@raven';

@Component({
  selector: 'rn-status-warning-banner',
  template: `
      <ng-container *ngIf="(accountStatus$ | async) as status">
          <div class="warn-banner-body" *ngIf="!canPlaceHold(status) || accountExpired(status)">
              <span class="warn-banner-title">Account Suspended</span>
              <ul>
                  <li *ngIf="!canPlaceHold(status)">
                    <span>There are charges on your account that exceed
                        {{ status.maximumAccountBalance | currency }}. In order to
                      place a hold, check out items, or renew items, you must make a
                      payment. Please view your
                      <a class="pointer" (click)="routingService.goToPatronBilling()">balance</a>
                      for details.</span>
                  </li>
                  <li *ngIf="accountExpired(status)">
                    <span>Your account has expired and could not be automatically renewed. Please view your
                      <a class="pointer" (click)="routingService.goToProfile()">profile</a> for details.
                    </span>
                  </li>
              </ul>
          </div>
      </ng-container>
  `,
  styles: [
    `
        ul {
            margin: 0;
            padding-inline-start: 10px;

        li {
            margin: 5px 0;
        }

        }

        .warn-banner-body {
            background-color: var(--error-light);
            border: solid 1px var(--error);
            padding: 16px;
            margin-top: 16px;
        }

        .warn-banner-title {
            font-family: var(--Maax-Medium);
        }
    `,
  ],
})
export class StatusWarningBannerComponent implements OnInit {
  constructor(
    private patronLedgerService: PatronLedgerService,
    public routingService: RoutesService
  ) {
  }

  accountStatus$: Observable<PatronAccountStatus>;
  canPlaceHold$: Observable<boolean>;
  accountExpired$: Observable<boolean>;

  ngOnInit() {
    this.accountStatus$ = this.patronLedgerService.getAccountStatus();
  }

  canPlaceHold(act: PatronAccountStatus): boolean {
    return PatronLedgerService.canPlaceHold(act);
  }

  accountExpired(act: PatronAccountStatus): boolean {
    return act.accountStatus === PATRON_STATUS.EXPIRED;
  }
}
