import {CirculationItem} from './circulation-item';

export enum CLAIM_STATUS {
  RETURNED = 'RETURNED',
  LOST = 'LOST',
  LOST_PAID = 'LOST_PAID',
  FOUND = 'FOUND'
}

export class CirculationItemClaimedReturned {
  id?: number;
  checkoutId: number;
  claimType: CLAIM_STATUS;
  comment?: string;
  patronId?: number;
  foundByUserId?: number;
  foundDate?: string;
  circulationItem?: CirculationItem;
}
