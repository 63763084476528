<div class="tab-spacer hide-xs"></div>
<mat-tab-group
  class="profile-tabs"
  [(selectedIndex)]="tabIndex"
  (selectedIndexChange)="onTabChange($event)"
  dynamicHeight
  animationDuration="0"
>
  <mat-tab>
    <ng-template mat-tab-label>
      <h3 class="hide-xs">Account</h3>
      <mat-icon class="hide-gt-xs">person</mat-icon>
    </ng-template>
        <ng-template matTabContent>
            <rn-account></rn-account>
        </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <h3 class="hide-xs">Communication Preferences</h3>
        <mat-icon class="hide-gt-xs">mail</mat-icon>
      </ng-template>
        <ng-template matTabContent>
            <rn-communication-preferences></rn-communication-preferences>
        </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <h3 class="hide-xs">Balance & Payments</h3>
        <mat-icon class="hide-gt-xs">account_balance</mat-icon>
      </ng-template>
        <ng-template matTabContent>
            <rn-balance-and-payments></rn-balance-and-payments>
        </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <h3 class="hide-xs">Payment Information</h3>
        <mat-icon class="hide-gt-xs">credit_card</mat-icon>
      </ng-template>
        <ng-template matTabContent>
            <rn-payment-information></rn-payment-information>
        </ng-template>
    </mat-tab>
</mat-tab-group>
