export class MaterialType {
  code?: string;
  name?: string;
  description?: string;
}

export enum MaterialTypeCode {
  ARTICLE = 'acp',
  BOOK = 'bks',
  BLU_RAY = 'bta',
  CD_AUDIO = 'cda',
  CD_FOR_COMPUTER = 'cdc',
  DVD_AUDIO = 'dva',
  DVD_VIDEO = 'dvv',
  NEWSPAPER = 'new',
  PERIODICAL = 'per',
  SERIAL = 'ser',
}
