import {AfterContentInit, Component, ContentChild, DoCheck, ElementRef, HostBinding, Input} from '@angular/core';

@Component({
  selector: 'rn-show-more-section',
  templateUrl: './show-more-section.component.html',
  styleUrls: ['./show-more-section.component.scss'],
})
export class ShowMoreSectionComponent implements AfterContentInit, DoCheck {

  expanded = false;
  contentTooLong = false;

  @HostBinding('style.--max-height') maxHeightCss: string;
  @Input() maxHeight: number; //max height in px
  @ContentChild('showMoreContent') content: ElementRef;

  constructor() {
  }

  ngAfterContentInit(): void {
    this.maxHeightCss = String(this.maxHeight) + 'px';

    if (this.content.nativeElement.offsetHeight > this.maxHeight) {
      this.contentTooLong = true;
    }
  }

  ngDoCheck() {
    if (!this.content) {
      return;
    }
    if (!this.contentTooLong && this.content.nativeElement.offsetHeight > this.maxHeight) {
      this.contentTooLong = true;
    } else if (this.contentTooLong && this.content.nativeElement.offsetHeight < this.maxHeight) {
      this.contentTooLong = false;
    }
  }

}
