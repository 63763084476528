import {Component, OnInit} from '@angular/core';
import {Location as BrowserLocation, Location} from '@angular/common';
import {FormBuilder, FormGroup, Validators,} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ReplaySubject, takeUntil} from 'rxjs';
import {
  AuthService,
  Environment,
  GlobalState,
  NotificationService,
  Patron,
  PATRON_NOTIFICATION_PREFERENCE_TYPE,
  PatronNotificationPreference,
  PatronService,
  RoutesService,
  selectPatron
} from '@raven';
import {Store} from '@ngrx/store';

@Component({
  selector: 'rn-communication-preferences',
  templateUrl: './communication-preferences.component.html',
  styleUrls: ['./communication-preferences.component.scss'],
})
export class CommunicationPreferencesComponent implements OnInit {
  patron: Patron;
  notificationPreferencesForm: FormGroup;
  isLoading: boolean;

  Languages: { label: string, value: string }[] = [
    {label: 'English', value: 'EN'},
    // {label: 'Chinese', value: 'ZH'},
    // {label: 'French', value: 'FR'},
    // {label: 'German', value: 'DE'},
    // {label: 'Japanese', value: 'JA'},
    // {label: 'Spanish', value: 'ES'},
    // {label: 'Polish', value: 'PL'},
    // {label: 'Italian', value: 'IT'},
    // {label: 'Vietnamese', value: 'VI'},
  ];

  destroyed$ = new ReplaySubject<boolean>(1);

  constructor(
    private authService: AuthService,
    private environment: Environment,
    private route: ActivatedRoute,
    private router: Router,
    public routingService: RoutesService,
    private location: Location,
    private browserLocation: BrowserLocation,
    private fb: FormBuilder,
    private patronService: PatronService,
    private notificationService: NotificationService,
    private store: Store<GlobalState>,
  ) {
    store.select(selectPatron)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(patron => {
        this.patron = patron;
      });
  }

  ngOnInit(): void {
    // set loading
    this.isLoading = true;
    this.patronService
      .getCommunicationPreferences()
      .subscribe((preferences) => {
        this.notificationPreferencesForm.controls['accountByEmail'].setValue(
          this.getNotification(preferences.notifications, PATRON_NOTIFICATION_PREFERENCE_TYPE.ACCOUNT).notifyEmail, {emitEvent: true});
        this.notificationPreferencesForm.controls['accountBySMS'].setValue(
          this.getNotification(preferences.notifications, PATRON_NOTIFICATION_PREFERENCE_TYPE.ACCOUNT).notifyText, {emitEvent: true});
        this.notificationPreferencesForm.controls['receiptByEmail'].setValue(
          this.getNotification(preferences.notifications, PATRON_NOTIFICATION_PREFERENCE_TYPE.RECEIPT).notifyEmail, {emitEvent: true});
        this.notificationPreferencesForm.controls['receiptBySMS'].setValue(
          this.getNotification(preferences.notifications, PATRON_NOTIFICATION_PREFERENCE_TYPE.RECEIPT).notifyText, {emitEvent: true});
        this.notificationPreferencesForm.controls['cardExpireByEmail'].setValue(
          this.getNotification(preferences.notifications, PATRON_NOTIFICATION_PREFERENCE_TYPE.CARD_EXPIRE).notifyEmail, {emitEvent: true});
        this.notificationPreferencesForm.controls['cardExpireBySMS'].setValue(
          this.getNotification(preferences.notifications, PATRON_NOTIFICATION_PREFERENCE_TYPE.CARD_EXPIRE).notifyText, {emitEvent: true});
        this.notificationPreferencesForm.controls['autoRenewByEmail'].setValue(
          this.getNotification(preferences.notifications, PATRON_NOTIFICATION_PREFERENCE_TYPE.AUTO_RENEW).notifyEmail, {emitEvent: true});
        this.notificationPreferencesForm.controls['autoRenewBySMS'].setValue(
          this.getNotification(preferences.notifications, PATRON_NOTIFICATION_PREFERENCE_TYPE.AUTO_RENEW).notifyText, {emitEvent: true});
        this.notificationPreferencesForm.controls['unsubscribe'].setValue(preferences.unsubscribeAll, {emitEvent: true});
        this.isLoading = false;
      });
    this.createForm();
    this.notificationPreferencesForm
      .get('unsubscribe')
      .valueChanges.subscribe((unsubscribed) => {
      let isDisabled = unsubscribed;
      this.setDisabled(this.notificationPreferencesForm.get('accountByEmail'), isDisabled);
      this.setDisabled(this.notificationPreferencesForm.get('receiptByEmail'), isDisabled);
      this.setDisabled(this.notificationPreferencesForm.get('cardExpireByEmail'), isDisabled);
      this.setDisabled(this.notificationPreferencesForm.get('autoRenewByEmail'), isDisabled);
      if (!this.patron?.phone1) {
        isDisabled = true;
      }
      this.setDisabled(this.notificationPreferencesForm.get('accountBySMS'), isDisabled);
      this.setDisabled(this.notificationPreferencesForm.get('receiptBySMS'), isDisabled);
      this.setDisabled(this.notificationPreferencesForm.get('cardExpireBySMS'), isDisabled);
      this.setDisabled(this.notificationPreferencesForm.get('autoRenewBySMS'), isDisabled);
    });
  }

  setDisabled(ctrl, disabled) {
    if (disabled) {
      ctrl.disable();
    } else {
      ctrl.enable();
    }
  }

  getNotification(notifications: PatronNotificationPreference[], type: PATRON_NOTIFICATION_PREFERENCE_TYPE): PatronNotificationPreference {
    if (notifications) {
      for (const notification of notifications) {
        if (notification.notificationType == type) {
          return notification;
        }
      }
    }
    // default value if no preferences exist
    return new PatronNotificationPreference();
  }

  toggleNotification(field: string): void {
    const cntl = this.notificationPreferencesForm.get(field);
    cntl.setValue(!this.notificationPreferencesForm.value[field]);
    cntl.markAsDirty();
  }

  createForm(): void {
    this.notificationPreferencesForm = this.fb.group({
      accountByEmail: [true, {validators: [Validators.required]}],
      accountBySMS: [true, {validators: [Validators.required]}],
      receiptByEmail: [true, {validators: [Validators.required]}],
      receiptBySMS: [true, {validators: [Validators.required]}],
      cardExpireByEmail: [true, {validators: [Validators.required]}],
      cardExpireBySMS: [true, {validators: [Validators.required]}],
      autoRenewByEmail: [true, {validators: [Validators.required]}],
      autoRenewBySMS: [true, {validators: [Validators.required]}],
      receiptLanguage: ['EN', {validators: [Validators.required]}],
      unsubscribe: [false, {validators: [Validators.required]}],
    });
  }

  cancel(): void {
    // head back to whence we came
    this.browserLocation.back();
  }

  submit(): void {
    const formValues = this.notificationPreferencesForm.getRawValue();
    const notifications = [
      {
        patronId: this.patron.id,
        notificationType: PATRON_NOTIFICATION_PREFERENCE_TYPE.ACCOUNT,
        sendNotification: formValues.accountByEmail || formValues.accountBySMS,
        notifyEmail: formValues.accountByEmail,
        notifyText: formValues.accountBySMS,
        notifyPhone: false,
      },
      {
        patronId: this.patron.id,
        notificationType: PATRON_NOTIFICATION_PREFERENCE_TYPE.RECEIPT,
        sendNotification: formValues.receiptByEmail || formValues.receiptBySMS,
        notifyEmail: formValues.receiptByEmail,
        notifyText: formValues.receiptBySMS,
        notifyPhone: false,
      },
      {
        patronId: this.patron.id,
        notificationType: PATRON_NOTIFICATION_PREFERENCE_TYPE.AUTO_RENEW,
        sendNotification: formValues.autoRenewByEmail || formValues.autoRenewBySMS,
        notifyEmail: formValues.autoRenewByEmail,
        notifyText: formValues.autoRenewBySMS,
        notifyPhone: false,
      },
      {
        patronId: this.patron.id,
        notificationType: PATRON_NOTIFICATION_PREFERENCE_TYPE.CARD_EXPIRE,
        sendNotification: formValues.cardExpireByEmail || formValues.cardExpireBySMS,
        notifyEmail: formValues.cardExpireByEmail,
        notifyText: formValues.cardExpireBySMS,
        notifyPhone: false,
      },
    ];
    const preferences = {
      notifications: notifications,
      phoneNumber: this.patron.phone1,
      preferredLanguage: formValues.receiptLanguage,
      unsubscribeAll: formValues.unsubscribe,
    };
    this.patronService
      .updateCommunicationPreferences(preferences, {
        successMessage: 'Preferences updated Successfully.',
        errorMessage: 'Error updating your communication preferences. Please contact customer support.',
        onSuccess: () => {
          this.notificationPreferencesForm.markAsPristine();
          this.notificationPreferencesForm.markAsUntouched();
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
