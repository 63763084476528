import {Component, ElementRef, Input, ViewChild} from '@angular/core';

@Component({
  selector: 'rn-background-accent',
  templateUrl: './background-accent.component.html',
  styleUrls: ['./background-accent.component.scss'],
})
export class BackgroundAccentComponent {
  @Input() setHeightFromSelector: string;
  setHeightFrom: any;
  @ViewChild('accent')
  accent: ElementRef;

  settingHeightInProgress = false;
  queryingForElementInProgress = false;

  delayToReduceLoad = 10;

  ngDoCheck(): void {
    if (
      !this.queryingForElementInProgress &&
      (!this.setHeightFrom ||
        !this.setHeightFrom?.getBoundingClientRect().bottom)
    ) {
      this.queryingForElementInProgress = true;
      setTimeout(() => {
        this.setHeightFrom = document.querySelector(this.setHeightFromSelector);
        this.queryingForElementInProgress = false;
      }, this.delayToReduceLoad);
    }
    if (!this.accent || !this.setHeightFrom) {
      return;
    }

    const target = this.accent?.nativeElement.style.height;
    const sourceNumber = Math.round(
      this.setHeightFrom.getBoundingClientRect().bottom -
      this.accent?.nativeElement.getBoundingClientRect().top
    );
    const source = sourceNumber + 'px';

    if (target != source && !this.settingHeightInProgress) {
      this.settingHeightInProgress = true;
      setTimeout(() => {
        const sourceNumber = Math.round(
          this.setHeightFrom.getBoundingClientRect().bottom -
          this.accent?.nativeElement.getBoundingClientRect().top
        );
        const source = sourceNumber + 'px';
        this.accent.nativeElement.style.height = source;
        this.settingHeightInProgress = false;
      }, 0);
    }
  }
}
