<ng-container *ngIf="proReviews$ | async as proReviews else nothingToShow">
  <div *ngIf="proReviews.length > 0 else noProReviews" class="pro-review-header">
    <h3>Professional Reviews ({{proReviews.length}})</h3>
  </div>
  <ng-container *ngFor="let review of proReviews">
    <ng-container *ngTemplateOutlet="reviewEntry context: {review: review}"></ng-container>
  </ng-container>
</ng-container>

<ng-template #reviewEntry let-r="review">
  <div class="review-section">
    <div class="flex-row flex-center-space-between">
      <h3>{{r.review_source}}</h3>
      <span>{{r.review_date ? r.review_date : 'Date Unknown'}}</span>
    </div>
    <h4 *ngIf="r.review_title">{{r.review_title}}</h4>
    <div class="pro-review-text">
      <span>{{r.review_text}} </span>
      <a href="{{r.review_url}}" target="_blank" rel="noopener">See More</a>
    </div>
  </div>
</ng-template>

<ng-template #nothingToShow>
  <ng-container *ngIf="loading$ | async else noProReviews">
    <div class="no-reviews center-center">
      <mat-spinner></mat-spinner>
    </div>
  </ng-container>
</ng-template>

<ng-template #noProReviews>
  <div class="no-reviews flex-row flex-center-center">
    <span>No professional reviews available for this item</span>
  </div>
</ng-template>
