import {NgModule} from '@angular/core';
import {Error404Component} from './404.component';
import {Error500Component} from './500.component';
import {Error403Component} from './403.component';
import {Error401Component} from './401.component';
import {SharedModule} from "../shared/shared.module";

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [
    Error401Component,
    Error403Component,
    Error404Component,
    Error500Component,
  ],
})
export class ErrorModule {
}
