import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {NotificationService} from './notification.service';
import {Environment} from '../model/environment';
import {HttpClient} from '@angular/common/http';
import {ResponseBase} from '../model/response-base/response-base';
import {PatronItemRequestComment} from '../model/item-request/patron-item-request-comment';
import {Patron} from '../model/patron/patron';

@Injectable({
  providedIn: 'root',
})
export class CommentService {

  constructor(private environment: Environment,
              private notificationService: NotificationService,
              private http: HttpClient) {
  }

  listCommentsForPatron(organizationId: number, patronId: number): Observable<ResponseBase<Comment>> {
    return this.http.get<ResponseBase<Comment>>(
      `${this.environment.apiUrl}/organizations/${organizationId}/patrons/${patronId}/comments`
    );
  }

  //comments also returned on PatronItemRequest objects
  listCommentsByPatronItemRequest(organizationId: number, patronItemRequestId: number): Observable<ResponseBase<PatronItemRequestComment>> {
    return this.http.get<ResponseBase<PatronItemRequestComment>>(
      `${this.environment.apiUrl}/organizations/${organizationId}/patron-item-request-comments/patron-item-requests/${patronItemRequestId}`
    );
  }

  //////// Save methods //////////

  createCommentOnPatron(organizationId: number, patron: Patron, comment: Comment): Observable<Comment> {
    const url = `${this.environment.apiUrl}/organizations/${organizationId}/patrons/${patron.id}/comments`;
    return this.http.post<Comment>(url, JSON.stringify(comment));
  }

  createCommentOnPatronItemRequest(organizationId: number, comment: PatronItemRequestComment): Observable<PatronItemRequestComment> {
    const url = `${this.environment.apiUrl}/organizations/${organizationId}/patron-item-request-comments`;
    return this.http.post<PatronItemRequestComment>(url, JSON.stringify(comment));
  }
}
