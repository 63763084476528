import {Component, HostListener, Input} from '@angular/core';

@Component({
  selector: 'rn-barcode-popover',
  template: `
    <em
      class="ri-barcode-box-line"
      #overlayOrigin
      (click)="toggleShow($event)"
    ></em>
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="overlayOrigin"
      [cdkConnectedOverlayOpen]="show"
    >
      <div class="informationalOverlay" (click)="$event.stopPropagation()">
        <ngx-barcode
          [bc-value]="barcode"
          [bc-display-value]="false"
        ></ngx-barcode>
        <div>Item Bar Code {{ barcode }}</div>
      </div>
    </ng-template>
  `,
  styles: [
    `
      .ri-barcode-box-line {
        cursor: pointer;
        color: var(--active);
      }

      .informationalOverlay {
        padding: 25px 0 5px;
        border-radius: 10px;
        background-color: #f5f5f5;
        box-shadow: 3px 3px 3px grey;
        text-align: center;

        position: relative;
        left: -50%;
        top: -140px;
      }
    `,
  ],
})
export class BarcodePopoverComponent {
  show = false;
  myId = Math.random() * 1000000000; // unique to this instance (probably)

  @Input() barcode: string;

  toggleShow($event): void {
    this.show = !this.show;
    // tag this event so when it bubbles up to the document we won't toggle our state again
    $event[this.myId] = true;
  }

  // we want to hide the panel again on any click outside the panel
  @HostListener('document:click', ['$event'])
  documentClick($event: MouseEvent) {
    // if this event already toggled our visibility, bail out
    if ($event[this.myId]) {
      return;
    }
    this.show = false;
  }
}
