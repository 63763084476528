<div *ngIf="catalogRecord" class="item flex-row flex-start-start" [ngClass]="{'top-border': topBorder }" style="gap: min(30px, 4vw)">
  <div class="list-image-wrapper">
    <rn-book-cover class="list-img mat-elevation-z6" [catalogRecord]="catalogRecord"></rn-book-cover>
  </div>
  <a class="flex-col-inline" routerLink="/catalog-item/{{ catalogRecord.id }}">
    <h3 class="item-title item-text">{{ catalogRecord.title }}</h3>
    <div class="item-text item-text-secondary">{{ catalogRecord.author }}</div>
    <div class="item-text item-text-secondary">
      {{ catalogRecord.marcDetails?.clean?.publicationDate | date:'yyyy'}}
      <rn-material-type-icon [materialType]="catalogRecord.materialType"></rn-material-type-icon>
      {{ catalogRecord.materialType?.description}}
    </div>
  </a>
</div>
