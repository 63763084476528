import {Organization} from '../organization';
import {Branch} from '../branch';
import {CatalogRecord} from '../catalog-record';
import {Patron} from '../patron/patron';

export class CirculationItem {
  id: number;
  organizationId: number;
  catalogRecordId: number;
  currentBranchId?: number;
  homeBranchId?: number;
  shelfId?: number;
  dueDate?: string;
  currentBranchLocationId?: number;
  barcode?: string;
  status?: CIRCULATION_ITEM_STATUS;
  statusModified?: string;
  classification?: CLASSIFICATION;
  condition?: CONDITION;
  loanable?: boolean;
  note?: string;

  checkoutSessionId?: number;

  organization?: Organization;
  homeBranch?: Branch;
  currentBranch?: Branch;
  catalogRecord?: CatalogRecord;
  patron?: Patron;

  lastLocalAccess?: string;
}

export enum CIRCULATION_ITEM_STATUS {
  CHECKED_OUT = 'CHECKED_OUT',
  IN_TRANSFER = 'IN_TRANSFER',
  IN_LIBRARY = 'IN_LIBRARY',
  LOST = 'LOST',
  NOT_RETURNED = 'NOT_RETURNED',
  LOST_PAID = 'LOST_PAID',
  MISSING = 'MISSING',
  FAILED_TO_FIND = 'FAILED_TO_FIND',
  REQUEST_REPLACE = 'REQUEST_REPLACE',
  WITHDRAWN = 'WITHDRAWN',
  MISSING_CLAIMED_RETURNED = 'MISSING_CLAIMED_RETURNED',
}

export enum CLASSIFICATION {
  DEWEY = 'DEWEY',
  LCC = 'LCC',
}

export enum CONDITION {
  NEW = 'NEW',
  WORN = 'WORN',
  DAMAGED = 'DAMAGED',
  DESTROYED = 'DESTROYED',
}
