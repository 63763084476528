import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Subject, takeUntil} from 'rxjs';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {ConnectionPositionPair} from '@angular/cdk/overlay';

@Component({
  selector: 'rn-activity-filter',
  template: `
    <ng-container *ngIf="isMobile; else filterForm">
      <div class="flex-col">
        <div class="flex-row flex-center-end" (click)="collapsed = !collapsed;" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
          <mat-icon color="primary">filter_list</mat-icon>
          <a>Filter</a>
        </div>
        <ng-template cdkConnectedOverlay
                     [cdkConnectedOverlayOrigin]="trigger"
                     [cdkConnectedOverlayOpen]="!collapsed"
                     [cdkConnectedOverlayPositions]="positions"
                     [cdkConnectedOverlayHasBackdrop]="true"
                     (backdropClick)="collapsed=true">
          <div class="filter-overlay-body">
            <ng-container *ngTemplateOutlet="filterForm"></ng-container>
          </div>
        </ng-template>
      </div>
    </ng-container>

    <ng-template #filterForm>
      <form [formGroup]="activityFilterForm" class="activityFilters flex-row-col-responsive">
        <mat-form-field appearance="outline" class="activity-field">
          <mat-select formControlName="lastXDays">
            <mat-option value="30">30 days</mat-option>
            <mat-option value="60">60 days</mat-option>
            <mat-option value="90">90 days</mat-option>
            <mat-option value="120">120 days</mat-option>
            <mat-option value="ALL">All Activity</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="range-field">
          <mat-date-range-input [rangePicker]="rangePicker" formControlname="customRange" [max]="today()">
            <input matStartDate formControlName="start" placeholder="Start date"/>
            <input matEndDate formControlName="end" placeholder="End date"/>
          </mat-date-range-input>
          <div matSuffix class="mat-suffix-div">
            <button mat-icon-button *ngIf="activityFilterForm.controls['start'].value || activityFilterForm.controls['start'].value"
                    (click)="clearDateRange()">
              <mat-icon class="mat-datepicker-toggle">close</mat-icon>
            </button>
            <mat-datepicker-toggle [for]="rangePicker"></mat-datepicker-toggle>
              </div>
              <mat-date-range-picker #rangePicker></mat-date-range-picker>
              <mat-error
                *ngIf="activityFilterForm.controls['start'].hasError('matDatepickerMax') || activityFilterForm.controls['end'].hasError('matDatepickerMax')">
                Date cannot be in the future.
              </mat-error>
              <mat-error *ngIf="activityFilterForm.controls['start'].hasError('matDatepickerParse')">
                Invalid start date.
              </mat-error>
          <mat-error *ngIf="activityFilterForm.controls['end'].hasError('matDatepickerParse')">
            Invalid end date.
          </mat-error>
          <mat-error
            *ngIf="activityFilterForm.controls['start'].hasError('matStartDateInvalid') || activityFilterForm.controls['end'].hasError('matEndDateInvalid')">
            Start date cannot be greater than End date.
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="transaction-type-field" *ngIf="showTransactionType">
          <mat-select formControlName="transactionType">
            <mat-option value="ALL">All Transaction Types</mat-option>
            <mat-option value="CHARGE">Charges</mat-option>
            <mat-option value="PAYMENT">Payments</mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </ng-template>
  `,
  styles: [
    `
        :host {
            display: inline-block;
        }

        .activityFilters {
          margin-left:auto;
        }

        .activityFilters mat-form-field:last-child {
            margin-right: 0;
        }

        .mat-suffix-div {
            display: flex;
            align-items: center;
        }

        .filter-overlay-body {
            background-color: white;
            padding: 8px 8px 0px 16px;
            border: 1px solid var(--dk-gray);
            border-radius: 8px;
        }

        @media screen and (max-width: 599px) {
          .activity-field {
            max-width: 250px;
          }
          .range-field {
            max-width: 250px;
          }
          .transaction-type-field {
            max-width: 250px;
          }
        }

        @media screen and (min-width: 600px) {
          .activity-field {
            max-width: 130px;
          }
          .range-field {
            max-width: 240px;
          }
          .transaction-type-field {
            max-width: 180px;
          }
        }
    `,
  ],
})
export class ActivityFilterComponent implements OnInit, OnDestroy {
  @Input() changes: Subject<any>;
  @Input() showTransactionType = true;
  public activityFilterForm: FormGroup;
  isMobile = false;
  collapsed = false;
  positions = [
    new ConnectionPositionPair(
      {originX: 'end', originY: 'bottom'},
      {overlayX: 'end', overlayY: 'top'}),
  ];


  initialValues = {
    lastXDays: 'ALL',
    start: '',
    end: '',
    transactionType: 'ALL',
  };

  destroy$ = new Subject<boolean>();

  constructor(private fb: FormBuilder,
              private breakpointObserver: BreakpointObserver) {
  }

  ngOnInit(): void {
    this.activityFilterForm = this.fb.group({
      lastXDays: ['lastXDays', {validators: []}],
      start: ['start', {validators: []}],
      end: ['end', {validators: []}],
      transactionType: ['transactionType', {validators: []}],
    });

    this.activityFilterForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(this.changes);

    this.activityFilterForm.setValue(this.initialValues);

    this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(takeUntil(this.destroy$))
      .subscribe((breakpointState: BreakpointState) => {
        this.isMobile = breakpointState.matches;
        this.collapsed = breakpointState.matches;
      });
  }

  today(): Date {
    return new Date();
  }

  clearDateRange() {
    this.activityFilterForm.controls['start'].setValue('');
    this.activityFilterForm.controls['end'].setValue('');
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
}
