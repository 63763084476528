import {Component, Input} from '@angular/core';
import {PatronAccountStatus} from '@raven';

@Component({
  selector: 'rn-billing-account-status-content',
  template: `
    <ng-template #accountStatusChip let-account="account">
      <mat-chip-list style="margin-left: auto; margin-top:-5px">
        <mat-chip *ngIf="account.accountStatus != 'ACTIVE' else active"
                  class="red-pill small-pill">{{ account.accountStatus | titlecase }}</mat-chip>
        <ng-template #active>
          <mat-chip class="green-pill small-pill">{{ account.accountStatus | titlecase }}</mat-chip>
        </ng-template>
      </mat-chip-list>
    </ng-template>

    <div class="flex-row">
      <h3>Account Status</h3>
      <ng-container *ngTemplateOutlet="accountStatusChip; context:{account:account}"></ng-container>
    </div>
    <div class="status-detail flex-row">
      <ng-container *ngIf="balanceOverMax(account); else balanceOk">
        <mat-icon class="red-close">close</mat-icon>
        <span>Balance greater than {{ account.maximumAccountBalance | currency }}</span>
      </ng-container>
      <ng-template #balanceOk>
        <mat-icon class="green-check">done</mat-icon>
        <span>Balance less than {{ account.maximumAccountBalance | currency }}</span>
      </ng-template>
    </div>
    <div class="status-detail flex-row">
      <mat-icon class="green-check">done</mat-icon>
      <span>No lost or missing items</span>
    </div>
    <div class="status-detail flex-row">
      <ng-container *ngIf="cardExpired(account); else cardActive">
        <mat-icon class="red-close">close</mat-icon>
        <span>Card expired {{ account.accountExpiration | date:'shortDate' }}</span>
      </ng-container>
      <ng-template #cardActive>
        <mat-icon class="green-check">done</mat-icon>
        <span>Card valid through {{ account.accountExpiration | date:'shortDate' }}</span>
      </ng-template>
    </div>
  `,
  styles: [`
    .red-close {
      color: var(--error);
      margin: -4px 5px 0 0;
    }

    .green-check {
      color: var(--success);
      margin: -4px 5px 0 0;
    }

    .status-detail {
      margin-top: 8px;
    }

    h3 {
        font-family: var(--Maax);
    }
  `],
})
export class AccountStatusContentComponent {

  @Input() account: PatronAccountStatus;

  balanceOverMax(account: PatronAccountStatus): boolean {
    return account.currentAccountBalance > account.maximumAccountBalance;
  }

  cardExpired(account: PatronAccountStatus): boolean {
    return new Date(account.accountExpiration).getTime() < Date.now();
  }

}
