import {NgModule} from '@angular/core';
import {PatronBillingModule} from '../patron-billing/patron-billing.module';
import {ProfileRoutingModule} from './profile-routing.module';
import {AccountComponent} from './account.component';
import {MyCardComponent} from './my-card.component';
import {ProfileComponent} from './profile.component';
import {CommunicationPreferencesComponent} from './communication-preferences.component';
import {PasswordUpdateDialog} from './dialogs/password-update-dialog';
import {PinUpdateDialog} from './dialogs/pin-update-dialog';
import {DeleteAccountDialogComponent} from "./dialogs/delete-account.dialog";
import {SaveHistoryDialogComponent} from "./dialogs/save-history.dialog";
import {AddressChangeDialog} from "./dialogs/address-change.dialog";
import {SharedModule} from "../shared/shared.module";
import {EmailUpdateDialog} from "./dialogs/email-update-dialog";

@NgModule({
  imports: [
    SharedModule,
    PatronBillingModule,
    ProfileRoutingModule,
  ],
  exports: [ProfileComponent],
  declarations: [
    ProfileComponent,
    AccountComponent,
    MyCardComponent,
    CommunicationPreferencesComponent,
    EmailUpdateDialog,
    PasswordUpdateDialog,
    PinUpdateDialog,
    AddressChangeDialog,
    DeleteAccountDialogComponent,
    SaveHistoryDialogComponent
  ],
})
export class ProfileModule {
}
