import {Component, Input} from '@angular/core';
import {RoutesService} from '@raven';

@Component({
  selector: 'rn-author-carousel',
  template: `
    <carousel [title]="title">
      <ng-container *ngFor="let author of authors; let last = last">
        <div style="position: relative" *carouselItem>
          <div class="carousel-item" [ngClass]="{ 'last' : last }">
            <h3 style="text-align:center; padding: 0 20px">{{ author }}</h3>
            <a class="arrow-link"><img alt="arrow-right" src="/assets/ui-elements-arrow-right.svg" class="arrow"/></a>
          </div>
        </div>
      </ng-container>
    </carousel>
  `,
  styleUrls: ['./author-carousel.component.scss'],
})
export class AuthorCarouselComponent {
  @Input() title = '';
  @Input() authors: string[];

  constructor(public routesService: RoutesService) {
  }
}
