<div class="dialog-wrapper flex-col">
  <div class="dialog-close-row flex-row flex-center-end">
    <mat-icon class="pointer" (click)="cancel()">close</mat-icon>
  </div>

  <div class="dialog-content flex-col flex-gap-20">
    <h3 class="dialog-title">Make a Payment</h3>
    <span class="dialog-subhead">Authorize your payment below</span>
    <div class="flex-col flex-gap-16">
      <div class="payment-details-row flex-row flex-center-space-between">
        <span class="payment-details-left">Payment Source</span>
        <span class="payment-details-right">{{data.sourceDisplayString}}</span>
      </div>
      <div class="payment-details-row flex-row flex-center-space-between">
        <span class="payment-details-left">Payment Amount</span>
        <span class="payment-details-right">{{data.paymentAmount | currency}}</span>
      </div>
      <div class="payment-details-row flex-row flex-center-space-between">
        <span class="payment-details-left">Payment Date</span>
        <span class="payment-details-right">{{data.paymentDate | date}}</span>
      </div>
      <div class="payment-details-row flex-row flex-center-space-between">
        <span class="payment-details-left">Payment To</span>
        <span class="payment-details-right">{{data.organizationName}}</span>
      </div>
    </div>
    <span class="payment-details-left">I authorize {{data.organizationName}} to initiate and to honor an electronic payment in the amount specified above from the chosen payment
      source.
      Please verify your information carefully.</span>
    <div class="button-row flex-row flex-center-end flex-gap-16">
      <button (click)="cancel()" mat-raised-button class="button-cancel">Cancel</button>
      <button *ngIf="data.paymentType != PATRON_PAYMENT_METHOD.APPLE" (click)="authorize()" mat-raised-button color=primary class="save-button">Authorize
        Payment
      </button>
      <div *ngIf="data.paymentType == PATRON_PAYMENT_METHOD.APPLE" (click)="authorizeApplePay()" id="apple-pay-button"></div>
    </div>
  </div>
</div>
