import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {Component, Input, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {combineLatest, filter, Observable, ReplaySubject, Subject, switchMap, takeUntil} from 'rxjs';
import {map, startWith, tap} from 'rxjs/operators';
import {GoogleCatalogRecord, ItemRequestService} from '@raven';

@Component({
  selector: 'rn-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.scss'],
})
export class ItemDetailsComponent implements OnInit, OnDestroy {
  @Input() item: object;
  googleCatalogRecord$: Observable<GoogleCatalogRecord>;

  data$: Observable<object>;
  mobile: boolean;
  destroy$ = new Subject();
  item$$ = new ReplaySubject<object>(1);

  constructor(
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private itemRequestService: ItemRequestService
  ) {
  }

  ngOnInit(): void {
    const item$ = this.item$$.asObservable()

    const isbn$ = this.route.paramMap
      .pipe(takeUntil(this.destroy$))
      .pipe(map(paramMap => paramMap.get('isbn')))
      .pipe(filter(isbn => !!isbn))
      .pipe(tap(x => console.log('isbn: ', x)));

    this.googleCatalogRecord$ = isbn$
      .pipe(switchMap(isbn => {
        return this.itemRequestService
          .searchGoogleAPIByISBN(isbn)
          .pipe(map((googleResponse) => {
            return Object.assign(new GoogleCatalogRecord(), googleResponse['items'][0]['volumeInfo']);
          }));
      }))
      .pipe(startWith(null));

    this.data$ = combineLatest([this.googleCatalogRecord$, item$])
      .pipe(map(([googleCatalogRecord, item]) => {
        return {item: googleCatalogRecord ? this.itemFromGoogleCatalogRecord(googleCatalogRecord) : item};
      }));

    this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: BreakpointState) => {
        this.mobile = state.matches;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.item$$.next(changes.item.currentValue);
  }

  itemFromGoogleCatalogRecord(googleRecord: GoogleCatalogRecord): object {
    return {
      title: googleRecord.title,
      author: googleRecord.authors,
      publicationDate: googleRecord.publishedDate,
      format: 'BOOK',
      isbn: googleRecord['industryIdentifiers'][0]?.identifier,
      coverImageUrl:
        googleRecord.imageLinks.thumbnail ||
        googleRecord.imageLinks.smallThumbnail ||
        '',
      description: googleRecord.description,
    };
  }

}
