import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {PhoneValidators} from 'ngx-phone-validators';
import {EMPTY, filter, Subject, switchMap, takeUntil} from 'rxjs';
import {SubSink} from 'subsink';
import {
  AuthService,
  BranchService,
  NotificationService,
  Patron,
  PatronService,
  RoutesService,
  STATES,
  ThemeService
} from '@raven';
import {AddressChangeDialog} from './dialogs/address-change.dialog';
import {PasswordUpdateDialog} from './dialogs/password-update-dialog';
import {PinUpdateDialog} from './dialogs/pin-update-dialog';
import {SaveHistoryDialogComponent} from './dialogs/save-history.dialog';
import {EmailUpdateDialog} from "./dialogs/email-update-dialog";

@Component({
  selector: 'rn-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit, OnDestroy {
  STATES = STATES;

  patron: Patron;
  personalInfoForm: FormGroup;
  isLoading: boolean;
  branchSelect = [];
  subs = new SubSink();

  destroyed$ = new Subject<boolean>();

  constructor(
    private branchService: BranchService,
    private patronService: PatronService,
    private routesService: RoutesService,
    public themeService: ThemeService,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    // set loading
    this.isLoading = true;
    this.branchService
      .getAllBranches()
      .subscribe((branches) => {
        this.branchSelect = [];
        for (const branch of branches) {
          this.branchSelect.push({label: branch.name, value: branch.id});
        }
      });
    this.createRegistrationForm();
  }

  createRegistrationForm(): void {
    // clone the patron as the auth patron is readonly
    this.patron = JSON.parse(JSON.stringify(this.patronService.patron));
    if (!this.patron.addresses || this.patron.addresses.length == 0) {
      this.patron.addresses = [
        {
          address: '',
          address2: '',
          city: '',
          state: '',
          zipCode: '',
        },
      ];
    }

    this.personalInfoForm = this.fb.group({
      firstName: [this.patron.firstName, {validators: [Validators.required, Validators.maxLength(50)]}],
      lastName: [this.patron.lastName, {validators: [Validators.required, Validators.maxLength(50)]}],
      email: [{value: this.patron.email, disabled: true}],
      password: [{value: '****************', disabled: true}],
      pin: [{value: '****', disabled: true}],
      address: [this.patron.address, {validators: [Validators.required, Validators.minLength(3), Validators.maxLength(100)]},],
      address2: [this.patron.address2, Validators.maxLength(100)],
      city: [this.patron.city, {validators: [Validators.required, Validators.minLength(3), Validators.maxLength(20)]},],
      state: [this.patron.state, {validators: [Validators.required]},],
      zip: [this.patron.zipCode, {validators: [Validators.required, Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$')]},],
      phone: [this.patron.phone1, [PhoneValidators.isPossibleNumberWithReason('US')],],
      barcode: [{value: this.getRedactedCardNumber(), disabled: true}],
      defaultBranch: [this.patron.branchId, {validators: [Validators.required]},],
      optIn: [this.patron.optInReadingHistory],
      theme: [this.themeService.currentTheme]
    });

    this.patronService
      .patron$.pipe(takeUntil(this.destroyed$))
      .subscribe((patronResponse) => {
        this.personalInfoForm.controls['email'].setValue(patronResponse.email);

        if (patronResponse && patronResponse.address) {
          // emitEvent: true is the default...
          this.personalInfoForm.controls['address'].setValue(patronResponse.address);
          this.personalInfoForm.controls['address2'].setValue(patronResponse.address2);
          this.personalInfoForm.controls['city'].setValue(patronResponse.city);
          this.personalInfoForm.controls['state'].setValue(patronResponse.state);
          this.personalInfoForm.controls['zip'].setValue(patronResponse.zipCode);
        }
        this.isLoading = false;
      });
    this.personalInfoForm.get('phone').valueChanges.subscribe(phone => {
      // get only the numeric characters
      phone = phone.replace(/\D/g, '');
      // the formcontrol.valueChanges fires before the values on the form object update, allowing us to get the previous value
      const oldValue = this.personalInfoForm.value.phone.replace(/\D/g, '');
      if (phone.length != 10 && phone == oldValue) {
        // this allows the user to backspace without automatically re-adding characters
        return;
      }
      // format to (###) ###-####
      let newPhone = `(${phone.slice(0, 3)}`;
      if (phone.length > 2) {
        newPhone += `) ${phone.slice(3, 6)}`;
      }
      if (phone.length > 5) {
        newPhone += `-${phone.slice(6, 10)}`;
      }
      this.personalInfoForm.get("phone").setValue(newPhone, {emitEvent: false});
    });
  }

  getRedactedCardNumber(): string {
    return this.patron.barcode.replace(/.(?=.{4,}$)/g, '*');
  }

  toggleCheckbox(field: string): void {
    const cntl = this.personalInfoForm.get(field);
    cntl.setValue(!this.personalInfoForm.value[field]);
    cntl.markAsDirty();
    if (field == 'optIn' && cntl.value) {
      this.dialog.open(SaveHistoryDialogComponent);
    }
  }

  submit(): void {
    if (!this.isAddressChanged()) return this.save();

    const dialogRef = this.dialog.open(AddressChangeDialog, {maxWidth: '95vw',});
    this.subs.sink = dialogRef
      .afterClosed()
      .pipe(filter(changeConfirmation => changeConfirmation))
      .subscribe(() => this.save());
  }

  isAddressChanged(): boolean {
    const formModel = this.personalInfoForm.value;
    return this.patron.address != formModel.address ||
      this.patron.address2 != formModel.address2 ||
      this.patron.city != formModel.city ||
      this.patron.state != formModel.state ||
      this.patron.zipCode != formModel.zip;
  }

  save(): void {
    const formModel = this.personalInfoForm.value;
    this.patron.firstName = formModel.firstName;
    this.patron.lastName = formModel.lastName;
    this.patron.address = formModel.address;
    this.patron.address2 = formModel.address2;
    this.patron.city = formModel.city;
    this.patron.state = formModel.state;
    this.patron.zipCode = formModel.zip;
    this.patron.phone1 = formModel.phone;
    // this.patron.country = 'United States of America';
    this.patron.branchId = formModel.defaultBranch;
    this.patron.optInReadingHistory = formModel.optIn;

    this.patronService.save(this.patron, {
      errorMessage: 'Error updating your personal information. Please contact customer support.',
      successMessage: 'Personal information updated.',
      onSuccess: newPatron => {
        this.patron = newPatron;
        this.personalInfoForm.markAsPristine();
        this.personalInfoForm.markAsUntouched();
      }
    })
  }

  updateEmail(): void {
    this.dialog.open(EmailUpdateDialog);
  }

  updatePassword(): void {
    this.dialog.open(PasswordUpdateDialog);
  }

  updatePin(): void {
    this.dialog.open(PinUpdateDialog)
      .afterClosed()
      .subscribe((updatedConfirmation) => {
        if (updatedConfirmation) {
          this.patron.pinGenerated = false;
        }
      });
  }

  // deleteAccount(): void {
  //   const dialogRef = this.dialog.open(DeleteAccountDialogComponent);
  //   this.subs.sink = dialogRef
  //     .afterClosed()
  //     .pipe(
  //       switchMap((deleteConfirmation: boolean) => {
  //         if (deleteConfirmation) {
  //           return this.patronService.deleteAccount();
  //         }
  //         return EMPTY;
  //       })
  //     )
  //     .subscribe(() => {
  //       this.notificationService.showSnackbarSuccess('Account deleted successfully');
  //       this.authService.logout();
  //       this.routesService.goToIndex();
  //     });
  // }

  undo(): void {
    this.personalInfoForm.reset();
    this.personalInfoForm.setValue({
      firstName: this.patron.firstName,
      lastName: this.patron.lastName,
      email: this.patron.email,
      password: '****************',
      pin: '****',
      address: this.patron.address,
      address2: this.patron.address2,
      city: this.patron.city,
      state: this.patron.state,
      zip: this.patron.zipCode,
      phone: this.patron.phone1,
      barcode: this.getRedactedCardNumber(),
      defaultBranch: this.patron.branchId,
      optIn: this.patron.optInReadingHistory,
      theme: this.themeService.currentTheme
    });
  }

  random(length): string {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  getErrorMessage(fieldName: string, field: AbstractControl): string {
    if (!field) {
      return '';
    }
    // at exactly 7 digits we don't get 'phoneNumberTooShort' and always get 'noPhoneNumber' which is unhelpful
    // could not find any valid 7 digit phone numbers, it seems there's a way to include an area code separately.
    // for our case, we'll cover this specific case with the standard 'phoneNumberTooShort' message
    if (fieldName == 'phone number' && field.value?.replace(/\D/g, '')?.length == 7) {
      return `${this.capitalize(fieldName)} is too short, please use 10 digits (including area code).`;
    }
    if (field.hasError('required')) {
      return `Please enter your ${fieldName}.`;
    }
    if (field.hasError('maxlength')) {
      return `${this.capitalize(fieldName)} is too long, please use less than ${field.getError('maxlength').requiredLength} characters.`;
    }
    if (field.hasError('minlength')) {
      return `${this.capitalize(fieldName)} is too short, please use at least ${field.getError('minlength').requiredLength} characters.`;
    }
    if (field.hasError('phoneNumberTooLong')) {
      return `${this.capitalize(fieldName)} is too long, please use 10 digits (including area code).`;
    }
    if (field.hasError('phoneNumberTooShort')) {
      return `${this.capitalize(fieldName)} is too short, please use 10 digits (including area code).`;
    }
    if (fieldName == 'zip code') {
      return `${this.capitalize(fieldName)} is invalid, please enter 5 digit zip code (+4 digits optional).`;
    }
    // we don't want to get here, this doesn't tell the user how to fix the error
    return `Please enter a valid ${fieldName}.`;
  }

  capitalize(str: string): string {
    if (!str) {
      return '';
    }
    return str.slice(0, 1).toUpperCase() + str.slice(1);
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
