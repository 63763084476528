<!--<div class="page-size-picker flex-1">
  <span class="text-hint">Showing</span>
  <mat-select [(value)]="selectedPageSize" (selectionChange)="pageSizeUpdated($event)" style="margin: 0 15px;">
    <mat-option *ngFor="let pageSize of pageSizeOptions" [value]="pageSize">{{ pageSize }}</mat-option>
  </mat-select>
  <span class="text-hint">of {{ listDataService.totalCount }}</span>
</div>-->

<div class="list-pager center-center" *ngIf="maxPage > 1">
  <span class="left-arrow" (click)="backward()">
    <mat-icon>arrow_backward</mat-icon>
  </span>
  <span *ngFor="let i of pageNums" class="wrapper" [ngClass]="{ circle: page === i }"
        (click)="goToPage(i)">
    <span class="page-number">{{ i }}</span>
  </span>
  <span class="right-arrow" (click)="forward()">
      <mat-icon>arrow_forward</mat-icon>
    </span>
</div>







