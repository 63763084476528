import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, combineLatest, debounceTime, EMPTY, map, Observable, ReplaySubject, shareReplay, startWith, switchMap, takeUntil} from "rxjs";

@Injectable()
export class ListDataService implements OnDestroy {

  data$: Observable<Array<any>>;
  data: Array<any> = [];
  dataLength$ = new BehaviorSubject<number>(0);
  totalCount = 1;

  fetchData: ({filter, sort, page}) => Observable<any>;

  filterState: any;
  sortState: { sortBy, sortDirection };
  pageState: { page, limit } = {page: 0, limit: 10};

  filter = new BehaviorSubject<any>(null);
  sort = new BehaviorSubject<any>(null);
  page = new BehaviorSubject<any>(null);

  private destroyed$ = new ReplaySubject<boolean>(1)

  constructor() {
    this.data$ = combineLatest([
      this.filter,
      this.sort,
      this.page
    ])
      .pipe(debounceTime(50))
      .pipe(switchMap(([filter, sort, page]) => {

        if (!this.fetchData) {
          return EMPTY;
        }
        return this.fetchData({
          filter: filter,
          sort: sort,
          page: page
        })
          .pipe(map(data => {
            this.dataLength$.next(data.totalCount);
            this.totalCount = data.totalCount;
            if (this.pageState.limit !== data.limit
              || this.pageState.page < 0
              || this.pageState.page > data.totalCount / data.limit) {
              this.pageState.limit = data.limit;
              if (this.pageState.page < 0) {
                this.pageState.page = 0;
              } else if (this.pageState.page > data.totalCount / data.limit) {
                this.pageState.page = Math.floor(data.totalCount / data.limit);
              }

              this.pageEvent(this.pageState);
            }

            this.data = data.objects;
            return data.objects;
          }));
      }))
      .pipe(startWith([]))
      .pipe(shareReplay({bufferSize: 1, refCount: false}))
      .pipe(takeUntil(this.destroyed$));

    this.data$.subscribe();
  }

  setFetchData(fetchData: ({filter, sort, page}) => Observable<any>) {
    this.fetchData = fetchData;
  }

  filterEvent(filterState: any): void {
    this.filterState = filterState;
    this.filter.next(filterState);
  }

  sortEvent(sortState: { active, direction }): void {
    this.sortState = {
      sortBy: sortState?.direction ? sortState?.active : undefined,
      sortDirection: sortState?.direction ? sortState?.direction : undefined,
    };
    this.sort.next(this.sortState);
  }

  pageEvent(pageState: { page, limit }): void {
    this.pageState = pageState;
    this.page.next(pageState);
  }

  triggerRefresh(): void {
    this.pageState.page = 0;
    this.pageEvent(this.pageState);
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
