import {Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {MatDialog} from '@angular/material/dialog';
import {MatDrawer, MatDrawerMode} from '@angular/material/sidenav';
import {BehaviorSubject, combineLatest, distinctUntilChanged, Observable, sample, shareReplay, Subject, switchMap, takeUntil} from 'rxjs';
import {map} from 'rxjs/operators';
import {CatalogFacetsComponent} from './components/catalog-facets/catalog-facets.component';
import {CatalogBrowseService} from './catalog-browse.service';
import {SearchFacets} from './mock-facets';
import {
  CatalogFacets,
  CatalogQuery,
  CatalogRecord,
  CatalogRecordService,
  CatalogSearchResponse,
  Page,
  PatronLedgerService,
  Sort,
  SubmitRequestComponent
} from '@raven';

@Component({
  selector: 'rn-catalog-browse',
  templateUrl: './catalog-browse.component.html',
  styleUrls: ['./catalog-browse.component.scss'],
  providers: [CatalogBrowseService]
})
export class CatalogBrowseComponent implements OnInit, OnDestroy {
  @ViewChildren(CatalogFacetsComponent)
  facetComponents: QueryList<CatalogFacetsComponent>;
  loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();

  items: Array<CatalogRecord> = [];
  searchForm: FormGroup;
  searchType = 'keyword';
  defaultDisplayMode = 'grid'; //DisplayMode.Grid;
  displayMode: string = this.defaultDisplayMode; //DisplayMode;
  facets = new SearchFacets().facets;

  showExpandedSearch = false;
  showExpandedSearchWarning = true;
  patronCanRequest: boolean
  destroy$ = new Subject<boolean>();
  isMobile = false;
  filtersOpened = true;
  facetDrawerMode: MatDrawerMode = 'side';
  @ViewChild(MatDrawer) facetDrawer: MatDrawer;
  sortSelectValue = '';

  searchButtonSubject = new Subject<boolean>();
  searchResponse$: Observable<CatalogSearchResponse>;

  pageSubject = this.catalogBrowseService.pageSubject
  page$ = this.catalogBrowseService.page$;
  querySubject = this.catalogBrowseService.querySubject;
  query$ = this.catalogBrowseService.query$;
  // filterSubject = this.catalogBrowseService.filterSubject;
  filter$ = this.catalogBrowseService.filter$;
  sortSubject = this.catalogBrowseService.sortSubject;
  sort$ = this.catalogBrowseService.sort$;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private patronLedgerService: PatronLedgerService,
    private catalogRecordService: CatalogRecordService,
    public catalogBrowseService: CatalogBrowseService,
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.loadingSubject.next(true);
    this.searchForm = this.fb.group({
      searchType: ['keyword', {validators: []}],
      searchTerm: ['', {validators: []}],
    });

    this.searchForm.valueChanges
      .pipe(
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        shareReplay({bufferSize: 1, refCount: false}),
        sample(this.searchButtonSubject),
        map(formValue => {
          return {query: formValue['searchTerm'], queryType: formValue['searchType']} as CatalogQuery;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(query => {
        this.catalogBrowseService.updateQuery(query);
      });

    const pageQueryFilterSort$ = combineLatest([this.page$, this.query$, this.filter$, this.sort$]).pipe(
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    );

    this.searchResponse$ = pageQueryFilterSort$.pipe(
      switchMap(([page, query, filters, sort]) => {
        return this.catalogRecordService.elasticSearch(page, query, filters, sort);
      }),
    );

    pageQueryFilterSort$.pipe(
      map(([page, query, filters, sort]) => this.serializeToUrlParams(page, query, filters, sort)),
      takeUntil(this.destroy$)
    ).subscribe();

    this.route.queryParams.pipe(
      map(params => this.setupFromUrlParams(params)),
      takeUntil(this.destroy$)
    ).subscribe();

    this.patronLedgerService.getAccountStatus()
      .pipe(PatronLedgerService.canRequestItems$)
      .pipe(takeUntil(this.destroy$))
      .subscribe((canRequest) => this.patronCanRequest = canRequest);

    this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(takeUntil(this.destroy$))
      .subscribe((breakpointState: BreakpointState) => {
        if (breakpointState.matches) {
          this.isMobile = true;
          this.filtersOpened = false;
          this.facetDrawerMode = 'over';
        } else {
          this.isMobile = false;
          this.filtersOpened = true;
          this.facetDrawerMode = 'side';
        }
      });
  }

  setupFromUrlParams(params: Params): void {
    const urlPage = parseInt(params['page']);
    const page = params['page'] && !isNaN(urlPage) && urlPage >= 0 ? urlPage : 0;
    const urlLimit = parseInt(params['limit']);
    const limit = params['limit'] && !isNaN(urlLimit) && urlLimit > 0 ? urlLimit : 25;
    const pageState = {page: page, limit: limit} as Page;
    this.pageSubject.next(pageState);

    const query = params['query'];
    const type = params['searchType'];
    if (query) {
      this.searchForm.get('searchTerm').setValue(query);
    }
    if (type) {
      this.searchForm.get('searchType').setValue(type);
      this.searchType = type;
    }
    const queryState = {query: this.searchForm.get('searchTerm').value, queryType: this.searchForm.get('searchType').value} as CatalogQuery;
    this.querySubject.next(queryState);

    const filtersObject = params['filters'] ?? JSON.stringify({});
    let catalogFilters;
    try {
      catalogFilters = JSON.parse(filtersObject);
    } catch (e) {
      catalogFilters = {};
    }

    const filterLinkType = params['filterLinkType'];
    const filterLinkValue = params['filterLinkValue'];
    if (filterLinkType && filterLinkValue) {
      catalogFilters = CatalogFacets.fromLinkParams(filterLinkType, filterLinkValue);
    }

    this.catalogBrowseService.updateFilters(catalogFilters);

    const sortBy = params['sortBy'];
    const direction = params['direction'];
    if (sortBy && direction) {
      this.sortSelectValue = sortBy + '-' + direction;
    }
    this.sortSubject.next({column: sortBy, direction: direction});
  }

  submitForm(): void {
    this.searchButtonSubject.next(true);
  }

  setPageNumber(pageNumber: number): void {
    this.catalogBrowseService.setPage(pageNumber);
    window.scrollTo(0, 0);
  }

  getPageCount(totalResults: number, limit: number): number {
    return Math.ceil(totalResults / limit);
  }

  getPageNumbersForControl(currentPage: number, totalResults: number, limit: number): Array<number> {
    const pageCount = this.getPageCount(totalResults, limit);
    const firstPage = currentPage - 4 < 1 ? 1 : currentPage - 4;
    const lastPage = currentPage + 4 > pageCount ? pageCount : currentPage + 4;
    const pageNumbersForControl = [];
    for (let i = firstPage; i <= lastPage; i++) {
      pageNumbersForControl.push(i);
    }
    return pageNumbersForControl;
  }

  serializeToUrlParams(page: Page, query: CatalogQuery, filters: CatalogFacets, sort: Sort) {
    // used to limit filter properties to those needed by API / displayed in the URL
    const fieldsToKeep = ['subjects.keyword', 'genres.keyword', 'materialTypeIds', 'branchIds', 'author.keyword', 'publicationDate.keyword', 'name', 'values', 'filterValue'];

    const newParams = {
      query: query.query,
      searchType: query.queryType,
      page: page.page,
      limit: page.limit,
      filters: JSON.stringify(filters, fieldsToKeep),
      sortBy: sort?.column,
      direction: sort?.direction,
      filterLinkType: undefined,
      filterLinkValue: undefined,
    }
    this.router.navigate([this.route.snapshot.url[0].path], {
      queryParams: newParams,
      queryParamsHandling: 'merge',
    });
  }

  setDisplayMode(mode): void {
    this.displayMode = mode;
  }

  setPageSize(length): void {
    this.catalogBrowseService.setPageAndLimit(0, length);
  }

  openItemRequestModal(): void {
    this.dialog.open(SubmitRequestComponent);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  hasFilters(response: CatalogSearchResponse): boolean {
    if (!response) {
      return false;
    }
    for (const facetKey of Object.keys(response.facets)) {
      if (response.facets[facetKey].values && response.facets[facetKey].values.filter(facetValue => facetValue.selected).length > 0) {
        return true;
      }
    }
    return false;
  }

  sortChanged(event){
    const sortObject = this.sortObjectFromSelectValue(event.target.value);
    this.catalogBrowseService.updateSort(sortObject);
  }

  sortObjectFromSelectValue(sortValue: string): Sort {
    const splitSortValue = sortValue.split('-');
    if (splitSortValue.length != 2) {
      return null;
    }
    return {
      column: splitSortValue[0],
      direction: splitSortValue[1],
    } as Sort;
  }
}

enum DisplayMode {
  Grid = 'grid',
  List = 'list',
}
