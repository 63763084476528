<ng-container *ngIf="data$ | async as data">
  <ng-container *ngTemplateOutlet="itemDetails; context: { request: data.request, item: data.item }"></ng-container>
  <ng-container *ngTemplateOutlet="comments; context: { comments: data.comments }"></ng-container>
</ng-container>

<ng-template #itemDetails let-request="request" let-item="item">
  <rn-item-details [item]="item">
    <div project="statusPill">
      <div class="status-open" *ngIf="request.status == ITEM_REQUEST_STATUS.OPEN">Requested</div>
      <div class="status-closed" *ngIf="request.status == ITEM_REQUEST_STATUS.CLOSED">Closed</div>
      <div class="status-review" *ngIf="request.status == ITEM_REQUEST_STATUS.REVIEW">In Review</div>
    </div>
    <div project="circulationInfo">
        <div class="not-available flex-row">
          <em class="ri-error-warning-fill" aria-hidden="false"></em>
          <span>This item is not available in your library network.</span>
        </div>
    </div>
    <div project="actions">
      <button mat-button class="cancel" (click)="deleteItemRequest(request)">
        Cancel Request
      </button>
    </div>
  </rn-item-details>
</ng-template>

<ng-template #comments let-comments="comments">
  <div class="comments">
    <header>
      <i class="ri-message-3-fill" aria-hidden="true"></i> Librarian and Patron
      Comments ({{ comments.length }})
    </header>
    <div *ngIf="comments.length > 0; else noComments" class="comment-list">
      <ng-container *ngFor="let comment of comments">
        <ng-container *ngTemplateOutlet="commentTmpl; context: { comment: comment }"></ng-container>
      </ng-container>
    </div>
    <div>
      Comment
      <form class="flex-row-col-responsive">
        <textarea style="box-sizing: border-box; flex: 1 1 0" [(ngModel)]="patronComment" [ngModelOptions]="{standalone: true}"
                  placeholder="Add a comment"></textarea>
        <button mat-flat-button color="primary" (click)="postComment()">
          Add Comment
        </button>
      </form>
    </div>
  </div>
  <ng-template #noComments>
    <div class="nocomments">No comments for this request.</div>
  </ng-template>
</ng-template>

<ng-template #commentTmpl let-comment="comment">
  <div class="comment-wrapper">
    <div [ngClass]="{'patron-comment': !comment.staffId,'staff-comment': !!comment.staffId}" class="flex-row">
      <div *ngIf="!comment.staffId" class="avatar">
        <span class="comment-initials">{{getPatronInitials()}}</span>
      </div>
      <div class="comment-text">{{ comment.comment }}</div>
      <div *ngIf="!!comment.staffId" class="staff avatar">
        <span class="ri-account-circle-fill"></span>
      </div>
    </div>
    <div class="comment-date">On {{ comment.created | date:'medium'}}</div>
  </div>
</ng-template>
