import {Component, Input} from '@angular/core';
import {take} from 'rxjs/operators';
import {PatronCardPaymentMethod, PatronPaymentService} from '@raven';

@Component({
  selector: 'rn-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss'],
})
export class PaymentMethodComponent {
  @Input()
  details: PatronCardPaymentMethod;
  @Input()
  isPrimary = false;

  setPrimaryEnabled = true;
  front = true;
  hide = false;

  constructor(private patronPaymentService: PatronPaymentService) {
  }

  hasBrandLogo(): boolean {
    return this.details.cardBrand == 'VISA'
      || this.details.cardBrand == 'MASTERCARD'
      || this.details.cardBrand == 'AMERICAN_EXPRESS'
      || this.details.cardBrand == 'DISCOVER'
      || this.details.cardBrand == 'DISCOVER_DINERS';
  }

  delete(): void {
    this.patronPaymentService.deletePaymentMethod(this.details)
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.hide = true;
        }
      })
  }

  setPrimary(): void {
    if (!this.setPrimaryEnabled) {
      return;
    }
    this.setPrimaryEnabled = false;
    const set$ = this.patronPaymentService.setPrimaryPaymentMethod(this.details)
      .pipe(take(1))
      .subscribe({
        complete: () => {
          this.setPrimaryEnabled = true;
          this.front = true;
        },
        error: () => this.setPrimaryEnabled = true
      });
  }
}
