import {CoverImageStrategy} from '../core/content-enhancement/cover-image-strategy';

export class Organization {
  id?: number;
  googlePatronTenantId?: string;
  slug?: string;
  name?: string;
  organizationTimeZone?: string;
  invitationEmail?: string;
  contactEmail?: string;
  arcgisBoundaryUrl?: string;
  websiteUrl?: string;
  opacUrl?: string;
  policyUrl?: string;
  phoneNumber?: string;
  hooplaLibraryId?: string;
  coverImageSource?: CoverImageSource;
  coverImageStrategy?: CoverImageStrategy;
  demoOrganization?: boolean;
  demoStaffUsername?: string;
  demoStaffPassword?: string;
  demoOpacUsername?: string;
  demoOpacPassword?: string;
}

export enum CoverImageSource {
  CHILIFRESH = 'CHILIFRESH',
  BOWKER = 'BOWKER',
}
