import {Component, Input} from '@angular/core';
import {Hold, HOLD_STATUS} from '@raven';

@Component({
  selector: 'rn-hold-status',
  styles: [`
      .hide {
          display: none;
      }

      .show {
          display: inline-block;
      }
  `],
  template: `
      <mat-chip-list *ngIf="hold">
          <!--[ngClass]="hold ? 'show' : 'hide'">-->
          <mat-chip class="blue-pill small-pill" selected *ngIf="hold.status == HOLD_STATUS.REQUESTED"
                    matTooltip="{{ hold.placedDate | date: 'MMM d, y' }}">
              Requested {{ hold.placedDate | date: 'MMM d' }}
          </mat-chip>
          <mat-chip class="yellow-pill small-pill" selected *ngIf="hold.status == HOLD_STATUS.TRANSIT"
                    matTooltip="In Transit as of {{ hold.modifiedDate | date: 'MMM d, y' }}">
              In Transit
          </mat-chip>
          <mat-chip class="green-pill small-pill" selected *ngIf="hold.status == HOLD_STATUS.PULLED"
                    matTooltip="Item must be picked up by {{ hold.expireDate | date: 'MMM d, y'}}">
              Pick up by {{ hold.expireDate | date: 'MMM d' }}
          </mat-chip>
          <mat-chip class="green-pill small-pill" selected *ngIf="hold.status == HOLD_STATUS.PICKED_UP">
              Checked Out
          </mat-chip>
          <mat-chip class="red-pill small-pill" selected *ngIf="hold.status == HOLD_STATUS.EXPIRED"
                    matTooltip="{{ hold.expireDate | date: 'MMM d, y' }}">
              Expired on {{ hold.expireDate | date: 'MMM d' }}
          </mat-chip>
          <mat-chip class="gray-pill small-pill" selected *ngIf="hold.status == HOLD_STATUS.PAUSED && hold.notWantedBefore"
                    matTooltip="{{ hold.notWantedBefore | date: 'MMM d, y' }}">
              Paused until {{ hold.notWantedBefore | date: 'MMM d' }}
          </mat-chip>
          <mat-chip class="gray-pill small-pill" selected *ngIf="hold.status == HOLD_STATUS.PAUSED && !hold.notWantedBefore">
              Paused
          </mat-chip>
          <mat-chip class="red-pill small-pill" selected *ngIf="hold.status == HOLD_STATUS.CANCELED"
                    matTooltip="{{ hold.modifiedDate | date: 'MMM d, y' }}">
              Canceled {{ hold.modifiedDate | date: 'MMM d' }}
          </mat-chip>
      </mat-chip-list>
  `,
})
export class HoldStatusComponent {
  HOLD_STATUS = HOLD_STATUS;
  @Input() hold: Hold;
}
