import {Action} from '@ngrx/store';
import {Branch, Organization, Patron} from "@raven";

export const DATA_LOAD = '[Data] Load';
export const DATA_LOADED = '[Data] Loaded';
export const BRANCH_UPDATED = '[Branch] Updated';
export const ORGANIZATION_UPDATED = '[Organization] Updated';
export const USER_UPDATED = '[User] Updated';
export const PATRON_UPDATED = '[Patron] Updated';
export const ADMIN_PAGE_ON = '[AdminPage] Off';
export const ADMIN_PAGE_OFF = '[AdminPage] On';
export const CLIENT_KEY_UPDATED = '[ClientKey] Updated';
export const DASHBOARD_CONFIGURATION_UPDATED =
  '[DashboardConfiguration] Updated';
export const TENANT_UPDATED = '[Tenant] Updated';

export class LoadData implements Action {
  readonly type = DATA_LOAD;
}

export class DataLoaded implements Action {
  readonly type = DATA_LOADED;
}

export class BranchUpdatedAction implements Action {
  readonly type = BRANCH_UPDATED;

  constructor(public payload: Branch) {
  }
}

export class OrganizationUpdatedAction implements Action {
  readonly type = ORGANIZATION_UPDATED;

  constructor(public payload: Organization) {
  }
}

// export class UserUpdatedAction implements Action {
//   readonly type = USER_UPDATED;
//   constructor(public payload: User) {}
// }

export class PatronUpdatedAction implements Action {
  readonly type = PATRON_UPDATED;

  constructor(public payload: Patron) {
  }
}

export class AdminPageOnAction implements Action {
  readonly type = ADMIN_PAGE_ON;

  constructor() {
  }
}

export class AdminPageOffAction implements Action {
  readonly type = ADMIN_PAGE_OFF;

  constructor() {
  }
}

export class ClientKeyUpdatedAction implements Action {
  readonly type = CLIENT_KEY_UPDATED;

  constructor(public payload: string) {
  }
}

export class DashboardConfigurationUpdatedAction implements Action {
  readonly type = DASHBOARD_CONFIGURATION_UPDATED;

  constructor(public payload: any) {
  }
}

export class TenantUpdatedAction implements Action {
  readonly type = TENANT_UPDATED;

  constructor(public payload: string) {
  }
}

export type All =
  | LoadData
  | DataLoaded
  | BranchUpdatedAction
  // | UserUpdatedAction
  | PatronUpdatedAction
  | AdminPageOnAction
  | AdminPageOffAction
  | OrganizationUpdatedAction
  | ClientKeyUpdatedAction
  | DashboardConfigurationUpdatedAction
  | TenantUpdatedAction;
