import {animate, state, style, transition, trigger,} from '@angular/animations';
import {SelectionModel} from '@angular/cdk/collections';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {DatePipe} from '@angular/common';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute} from '@angular/router';
import {EMPTY, Subject, switchMap, takeUntil,} from 'rxjs';
import {take} from 'rxjs/operators';
import {SubSink} from 'subsink';
import moment from 'moment';
import {
  AuthService,
  CatalogRecordService,
  Checkout,
  CirculationItemService,
  ConfirmationDialogComponent,
  Environment,
  ListDataService,
  NotificationService,
  PATRON_STATUS,
  PatronAccountStatus,
  PatronLedgerService,
  PatronService,
  RoutesService
} from '@raven';
import {ClaimReturnedDialog} from './dialogs/claims-dialog';
import {CheckoutService} from './checkout.service';

@Component({
  selector: 'rn-patron-checkouts',
  templateUrl: './patron-checkouts.component.html',
  providers: [ListDataService, DatePipe],
  styleUrls: ['./patron-checkouts.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class PatronCheckoutsComponent implements OnInit, OnDestroy {
  private subs = new SubSink();
  patronAccountStatus: PatronAccountStatus;
  selection: SelectionModel<Checkout> =
    new SelectionModel<Checkout>(true, []);
  destroy$ = new Subject<boolean>();
  isMobile = false;
  desktopColumns = [
    'checkbox',
    'title',
    'mediaType',
    'checkoutDate',
    'lastRenewal',
    'dueDate',
    'status',
    'actions',
  ];
  mobileColumns = [
    'checkbox',
    'mobile',
    'actions',
  ];
  columnsToDisplay = this.desktopColumns;
  today = moment().startOf('day');

  constructor(public listDataService: ListDataService,
              private environment: Environment,
              private authService: AuthService,
              private breakpointObserver: BreakpointObserver,
              private catalogRecordService: CatalogRecordService,
              private circulationItemService: CirculationItemService,
              private checkoutService: CheckoutService,
              private notificationService: NotificationService,
              private patronService: PatronService,
              private patronLedgerService: PatronLedgerService,
              private route: ActivatedRoute,
              private dialog: MatDialog,
              public routingService: RoutesService,
              public datePipe: DatePipe) {
  }

  isLoading = false;

  ngOnInit(): void {
    this.listDataService.setFetchData(({filter, sort, page}) => {
      page ??= {page: 0, limit: 10};
      return this.checkoutService.patronGetCheckouts(filter, page?.page, page?.limit);
    });
    this.patronLedgerService.getAccountStatus().pipe(take(1)).subscribe(
      (status) => {
        this.patronAccountStatus = status;
      }
    );
    this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(takeUntil(this.destroy$))
      .subscribe((breakpointState: BreakpointState) => {
        if (breakpointState.matches) {
          this.isMobile = true;
          this.columnsToDisplay = this.mobileColumns;
        } else {
          this.isMobile = false;
          this.columnsToDisplay = this.desktopColumns;
        }
      });
  }

  getRenewableItems(items: Checkout[]): Checkout[] {
    const renewItems: Checkout[] = [];
    for (const item of items) {
      if (this.isRenewable(item)) {
        renewItems.push(item);
      }
    }
    return renewItems;
  }

  hasOverdueItem(items: Checkout[]): boolean {
    for (const item of items) {
      if (item.dueDays < 0) {
        return true;
      }
    }
    return false;
  }

  renewCheckout(items: Checkout[]): void {
    const renewItems = this.getRenewableItems(items);
    if (renewItems.length == 0) {
      return;
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Renew Item (' + renewItems.length + ')',
        message:
          renewItems.length > 1
            ? 'Are you sure you want to renew the selected items?'
            : 'Are you sure you want to renew this item?',
        warning: this.hasOverdueItem(renewItems)
          ? 'Overdue fines will remain due, but renewing will stop new fines from accruing from this point' +
          ' forward.'
          : '',
        confirmButton: 'Yes, Renew',
      },
      maxWidth: '95vw',
    });
    this.subs.sink = dialogRef.afterClosed()
      .pipe(
        switchMap((confirmed: boolean) => {
          if (confirmed) {
            if (renewItems.length == 1) {
              return this.checkoutService.renewCheckout(renewItems[0].checkoutId);
            } else {
              return this.checkoutService.renewCheckoutList(renewItems.map((item) => item.checkoutId));
            }
          }
          return EMPTY;
        }),
      )
      .subscribe((result) => {
        if (Array.isArray(result)) {
          this.notificationService.showSnackbarSuccess(
            `${result.length} items have been successfully renewed.`
          );
        } else {
          this.notificationService.showSnackbarSuccess(
            '1 item has been successfully renewed.'
          );
        }
        this.listDataService.triggerRefresh();
      });
  }

  updateCheckout(updated: Checkout): void {
    for (const item of this.listDataService.data) {
      if (item.checkoutId == updated.checkoutId) {
        item.lastRenewal = updated.lastRenewal;
        item.dueDate = updated.dueDate;
        return;
      }
    }
  }

  submitClaim(item: Checkout): void {
    const dialogRef = this.dialog.open(ClaimReturnedDialog, {
      panelClass: 'dialog-no-pad',
      autoFocus: false,
      data: {
        checkoutId: item.checkoutId,
        title: item.itemTitle,
        barcode: item.itemBarcode,
      },
      maxWidth: '95vw',
      maxHeight: '90vh',
    });
    this.subs.sink = dialogRef.afterClosed()
      .pipe(
        switchMap((claim) =>
          (claim?.checkoutId && claim?.claimType)
            ? this.checkoutService.claimReturned(claim)
            : EMPTY
        )
      )
      .subscribe((claim) => {
        if (claim) {
          this.notificationService.showSnackbarSuccess('Claim has been submitted.');
          this.listDataService.triggerRefresh();
        }
      });
  }

  allSelected(): boolean {
    return this.selection.selected.length === this.listDataService.data.length;
  }

  toggleAll(): void {
    this.allSelected()
      ? this.selection.clear()
      : this.listDataService.data.forEach((row) => this.selection.select(row));
  }

  selectionHasRenewableItems(): boolean {
    if (!this.selection.hasValue()) {
      return false;
    }
    for (const circulationItemCheckout of this.selection.selected) {
      if (this.isRenewable(circulationItemCheckout)) {
        return true;
      }
    }
    return false;
  }

  getTooltipText(item: Checkout): string {
    if (item.claimId > 0) {
      return 'Item is ineligible for renewal'
    }
    if (item.holdExists) {
      return 'Item has holds, cannot renew';
    }
    if (!PatronLedgerService.underMaxBalance(this.patronAccountStatus)) {
      return 'Fee limit reached, cannot renew';
    }
    if (this.patronAccountStatus.accountStatus === PATRON_STATUS.EXPIRED) {
      return 'Account expired, cannot renew';
    }
    if (this.patronAccountStatus.accountStatus === PATRON_STATUS.SUSPENDED) {
      return 'Account suspended, cannot renew';
    }
    return 'Renew';
  }

  isRenewable(checkout: Checkout): boolean {
    return (
      !checkout.holdExists && checkout.claimId == null && PatronLedgerService.canRenew(this.patronAccountStatus)
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.destroy$.next(true);
  }
}
