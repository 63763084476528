import {CatalogFacet} from '@raven';

export class CatalogFacets {
  [key: string]: CatalogFacet

  public static fromLinkParams(linkType: string, value: string): CatalogFacets {
    if (linkType === 'genre') {
      return {'genres.keyword': {name: 'genres.keyword', values: [{filterValue: value}]}};
    } else if (linkType === 'subject') {
      return {'subjects.keyword': {name: 'subjects.keyword', values: [{filterValue: value}]}};
    } else if (linkType === 'author') {
      return {'author.keyword': {name: 'author.keyword', values: [{filterValue: value}]}};
    } else {
      return {};
    }
  }
}