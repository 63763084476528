import {NgModule} from '@angular/core';
import {RegistrationPersonalInfoComponent} from './registration-personal-info.component';
import {RegistrationRoutingModule} from './registration-routing.module';
import {RegistrationAccountCreationComponent} from './registration-account-creation.component';
import {RegistrationAgeValidationComponent} from './registration-age-validation.component';
import {RegistrationNotificationPrefComponent} from './registration-notification-pref.component';
import {TermsComponent} from '@raven';
import {RegistrationSidePanelComponent} from './registration-side-panel.component';
import {RegisterExistingCardComponent} from './register-existing-card.component';
import {PhoneValidatorsModule} from 'ngx-phone-validators';
import {SharedModule} from "../shared/shared.module";

@NgModule({
  imports: [
    SharedModule,
    // import the routing for this module
    RegistrationRoutingModule,
    PhoneValidatorsModule,
  ],
  declarations: [
    RegistrationAccountCreationComponent,
    RegistrationAgeValidationComponent,
    RegistrationPersonalInfoComponent,
    RegistrationNotificationPrefComponent,
    RegisterExistingCardComponent,
    RegistrationSidePanelComponent,
    TermsComponent,
  ],
  providers: [],
})
export class RegistrationModule {
}
