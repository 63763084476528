import {MaterialType, MaterialTypeCode} from './material-type';
import {CoverImageStrategy} from '../core/content-enhancement/cover-image-strategy';

export class CatalogRecord {
  id: number;
  marcRecord?: string;  // remove this for patron facing
  title: string;
  description?: string;
  author?: string;
  identifier?: string;
  identifierType: IdentifierType;
  imageUrl?: string;
  materialType: MaterialType;
  availability?: CatalogRecordAvailability;
  marcDetails?: MarcDetails;

  patronFavorite?: boolean;


  public static getImageUrls(catalogRecord: CatalogRecord, imageSource: CoverImageStrategy): string[] {
    if (!catalogRecord) {
      return [];
    }
    const imageUrls = [];
    if (catalogRecord.identifier) {
      imageUrls.push(imageSource.getImageUrl(catalogRecord.identifier));
    }
    if (catalogRecord.imageUrl) {
      imageUrls.push(catalogRecord.imageUrl)
    }
    imageUrls.push(CatalogRecord.getPlaceholderImageUrl(catalogRecord));
    //TODO: eventually dig into itemDetails/marcDetails to calculate full list of item urls
    return imageUrls;
  }

  private static getPlaceholderImageUrl(catalogRecord: CatalogRecord): string {
    const code = catalogRecord?.materialType?.code;
    if (!code || code == MaterialTypeCode.BOOK) {
      return '/assets/stock-book-image.jpg';
    }
    if (code == MaterialTypeCode.BLU_RAY) {
      return '/assets/stock-cover-art/BluRay-NW.png';
    }
    if (code == MaterialTypeCode.CD_AUDIO || code == MaterialTypeCode.CD_FOR_COMPUTER) {
      return '/assets/stock-cover-art/CD-NW.png';
    }
    if (code == MaterialTypeCode.DVD_AUDIO || code == MaterialTypeCode.DVD_VIDEO) {
      return '/assets/stock-cover-art/DVD-NW.png';
    }
    if (code == MaterialTypeCode.NEWSPAPER) {
      return '/assets/stock-cover-art/Newpaper-NW.png';
    }
    if (code == MaterialTypeCode.PERIODICAL || code == MaterialTypeCode.ARTICLE) {
      return '/assets/stock-cover-art/Periodical-NW.png';
    }
    if (code == MaterialTypeCode.SERIAL) {
      return '/assets/stock-cover-art/Serial-NW.png';
    }
    return '/assets/stock-book-image.jpg';
  }
}

export class CatalogRecordAvailability {
  allBranchCount?: number;
  availableCount?: number;
  inTransferCount?: number;
  onHoldCount?: number;
  onOrderCount?: number;
  patronHomeBranchCopyCount?: number;
}

export enum IdentifierType {
  ISBN = 'ISBN',
  ISSN = 'ISSN',
  UPC = 'UPC',
  EAN = 'EAN'
}

export class MarcDetails {
  title?: string;
  subtitle?: string;
  statementOfResponsibility?: string;
  author?: string;
  otherAuthors?: string[];
  summary?: string;
  languageCode?: string;
  physicalDescription?: string;
  publisher?: string;
  publicationDate?: string;
  publicationLocation?: string;
  edition?: string;
  subjects?: string[];
  genres?: string[];
  notes?: string[];
  callNumbers?: { [key: string]: string };
  identifiers?: { [key: string]: string[] };
  controlNumbers?: { [key: string]: string };
  marcRecordStrings?: string[];
  clean: CleanedMarcDetails;
}

export class CleanedMarcDetails {
  publicationDate: string;
}
