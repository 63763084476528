import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Hold, HoldService, RoutesService} from '@raven';

/**
 * CANCEL HOLD DIALOG COMPONENT
 */
@Component({
  selector: 'rn-cancel-hold-dialog',
  template: `
    <div class="hold-dialog flex-col">
      <h3>Cancel Hold{{ cancellableHolds.length | plural }} ({{ cancellableHolds.length }})</h3>
      <div class="flex-col flex-start-start">
        <ng-container *ngIf="cantCancel > 0">
          <div class="warn-box dialog-warn-box flex-row flex-center-start">
            <span>{{ cantCancel }} of the selected items cannot be cancelled.</span>
          </div>
        </ng-container>
        <span class="Text-Style dialog-body">
                Are you sure you want to cancel {{ cancellableHolds.length }} item hold{{ cancellableHolds.length | plural}}?
              </span>
      </div>
      <div class="dialog-button-row">
        <button mat-stroked-button class="save-button-group button-cancel" (click)="onNoClick()">No</button>
        <button mat-raised-button color="primary" class="save-button-group save-button" (click)="onCancel()">
          Yes, Cancel
        </button>
      </div>
    </div>
  `,
  styles: [``],
})
export class CancelHoldDialog {
  cancellableHolds: Hold[];
  cantCancel: number;

  constructor(
    public dialogRef: MatDialogRef<CancelHoldDialog>,
    private holdService: HoldService,
    public routesService: RoutesService,
    @Inject(MAT_DIALOG_DATA) public data: { holds: Hold[], refreshHolds: () => void }
  ) {
    this.cancellableHolds = HoldService.getCancellableHolds(data.holds);
    this.cantCancel = data.holds.length - this.cancellableHolds.length;
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onCancel(): void {
    this.holdService.cancel(
      this.cancellableHolds.map((h) => h.id),
      {onSuccess: this.onSuccess.bind(this)}
    );
  }

  onSuccess(result: boolean): void {
    if (result) {
      this.data.refreshHolds();
      this.dialogRef.close(true);
    }
  }
}
