import {CatalogRecord} from './catalog-record';

export class GoogleCatalogRecord {
  kind?: string;
  id?: number;
  etag?: string;
  selfLink?: string;
  title?: string;
  authors?: string[];
  publisher?: string;
  publishedDate?: string;
  description?: string;
  // ISBN's
  identifiers?: GoogleIndustryIdentifier[];
  isbn10?: string;
  isbn13?: string;
  printedPageCount?: number;
  pageCount?: number;
  height?: string;
  width?: string;
  thickness?: string;
  printType?: string;
  mainCategory?: string;
  categories?: string[];
  averageRating?: number;
  ratingsCount?: number;
  contentVersion?: string;
  imageLinks?: GoogleImageLinks;

  // Google SaleInfo object
  saleInfo?: SaleInfo;

  // Google AccessInfo object
  accessInfo?: AccessInfo;

  // Mill-eh-nay objects
  catalogRecord?: CatalogRecord;
}

// export enum CatalogRecordIdentifierType {
//     ISBN_10 = 'ISBN_10',
//     ISBN_13 = 'ISBN_13',
//     OTHER = 'OTHER'
// }

export class AccessInfo {
  country?: string;
  viewability?: string;
  embeddable?: boolean;
  publicDomain?: boolean;
  textToSpeechPermission?: string;
  epub?: EPub;
  availablePDF?: boolean;
  accessViewStatus?: string;
}

export class EPub {
  available?: boolean;
  acsTokenLink?: string;
}

export class GoogleCatalogRecordPrice {
  amount?: number;
  currencyCode?: string;
}

export class GoogleImageLinks {
  extraLarge?: string;
  large?: string;
  medium?: string;
  small?: string;
  smallThumbnail?: string;
  thumbnail?: string;
}

export class GoogleIndustryIdentifier {
  type?: string;
  identifier?: string;
}

export class SaleInfo {
  country?: string;
  saleability?: string;
  ebook?: boolean;
  listPrice?: GoogleCatalogRecordPrice;
  retailPrice?: GoogleCatalogRecordPrice;
  buyLink?: string;
}

export enum GOOGLE_RECORD_REQUEST_STATUS {
  LIBRARY_OWNED = 'LIBRARY_OWNED',
  PATRON_REQUESTED = 'PATRON_REQUESTED',
  OTHER_PATRON_REQUESTED = 'OTHER_PATRON_REQUESTED',
  REQUESTED_CLOSED = 'REQUESTED_CLOSED',
  CAN_REQUEST = 'CAN_REQUEST',
}
