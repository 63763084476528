import {NgModule} from '@angular/core';
import {VerificationComponent} from './verification.component';
import {VerificationEmailComponent} from './verification-email.component';
import {SharedModule} from "../shared/shared.module";

@NgModule({
  imports: [SharedModule],
  declarations: [VerificationComponent, VerificationEmailComponent],
  providers: [],
})
export class VerificationModule {
}
