import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, tap} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {GoogleCatalogRecord, GoogleIndustryIdentifier} from '../model/google-catalog-record';
import {CatalogRecord} from '../model/catalog-record';
import {Environment} from '../model/environment';
import {ItemRequestResponse} from '../model/item-request/item-request-response';
import {ItemRequest} from '../model/item-request/item-request';
import {ResponseBase} from '../model/response-base/response-base';
import {MovieDataImdbRecord} from '../model/open-library/movie-data-imdb-record';
import {OpenLibraryAuthorSearch} from '../model/open-library/open-library-author-search';

@Injectable({
  providedIn: 'root',
})
export class ItemRequestService {

  constructor(private environment: Environment, private http: HttpClient) {
  }

  closeRequest(organizationId: number, itemRequestId: number, response: ItemRequestResponse): Observable<ItemRequest> {
    const url = `${this.environment.apiUrl}/organizations/${organizationId}/item-requests/${itemRequestId}/close`;
    return this.http
      .post<ItemRequest>(url, JSON.stringify(response))
      .pipe(
        tap((_) =>
          console.log(
            'Closed request with code: ' +
            response.code +
            ' - ' +
            response.title
          )
        ),
        catchError((err: unknown) => throwError(err))
      );
  }

  reopenRequest(organizationId: number, itemRequestId: number, itemRequest: ItemRequest) {
    const url = `${this.environment.apiUrl}/organizations/${organizationId}/item-requests/${itemRequestId}/reopen`;
    return this.http
      .put<ItemRequest>(url, JSON.stringify(itemRequest))
      .pipe(
        tap((_) => console.log('Reopened request with id: ' + itemRequestId)),
        catchError((err: unknown) => throwError(err))
      );
  }

  getByOrgAndISBN(organizationId: number, isbn: string): Observable<ItemRequest> {
    const url = `${this.environment.apiUrl}/organizations/${organizationId}/item-requests/isbn/${isbn}`;
    return this.http.get<ItemRequest>(url).pipe(
      tap((_) => console.log('fetched objects')),
      catchError((err: unknown) => throwError(err))
    );
  }

  getByOrgAndISBNs(organizationId: number, googleIdentifiers: GoogleIndustryIdentifier[]): Observable<ItemRequest> {
    const url = `${this.environment.apiUrl}/organizations/${organizationId}/item-requests/google-identifiers/${googleIdentifiers}`;
    return this.http.get<ItemRequest>(url).pipe(
      tap((_) => console.log('fetched objects')),
      catchError((err: unknown) => throwError(err))
    );
  }

  listByOrganizationIdAndGroupedByISBN(organizationId: number, page: number, limit: number
  ): Observable<ResponseBase<ItemRequest>> {
    const url = `${this.environment.apiUrl}/organizations/${organizationId}/item-requests/grouped/isbn`;
    return this.http.get<ResponseBase<ItemRequest>>(url).pipe(
      tap((_) => console.log('fetched objects')),
      catchError((err: unknown) => throwError(err))
    );
  }

  getByGoogleId(organizationId: number, googleId: string): Observable<GoogleCatalogRecord> {
    const url = `${this.environment.apiUrl}/organizations/${organizationId}/item-requests/google-book-search/${googleId}`;
    return this.http.get<GoogleCatalogRecord>(url).pipe(
      tap((result) => console.log(result)),
      catchError((err: unknown) => throwError(err))
    );
  }

  searchGoogleAPIByISBN(isbn: string): any {
    const url = `https://www.googleapis.com/books/v1/volumes?q=isbn:${isbn}`;
    return this.http.get(url).pipe(
      tap((result) => console.log(result)),
      catchError((err: unknown) => throwError(err))
    );
  }

  searchGoogleAPIByQuery(q: string): any {
    const url = `https://www.googleapis.com/books/v1/volumes?q=${q}`;
    return this.http.get(url).pipe(
      tap((result) => console.log(result)),
      catchError((err: unknown) => throwError(err))
    );
  }

  /**
   * Searches GOOGLE's open library API for book title
   * @param organizationId
   * @param title
   * @param author
   * @param keywords
   * @param isbn
   * @param page
   * @param limit
   */
  searchGoogleForBook(organizationId: number, title: string, author: string, keywords: string, isbn: string, page: number, limit: number): Observable<ResponseBase<GoogleCatalogRecord>> {
    const url = `${
      this.environment.apiUrl
    }/organizations/${organizationId}/item-requests/search-book?title=${title}&author=${author}&keywords=${keywords}&isbn=${isbn}&page=${
      !page ? 0 : page
    }&limit=${!limit ? 10 : limit}`;
    return this.http.get<ResponseBase<GoogleCatalogRecord>>(url).pipe(
      tap((_) => console.log(`fetched list of books`)),
      catchError((err: unknown) => throwError(err))
    );
  }

  searchInternalCatalogForBook(organizationId: number, title: string, author: string, page?: number, limit?: number): Observable<ResponseBase<CatalogRecord>> {
    const url = `${
      this.environment.apiUrl
    }/organizations/${organizationId}/catalog-records/search-book?title=${title}&author=${author}&page=${
      !page ? 0 : page
    }&limit=${!limit ? 10 : limit}`;
    return this.http.get<ResponseBase<CatalogRecord>>(url).pipe(
      tap((_) => console.log(`fetched list of books`)),
      catchError((err: unknown) => throwError(err))
    );
  }

  searchForMovie(organizationId: number, title: string, movieSeries: string): Observable<MovieDataImdbRecord[]> {
    const url = `${this.environment.apiUrl}/organizations/${organizationId}/item-requests/search-movie-series?title=${title}&movieSeries=${movieSeries}`;
    return this.http.get<MovieDataImdbRecord[]>(url).pipe(
      tap((_) => console.log(`fetched list of movies by title: ` + title)),
      catchError((err: unknown) => throwError(err))
    );
  }

  /**
   * Searches Open Library API for author
   * @param author
   */
  searchOpenLibraryAuthor(author: string): Observable<OpenLibraryAuthorSearch> {
    author = author.replace(/ /g, '%20');
    const url = `https://openlibrary.org/search/authors.json?q=${author}`;
    return this.http.get<OpenLibraryAuthorSearch>(url);
  }
}
