import {Component, Input} from '@angular/core';
import {Checkout, CHECKOUT_STATUS, CLAIM_STATUS} from '@raven';
import moment from 'moment';

@Component({
  selector: 'rn-checkout-status',
  template: `
    <mat-chip-list style="display: inline-block">
      <mat-chip class="blue-pill small-pill" selected *ngIf="hasClaim(checkout)">
        {{ getClaimStatus(checkout.claimType)}}
      </mat-chip>
      <mat-chip class="red-pill small-pill" selected *ngIf="inTheRed(checkout)">
        {{ dateFromNow(checkout) }}
      </mat-chip>
      <mat-chip class="yellow-pill small-pill" selected *ngIf="inTheYellow(checkout)">
        {{ dateFromNow(checkout) }}
      </mat-chip>
      <mat-chip class="green-pill small-pill" selected *ngIf="inTheGreen(checkout)">
        {{ dateFromNow(checkout) }}
      </mat-chip>
    </mat-chip-list>
  `,
  styles: [`
  `],
})
export class PatronCheckoutStatusComponent {
  @Input() checkout: Checkout;
  today = moment().startOf('day');

  hasClaim(checkout: Checkout): boolean {
    return checkout.status == CHECKOUT_STATUS.CLAIMED_FOUND
      || checkout.status == CHECKOUT_STATUS.CLAIMED_RETURNED
      || checkout.status == CHECKOUT_STATUS.CLAIMED_LOST
      || checkout.status == CHECKOUT_STATUS.CLAIMED_LOST_PAID;
  }

  inTheRed(checkout: Checkout): boolean {
    return checkout.status == CHECKOUT_STATUS.OVERDUE ||
      (checkout.status == CHECKOUT_STATUS.DUE && checkout.dueDays <= 2);
  }

  inTheYellow(checkout: Checkout): boolean {
    return checkout.status == CHECKOUT_STATUS.DUE && checkout.dueDays > 2 && checkout.dueDays <= 7;
  }

  inTheGreen(checkout: Checkout): boolean {
    return checkout.status == CHECKOUT_STATUS.CHECKED_IN ||
      (checkout.status == CHECKOUT_STATUS.DUE && checkout.dueDays > 7);
  }

  dateFromNow(checkout: Checkout): string {
    if (checkout.status == CHECKOUT_STATUS.CHECKED_IN) {
      return `Returned`;
    }
    if (checkout.dueDays > 0) {
      return `Due in ${checkout.dueDays} days`;
    }
    if (checkout.dueDays < 0) {
      return `Overdue by ${-checkout.dueDays} days`;
    }
    return 'Due';
  }

  getClaimStatus(status: CLAIM_STATUS) {
    if (!status) {
      return '';
    }
    if (status == CLAIM_STATUS.LOST_PAID) {
      return "Lost and Paid";
    }
    const statusStr = status.toString();
    return 'Claimed ' + statusStr[0] + statusStr.slice(1).toLowerCase();
  }
}
