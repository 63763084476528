import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {GlobalState, OrganizationUpdatedAction} from '@raven';
import {BehaviorSubject, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Environment} from '../model/environment';
import {CoverImageSource, Organization} from '../model/organization';
import {BowkerCoverImageSource} from '../core/content-enhancement/bowker-cover-image-source';
import {ChiliFreshCoverImageSource} from '../core/content-enhancement/chili-fresh-cover-image-source';
import {CoverImageStrategy} from '../core/content-enhancement/cover-image-strategy';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  organizationPipe = new BehaviorSubject(null);
  organization$ = this.organizationPipe.asObservable();

  public coverImageStrategy: CoverImageStrategy;

  constructor(private environment: Environment,
              private http: HttpClient,
              protected store: Store<GlobalState>) {
  }

  setupOrganization(): Observable<Organization> {
    return this.getByHostname().pipe(
      tap((organization: Organization) => {
        this.organizationPipe.next(organization);
        // put it in the store
        this.setCoverImageStrategy(organization);
        this.coverImageStrategy = organization.coverImageStrategy;
        this.store.dispatch(new OrganizationUpdatedAction(organization));
      })
    );
  }

  private getByHostname(): Observable<Organization> {
    const url = `${this.environment.apiUrl}/v1/organizations/tenant/patron-ui`;
    return this.http.get<Organization>(url);
  }

  getOrganization(id: number): Observable<Organization> {
    const url = `${this.environment.apiUrl}/organizations/${id}`;
    return this.http.get<Organization>(url);
  }

  private setCoverImageStrategy(organization: Organization): void {
    if (!organization) {
      return;
    }
    if (organization.coverImageSource == CoverImageSource.BOWKER) {
      organization.coverImageStrategy = new BowkerCoverImageSource(this.environment);
      return;
    }
    organization.coverImageStrategy = new ChiliFreshCoverImageSource();
  }
}
