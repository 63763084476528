import {Environment} from '../app/model/environment';

export const environment: Environment = {
  name: 'dev',
  app: 'raven',
  production: false,
  enableSentry: false,
  webUrl: 'https://dev.simplicitylibrary.com',
  apiUrl: 'https://api.dev.simplicitylibrary.com/api',
  graphUrl: 'https://api.dev.simplicitylibrary.com/graphql',
  loginUrl: 'https://api.dev.simplicitylibrary.com/api/authenticate',
  snackBarTimeout: 6000,
  schedulerLicenseKey: '0119883474-fcs-1591401600',
  skipFireAuth: false,
  firebase: {
    // millonex-dev
    apiKey: 'AIzaSyCu-RkpLWvAU6_rbcLAlOrVQAmAAxTiIUc',
    authDomain: 'millonex-dev.firebaseapp.com',
    databaseURL: 'https://millonex-dev.firebaseio.com',
    projectId: 'millonex-dev',
    storageBucket: 'millonex-dev.appspot.com',
    messagingSenderId: '433705526478',
    appId: '1:433705526478:web:8b5941dd792f1097809c9f',
    measurementId: 'G-PXW621DMQ5',
  },
  squareWebSdkUrl: "https://sandbox.web.squarecdn.com/v1/square.js",
};
