import {LoginComponent} from './core/login/login.component';
import {LogoutComponent} from './core/login/logout.component';
import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {VerificationEmailComponent} from './core/verification/verification-email.component';
import {AppLayoutComponent} from './_layout/app-layout.component';
import {CatalogHomeComponent} from './core/catalog/catalog-home.component';
import {Error401Component} from './core/error-pages/401.component';
import {Error403Component} from './core/error-pages/403.component';
import {Error404Component} from './core/error-pages/404.component';
import {Error500Component} from './core/error-pages/500.component';
import {RegistrationGuard} from './core/registration/registration.guard';
import {RegistrationAccountCreationComponent} from './core/registration/registration-account-creation.component';
import {RegisterExistingCardComponent} from './core/registration/register-existing-card.component';
import {RegistrationPersonalInfoComponent} from './core/registration/registration-personal-info.component';
import {RegistrationNotificationPrefComponent} from './core/registration/registration-notification-pref.component';
import {RegistrationAgeValidationComponent} from './core/registration/registration-age-validation.component';
import {IndexComponent} from './core/index/index.component';
import {AuthGuard} from './core/guards/auth.guard';
import {CatalogBrowseComponent} from './core/catalog/catalog-browse/catalog-browse.component';
import {CatalogItemDetailsComponent} from './core/catalog/catalog-item-details/catalog-item-details.component';
import {FavoritesComponent} from './core/favorites/favorites.component';
import {HoldsComponent} from './core/holds/holds.component';
import {ProfileComponent} from './core/profile/profile.component';
import {PatronCheckoutsComponent} from './core/patron-checkouts/patron-checkouts.component';
import {ItemRequestListComponent} from './core/item-requests/item-request-list/item-request-list.component';
import {ItemDetailsComponent} from './core/item-requests/item-details/item-details.component';
import {ItemRequestDetailsComponent} from './core/item-requests/item-request-details/item-request-details.component';
import {CommunicationPreferencesComponent} from './core/profile/communication-preferences.component';
import {MyCardComponent} from './core/profile/my-card.component';

const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      {path: '', component: IndexComponent, data: {navbarVisible: false}},

      {path: 'login', component: LoginComponent, data: {title: 'Login'}},
      {path: 'logout', component: LogoutComponent, data: {title: 'Logout'}},

      {path: 'verification/verify-email', component: VerificationEmailComponent, data: {navbarVisible: false}},

      // error pages
      {path: '401', component: Error401Component},
      {path: '403', component: Error403Component},
      {path: '404', component: Error404Component},
      {path: '500', component: Error500Component},

      // "public" routes.  We do want to force registration completion if they are authenticated, though
      {
        canActivate: [RegistrationGuard],
        path: '', children: [
          {path: 'dashboard', component: CatalogHomeComponent, data: {footerVisible: false, title: 'Dashboard'}},
          // catalog
          {path: 'catalog', component: CatalogHomeComponent, data: {footerVisible: false, title: 'Catalog'}},
          {path: 'catalog-browse', component: CatalogBrowseComponent, data: {title: 'Catalog'}},
          {path: 'catalog-item/:id', component: CatalogItemDetailsComponent, data: {title: 'Catalog'}},
        ]
      },

      // registration routes
      {
        canActivate: [RegistrationGuard],
        data: {navbarVisible: false},
        path: '', children: [
          {path: 'register', component: RegistrationAccountCreationComponent, data: {navbarVisible: false}},
          {path: 'register-existing-card', component: RegisterExistingCardComponent, data: {navbarVisible: false}},

          // {path: 'register/verification', component: RegistrationAccountCreationComponent},
          {path: 'register/patron-info', component: RegistrationPersonalInfoComponent, data: {navbarVisible: false}},
          {path: 'register/notifications', component: RegistrationNotificationPrefComponent, data: {navbarVisible: false}},
          {path: 'register/in-person', component: RegistrationAgeValidationComponent, data: {navbarVisible: false}},
        ]
      },

      // authenticated routes
      {
        canActivate: [AuthGuard, RegistrationGuard],
        path: '', children: [

          // favorites
          {path: 'favorites', component: FavoritesComponent, data: {footerVisible: false, title: 'Favorites'}},

          // holds
          {path: 'holds', component: HoldsComponent, data: {footerVisible: false, title: 'Holds'}},
          {path: 'profile', component: ProfileComponent, data: {footerVisible: false, title: 'Profile'}},
          {path: 'checkouts', component: PatronCheckoutsComponent, data: {footerVisible: false, title: 'Items Out'}},

          // patron item requests
          {path: 'patron-item-requests-v2', component: ItemRequestListComponent, data: {title: 'Item Requests'}},
          {path: 'outside-item-details/:isbn', component: ItemDetailsComponent, data: {title: 'Outside Item Details'}},
          {path: 'item-request-details/:id', component: ItemRequestDetailsComponent, data: {title: 'Item Request Details'}},

          {path: 'profile', component: ProfileComponent, data: {footerVisible: false, title: 'Profile'}},
          {path: 'communication-preferences', component: CommunicationPreferencesComponent, data: {title: 'Communication Preferences'}},
          {path: 'my-card', component: MyCardComponent, data: {title: 'My Card'}},
        ]
      }
    ],
  },

  // fallthrough if nothing else matches redirect to '/'
  {path: '**', redirectTo: '/', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: "enabled"})],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
