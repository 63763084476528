import {FacetValue} from '@raven';

export class SearchFacets {
  facets = [
    new Facet('Categories', [
      {
        displayValue: 'Biography',
        filterValue: 'Biography',
        count: 1,
      },
      {
        displayValue: 'Business',
        filterValue: 'Business',
        count: 1,
      },
      {
        displayValue: 'Children',
        filterValue: 'Children',
        count: 1,
      },
      {
        displayValue: 'Fantasy',
        filterValue: 'Fantasy',
        count: 1,
      },
      {
        displayValue: 'Fiction',
        filterValue: 'Fiction',
        count: 1,
      },
      {
        displayValue: 'Category 6',
        filterValue: 'Category 6',
        count: 1,
      },
      {
        displayValue: 'Category 7',
        filterValue: 'Category 7',
        count: 1,
      },
      {
        displayValue: 'Category 8',
        filterValue: 'Category 8',
        count: 1,
      },
      {
        displayValue: 'Category 9',
        filterValue: 'Category 9',
        count: 1,
      },
    ]),

  ];
}

export class Facet {
  label: string;
  values: Array<FacetValue>;

  constructor(label, values) {
    this.label = label;
    this.values = values;
  }
}
