import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EMPTY, Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {Environment} from '../model/environment';
import {CatalogRecord} from '../model/catalog-record';
import {ResponseBase} from '../model/response-base/response-base';
import {NotificationService} from './notification.service';
import {CatalogSearchResponse} from '../model/catalog-search/catalog-search-response';
import {CatalogSearchState} from '../model/catalog-search/catalog-search-state';
import {BowkerMetadata} from '../model/bowker/bowker-metadata';
import {CatalogRecordAvailabilityDetails} from '../model/catalog-record/catalog-record-availability-details';
import {PatronService} from './patron.service';
import {Page} from '../model/list/page';
import {Sort} from '../model/list/sort';
import {CatalogQuery} from '../model/catalog-search/catalog-query';
import {CatalogFacets} from '@raven';

@Injectable({
  providedIn: 'root',
})
export class CatalogRecordService {

  constructor(private environment: Environment,
              private http: HttpClient,
              private patronService: PatronService,
              private notificationService: NotificationService) {
  }

  elasticSearch(page: Page, query: CatalogQuery, filters: CatalogFacets, sort: Sort) {
    const state = {
      page: page.page,
      pageSize: page.limit,
      query: query.query,
      searchType: query.queryType,
      filters: filters,
      sortBy: sort?.column,
      direction: sort?.direction,
    } as CatalogSearchState;
    const url = `${this.environment.apiUrl}/v1/catalog-records/elastic-search`;
    return this.http.post<CatalogSearchResponse>(url, state).pipe(
      catchError((error: unknown) => {
        console.log('Error message', error)
        this.notificationService.showSnackbarError('Error looking up your search results. Please contact customer support.');
        return EMPTY;
      })
    )
  }

  getById(catalogRecordId: number): Observable<CatalogRecord> {
    const url = `${this.environment.apiUrl}/v1/catalog-records/${catalogRecordId}`;

    if (!catalogRecordId) {
      return of(null);
    }

    return this.http.get<CatalogRecord>(url).pipe(
      catchError(() => {
        this.notificationService.showSnackbarError(
          'Unable to load catalog record'
        );
        return of(null);
      })
    );
  }

  getAvailabilityDetails(catalogRecordId: number): Observable<CatalogRecordAvailabilityDetails> {
    const url = `${this.environment.apiUrl}/v1/catalog-records/${catalogRecordId}/availability`;
    if (!catalogRecordId) {
      return of(null);
    }

    return this.http.get<CatalogRecordAvailabilityDetails>(url).pipe(
      catchError(() => {
        return of(null);
      })
    );
  }

  getPopularTitles(): Observable<ResponseBase<CatalogRecord>> {
    const url = `${this.environment.apiUrl}/v1/catalog-records/popular-titles`;
    return this.http.get<ResponseBase<CatalogRecord>>(url);
  }

  getRelatedTitles(catalogRecordId: number): Observable<CatalogRecord[]> {
    const url = `${this.environment.apiUrl}/v1/catalog-records/${catalogRecordId}/related`;
    if (!catalogRecordId) {
      return of([]);
    }
    return this.http.get<CatalogRecord[]>(url).pipe(
      catchError(() => {
        return of([]);
      })
    );
  }

  getRecentlyAdded(): Observable<ResponseBase<CatalogRecord>> {
    const url = `${this.environment.apiUrl}/v1/catalog-records/recently-added`;
    return this.http.get<ResponseBase<CatalogRecord>>(url);
  }

  getPopularAuthors(): Observable<ResponseBase<string>> {
    const url = `${this.environment.apiUrl}/v1/catalog-records/popular-authors`;
    return this.http.get<ResponseBase<string>>(url);
  }

  getOnOrder(): Observable<ResponseBase<CatalogRecord>> {
    const url = `${this.environment.apiUrl}/v1/catalog-records/on-order`;
    return this.http.get<ResponseBase<CatalogRecord>>(url);
  }

  getMetadata(identifier: string): Observable<BowkerMetadata> {
    if (!identifier) {
      return of({} as BowkerMetadata);
    }
    const url = `${this.environment.apiUrl}/v1/catalog-records/bowker-metadata?identifier=${identifier}`;
    const metadata$ = this.http.get<BowkerMetadata>(url)
    return metadata$.pipe(
      catchError(() => {
        return of({} as BowkerMetadata);
      }),
    );
  }

  search(organizationId: number, term: string, status: string[], page: number, limit: number): Observable<ResponseBase<CatalogRecord>> {
    // define these and add to them if needed
    let statuses = '';

    // check for statuses
    if (status && status.length > 0) {
      // join the statues
      statuses = status.join(',');
    }
    // make the call
    return this.http
      .get<ResponseBase<CatalogRecord>>(
        `${this.environment.apiUrl}/organizations/${organizationId}/catalog?q=${term}&status=${statuses}&page=${page}&limit=${limit}`)
      .pipe(
        map((results: ResponseBase<CatalogRecord>) => {
          return results;
        }),
        tap((results) => console.log('search results are', results))
      );
  }
}
