import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {ExpansionPanelComponent} from './expansion-panel/expansion-panel.component';
import {BackgroundAccentComponent} from './background-accent/background-accent.component';
import {BookCarouselComponent} from './book-carousel.component';
import {GridCarouselComponent} from './grid-carousel.component';
import {FallbackImageComponent} from './fallback-image/fallback-image.component';
import {BookCoverComponent} from '../favorites/book-cover/book-cover.component';
import {CarouselComponent, CarouselItemDirective, ConfirmationDialogComponent, NonBreakingPipe, PagerComponent,} from '@raven';
import {FullCalendarModule} from '@fullcalendar/angular';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {MatNativeDateModule, MatOptionModule, MatRippleModule,} from '@angular/material/core';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MomentModule} from 'ngx-moment';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {DropzoneModule} from 'ngx-dropzone-wrapper';
import {GalleryModule} from 'ng-gallery';
import {ColorPickerModule} from 'ngx-color-picker';
import {CustomPluralPipe} from './pipes/plural.pipe';
import {MaterialTypeIconComponent} from './material-type-icon/material-type-icon.component';
import {GridItemComponent} from './grid-item/grid-item.component';
import {ListItemComponent} from './list-item/list-item.component';
import {BarcodeComponent} from "./barcode.component";

// FullCalendarModule.registerPlugins([
//   dayGridPlugin,
//   timeGridPlugin,
//   listPlugin,
//   rrulePlugin,
//   resourceTimelinePlugin,
// ]);

@NgModule({
  declarations: [
    ExpansionPanelComponent,
    BackgroundAccentComponent,
    BookCarouselComponent,
    GridCarouselComponent,
    FallbackImageComponent,
    BookCoverComponent,
    ConfirmationDialogComponent,
    NonBreakingPipe,
    CarouselItemDirective,
    CarouselComponent,
    CustomPluralPipe,
    PagerComponent,
    MaterialTypeIconComponent,
    GridItemComponent,
    ListItemComponent,
    BarcodeComponent
  ],
  exports: [
    ExpansionPanelComponent,
    BackgroundAccentComponent,
    BookCarouselComponent,
    GridCarouselComponent,
    FallbackImageComponent,
    BookCoverComponent,
    ConfirmationDialogComponent,
    NonBreakingPipe,
    CarouselItemDirective,
    CarouselComponent,
    CustomPluralPipe,
    PagerComponent,
    // angular
    BrowserAnimationsModule,
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
    GalleryModule,
    // angular material stuff
    MatNativeDateModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatOptionModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    // moment.js
    MomentModule,
    NgxChartsModule,
    // CDKs
    DragDropModule,
    NgxDropzoneModule,
    DropzoneModule,
    // Forms
    ColorPickerModule,
    MaterialTypeIconComponent,
    GridItemComponent,
    ListItemComponent,
    BarcodeComponent
  ],
  imports: [
    // angular
    BrowserAnimationsModule,
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
    // angular material stuff
    MatNativeDateModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatOptionModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    // moment.js
    MomentModule,
    NgxChartsModule,
    // CDKs
    DragDropModule,
    NgxDropzoneModule,
    DropzoneModule,
    // Full Calender
    FullCalendarModule,
    FlexModule,
  ],
})
export class SharedModule {
}
