import {ItemRequestResponse} from './item-request-response';
import {GoogleCatalogRecord} from '../google-catalog-record';

export class ItemRequest {
  id?: number;
  organizationId: number;
  googleId?: string;
  title: string;
  isbn10?: string;
  isbn13?: string;
  author?: string;
  director?: string;
  publicationDate?: string;
  releaseDate?: string;
  requestType: ITEM_REQUEST_TYPE;
  status: ITEM_REQUEST_STATUS;
  commentCount: number;
  requestCount: number;
  withdrawnCount: number;

  googleCatalogRecord?: GoogleCatalogRecord;
  itemRequestResponses?: ItemRequestResponse[];
}

export enum ITEM_REQUEST_TYPE {
  PURCHASE = 'PURCHASE',
  INTER_LIBRARY_LOAN = 'INTER_LIBRARY_LOAN',
  EITHER = 'EITHER',
}

export enum ITEM_REQUEST_STATUS {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  REVIEW = 'REVIEW',
}
