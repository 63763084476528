import {AfterContentChecked, Component, ContentChild, ContentChildren, ElementRef, Input, QueryList, TemplateRef, ViewChild,} from '@angular/core';
import {CarouselItemDirective} from './carousel-item.directive';
import {animate, AnimationBuilder, AnimationFactory, AnimationPlayer, style,} from '@angular/animations';

@Component({
  selector: 'carousel',
  template: `
    <div>
      <div class="carousel-title-bar">
        <div class="mat-display-2" style="display: inline-block; margin-bottom: 2vh">
          {{ title }}
          <img alt="arrow-right" src="/assets/chevron-bottom.svg" class="chevron hide-xs"/>
        </div>
        <div class="mat-subheading-1 carousel-view-all hide-gt-xs">
          View all
          <img alt="" src="/assets/chevron-bottom.svg" class="small-chevron">
        </div>
        <div *ngIf="showTopButtons && numPages() > 0" class="carousel-top-buttons hide-xs">
          <button mat-raised-button color="primary" class="ButtonSmallBlack" [disabled]="!canScrollLeft" (click)="prev()">
            <img alt="arrow-right" src="/assets/ui-elements-arrow-left.svg" class="arrow"/>
          </button>
          <button mat-raised-button color="primary" class="ButtonSmallBlack" [disabled]="!canScrollRight" (click)="next()">
            <img alt="arrow-right" src="/assets/ui-elements-arrow-right.svg" class="arrow"/>
          </button>
        </div>
      </div>
      <section class="carousel-wrapper" #wrapper (scroll)="onScroll()">
        <ul class="carousel-inner" #carousel>
          <li *ngFor="let item of items" class="carousel-item">
            <ng-container [ngTemplateOutlet]="item.tpl"></ng-container>
          </li>
        </ul>
      </section>
      <ng-container *ngIf="numItems() === 0" [ngTemplateOutlet]="emptyTemplateRef"></ng-container>
      <div *ngIf="showBottomPageDots" class="carousel-button-box">
        <a *ngFor="let item of [].constructor(numPages()); let i = index" (click)="clickPage(i)"
           [class]="isPage(i) ? 'carousel-button-selected' : 'carousel-button'">
          <span>{{ i }}</span>
        </a>
      </div>
    </div>
  `,
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements AfterContentChecked {
  @ContentChildren(CarouselItemDirective)
  items: QueryList<CarouselItemDirective>;
  @ContentChild('emptyTemplate') emptyTemplateRef: TemplateRef<any>;
  @ViewChild('carousel') private carousel: ElementRef;
  @Input() title = '';
  @Input() timing = '250ms ease-in';
  @Input() showTopButtons = true;
  @Input() showBottomPageDots = false;
  @Input() slidesPerPage = 6;
  @Input() itemWidth = 200;
  private player: AnimationPlayer;
  currentSlide = 0;
  @ViewChild('wrapper') private wrapper: ElementRef;
  public canScrollLeft = false;
  public canScrollRight = true;

  ngAfterContentChecked(): void {
    this.onScroll();
  }

  private buildAnimation(offset) {
    return this.builder.build([
      animate(this.timing, style({transform: `translateX(-${offset}px)`})),
    ]);
  }

  animate(): void {
    const offset = this.currentSlide * this.itemWidth;
    const myAnimation: AnimationFactory = this.buildAnimation(offset);
    this.player = myAnimation.create(this.carousel.nativeElement);
    this.player.play();
  }

  onScroll(): void {
    if (!this.wrapper || !this.wrapper.nativeElement) {
      return;
    }

    const viewWidth = this.wrapper.nativeElement.offsetWidth;
    const xScroll = this.wrapper.nativeElement.scrollLeft;
    const xScrollMax = this.wrapper.nativeElement.scrollWidth;
    this.canScrollRight = (xScroll + viewWidth) < xScrollMax;

    this.canScrollLeft = this.wrapper.nativeElement.scrollLeft > 0;

    //approx correct in case the page buttons return
    this.currentSlide = Math.floor((xScroll + viewWidth) / this.itemWidth);
  }

  calcScrollPosition(scrollRight: boolean): number {
    if (!this.wrapper || !this.wrapper.nativeElement) {
      return 0;
    }

    const viewWidth = this.wrapper.nativeElement.offsetWidth;
    const xScroll = this.wrapper.nativeElement.scrollLeft;

    if (scrollRight) {
      const lastFullVisible = Math.floor((xScroll + viewWidth) / this.itemWidth);
      return lastFullVisible * this.itemWidth;
    } else {
      const firstFullVisible = Math.ceil(xScroll / this.itemWidth);
      return (firstFullVisible * this.itemWidth) - viewWidth;
    }
  }

  next(): void {
    const newScrollPos = this.calcScrollPosition(true);
    this.wrapper.nativeElement.scroll({top: 0, left: newScrollPos, behavior: 'smooth'});
  }

  prev(): void {
    const newScrollPos = this.calcScrollPosition(false);
    this.wrapper.nativeElement.scroll({top: 0, left: newScrollPos, behavior: 'smooth'});
  }

  numItems(): number {
    return this.items?.length ? this.items.length : 0;
  }

  numPages(): number {
    return Math.ceil(this.numItems() / this.slidesPerPage);
  }

  clickPage(index: number): void {
    const slide = index * this.slidesPerPage;
    if (this.currentSlide === slide) return;
    this.currentSlide = slide;
    this.animate();
  }

  isPage(index: number): boolean {
    const slide = Math.floor(this.currentSlide / this.slidesPerPage);
    return slide == index;
  }

  constructor(private builder: AnimationBuilder) {
  }
}
