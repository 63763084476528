import {NgModule} from '@angular/core';
import {FavoritesComponent} from './favorites.component';
import {FavoritesGridComponent} from './favorites-grid/favorites-grid.component';
import {PagingControlComponent} from './paging-control/paging-control.component';
import {PageSizeSelectComponent} from './components/page-size-select.component';
import {GridListSelectComponent} from './components/grid-list-select.component';
import {MatIconModule} from '@angular/material/icon';
import {SharedModule} from '../shared/shared.module';

@NgModule({
  imports: [MatIconModule, SharedModule],
  exports: [FavoritesComponent, PagingControlComponent],
  declarations: [
    FavoritesGridComponent,
    FavoritesComponent,
    PagingControlComponent,
    PageSizeSelectComponent,
    GridListSelectComponent,
  ],
})
export class FavoritesModule {
}
