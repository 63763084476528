import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as cloneDeep from 'lodash/cloneDeep';
import {PhoneValidators} from 'ngx-phone-validators';
import moment from 'moment';
import {AuthService, NotificationService, Patron, PATRON_PHONE_TYPE, PatronService} from '@raven';

@Component({
  selector: 'rn-registration-notification',
  templateUrl: './registration-notification-pref.component.html',
  styleUrls: ['./registration-notification-pref.component.scss'],
})
export class RegistrationNotificationPrefComponent implements OnInit {

  patron: Patron;
  notificationPreferencesForm: FormGroup;
  isLoading: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private patronService: PatronService,
    private notificationService: NotificationService
  ) {
  }

  ngOnInit(): void {
    // set loading
    this.isLoading = true;
    // clone the patron as the auth patron is readonly
    this.patron = cloneDeep(this.patronService.patron);
    this.createForm();
    this.isLoading = false;
  }

  createForm(): void {
    this.notificationPreferencesForm = this.fb.group({
      byEmail: [false, {validators: [Validators.required]}],
      bySMS: [false, {validators: [Validators.required]}],
      phoneNumber: [
        this.patron.phone1,
        [PhoneValidators.isPossibleNumberWithReason('US')],
      ],
    });
    this.notificationPreferencesForm.get("phoneNumber").valueChanges.subscribe(phone => {
      // get only the numeric characters
      phone = phone.replace(/\D/g, '');
      // the formcontrol.valueChanges fires before the values on the form object update, allowing us to get the previous value
      const oldValue = this.notificationPreferencesForm.value.phoneNumber.replace(/\D/g, '');
      if (phone.length != 10 && phone == oldValue) {
        // this allows the user to backspace without automatically re-adding characters
        return;
      }
      // format to (###) ###-####
      let newPhone = `(${phone.slice(0, 3)}`;
      if (phone.length > 2) {
        newPhone += `) ${phone.slice(3, 6)}`;
      }
      if (phone.length > 5) {
        newPhone += `-${phone.slice(6, 10)}`;
      }
      this.notificationPreferencesForm.get("phoneNumber").setValue(newPhone, {emitEvent: false});
    });
  }

  toggleNotification(field: string): void {
    this.notificationPreferencesForm
      .get(field)
      .setValue(!this.notificationPreferencesForm.value[field]);
  }

  isFormValid(): boolean {
    const formValues = this.notificationPreferencesForm.value;
    if (
      !this.notificationPreferencesForm.valid ||
      (formValues.bySMS && !formValues.phoneNumber)
    ) {
      return false;
    }
    return formValues.byEmail || formValues.bySMS;
  }

  getErrorMessage(fieldName: string, field: AbstractControl): string {
    if (!field) {
      return '';
    }
    // at exactly 7 digits we don't get 'phoneNumberTooShort' and always get 'noPhoneNumber' which is unhelpful
    // could not find any valid 7 digit phone numbers, it seems there's a way to include an area code separately
    // for our case, we'll cover this specific case with the standard 'phoneNumberTooShort' message
    if (field.value?.replace(/\D/g, '')?.length == 7) {
      return `${this.capitalize(fieldName)} is too short, please use 10 digits (including area code).`;
    }
    if (field.hasError('required') || field.hasError('noPhoneNumber')) {
      return `Please enter your ${fieldName}.`;
    }
    if (field.hasError('phoneNumberTooLong')) {
      return `${this.capitalize(fieldName)} is too long, please use 10 digits (including area code).`;
    }
    if (field.hasError('phoneNumberTooShort')) {
      return `${this.capitalize(fieldName)} is too short, please use 10 digits (including area code).`;
    }
    // we don't want to get here, this doesn't tell the user how to fix the error
    return `Please enter a valid ${fieldName}.`;
  }

  capitalize(str: string): string {
    if (!str) {
      return '';
    }
    return str.slice(0, 1).toUpperCase() + str.slice(1);
  }

  submit(): void {
    if (!this.isFormValid()) {
      return;
    }
    const formValues = this.notificationPreferencesForm.value;
    this.patronService
      .registrationSelectNotifications({
        byEmail: formValues.byEmail,
        bySms: formValues.bySMS,
        phone: formValues.phoneNumber ? formValues.phoneNumber : null,
        phoneType: formValues.phoneNumber ? PATRON_PHONE_TYPE.MOBILE : null,
      }, {
        errorMessage: 'Error updating your communication preferences. Please contact customer support.',
        successMessage: 'Preferences updated successfully',
      })
      .subscribe(() => {
        // check age
        const d = moment(this.patron.dateOfBirth, 'YYYY-M-D');
        const age = Math.floor(moment.duration(moment().diff(d)).years());
        if (age < 18) {
          return this.router.navigate(['/register/in-person']);
        }
        // The route config has been re-organized and navigating to /dashboard doesn't work
        // I'm not sure why, I think "navigate" is a little more complicated than just "goto" route
        // Just going to '/' works for now, authenticated users (which we are) get forwarded to /dashboard
        // return this.router.navigate(['/']);
        // navigating directly to /dashboard seems to work now afaict
        return this.router.navigate(['/dashboard']);
      });
  }
}
