import {Injectable} from '@angular/core';
import {EMPTY, Observable, of, shareReplay} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {HttpErrorResponse} from "@angular/common/http";
import {NotificationService} from "@raven";

export interface ServiceOptions<T> {
  returnOnError?: T;
  returnOnSuccess?: T;
  successMessage?: string,
  errorMessage?: string | { [key: string]: OnError };
  onSuccess?: (result: T) => void;
}

type OnError = string | ((err) => void);

@Injectable({
  providedIn: 'root'
})
export class ServiceUtilService {

  constructor(
    private notificationService: NotificationService,
  ) {
  }

  httpInvoker<T>(obs: Observable<T>, options: ServiceOptions<T> = {}): Observable<T> {
    let result = obs.pipe(
        shareReplay({bufferSize: 1, refCount: true}),
        map(v => options.returnOnSuccess ?? v),
        tap(v => {
          options.successMessage && this.notificationService.showSnackbarSuccess(options.successMessage);
          options.onSuccess && options.onSuccess(v);
        })
      );

    if (options.errorMessage) {
      result = result.pipe(
        catchError((err: unknown) => {
          if (typeof (options.errorMessage) === 'string') {
            this.notificationService.showSnackbarError(options.errorMessage);
          } else if (err instanceof HttpErrorResponse) {
            this.handleHttpErrorCodes(err, options);
          }

          return options.returnOnError !== undefined ? of(options.returnOnError) : EMPTY;
        })
      );
    }

    result.subscribe();
    return result;
  }

  handleHttpErrorCodes<T>(err: HttpErrorResponse, options: ServiceOptions<T> = {}) {
    const
      errCode = err.status,
      onError = options.errorMessage[errCode] || options.errorMessage['default'],
      handlerType = typeof (onError);

    if (handlerType === 'string') {
      this.notificationService.showSnackbarError(onError);
    } else if (handlerType === 'function') {
      onError(err);
    } else {
      console.error('** ServiceUtil received unknown error options! **');
    }
  }
}
