import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Subject, takeUntil} from 'rxjs';
import {map} from 'rxjs/operators';
import {HOLD_STATUS, ListDataService} from '@raven';

@Component({
  selector: 'rn-hold-status-filter',
  template: `
    <div class="form-wrapper">
      <form class="activityFilters" [formGroup]="holdStatusForm">
        <mat-form-field appearance="outline">
          <mat-select formControlName="holdStatus">
            <mat-option value="">All Holds</mat-option>
            <mat-option value="{{HOLD_STATUS.PULLED}}">Ready for pickup</mat-option>
            <mat-option value="{{HOLD_STATUS.TRANSIT}}">{{"In Transit" | titlecase}}</mat-option>
            <mat-option value="{{HOLD_STATUS.REQUESTED}}">{{HOLD_STATUS.REQUESTED | titlecase}}</mat-option>
            <mat-option value="{{HOLD_STATUS.PAUSED}}">{{HOLD_STATUS.PAUSED | titlecase}}</mat-option>
            <mat-option value="{{HOLD_STATUS.EXPIRED}}">{{HOLD_STATUS.EXPIRED | titlecase}}</mat-option>
            <mat-option value="{{HOLD_STATUS.CANCELED}}">{{HOLD_STATUS.CANCELED | titlecase}}</mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
  `,
  styles: [
    `
        div > form > mat-form-field.mat-form-field {
            margin-top: 0;
            max-width: 160px;
        }

        .form-wrapper {
            margin-left: auto;
        }

        .activityFilters mat-form-field:last-child {
            margin-right: 0;
        }
    `,
  ],
})
export class HoldStatusFilterComponent implements OnInit, OnDestroy {

  public holdStatusForm: FormGroup;
  readonly HOLD_STATUS = HOLD_STATUS;

  destroy$ = new Subject<boolean>();

  constructor(public listDataService: ListDataService, private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.holdStatusForm = this.fb.group({
      holdStatus: ['holdStatus', {validators: []}],
    });

    this.holdStatusForm.setValue({holdStatus: ''})

    this.holdStatusForm.valueChanges
      .pipe(map(value => value.holdStatus ? value.holdStatus : null))
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => this.listDataService.filterEvent(value));
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
}
