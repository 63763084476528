<div *ngIf="!isLoading; else loading_spinner">
  <form [formGroup]="personalInfoForm" novalidate>
    <div class="account-header flex-row-wrap flex-center-space-between flex-gap-30">
      <div class="mat-display-1 account-title">Account</div>
    </div>
    <div class="account-row flex-row-wrap-col-responsive">
      <div class="account-column">
        <h3 class="account-subtitle">My Information</h3>
        <mat-label>First Name</mat-label>
        <mat-form-field appearance="outline">
          <input matInput type="text" formControlName="firstName"/>
          <mat-error *ngIf="personalInfoForm.controls['firstName']?.invalid">
            {{getErrorMessage('first name', personalInfoForm.controls['firstName'])}}
          </mat-error>
        </mat-form-field>
        <mat-label>Last Name</mat-label>
        <mat-form-field appearance="outline">
          <input matInput type="text" formControlName="lastName"/>
          <mat-error *ngIf="personalInfoForm.controls['lastName']?.invalid">
            {{getErrorMessage('last name', personalInfoForm.controls['lastName'])}}
          </mat-error>
        </mat-form-field>
        <mat-label>Phone Number</mat-label>
        <mat-form-field appearance="outline">
          <input matInput type="text" formControlName="phone"/>
          <mat-error *ngIf="personalInfoForm.controls['phone']?.invalid">
            {{getErrorMessage('phone number', personalInfoForm.controls['phone'])}}
          </mat-error>
        </mat-form-field>
        <mat-label>Email</mat-label>
        <mat-form-field appearance="outline">
          <input matInput type="text" formControlName="email"/>
        </mat-form-field>
        <a class="pointer" (click)="updateEmail()">Change email</a>
        <br/><br/>
        <mat-label>Password</mat-label>
        <mat-form-field appearance="outline" (click)="updatePassword()">
          <input matInput type="password" formControlName="password"/>
        </mat-form-field>
        <a class="pointer" (click)="updatePassword()">Change password</a>
        <br/><br/>
        <mat-label>PIN&nbsp;&nbsp;
          <em *ngIf="patron.pinGenerated" (click)="$event.stopPropagation()"
              class="ri-information-line" style="font-weight: bold; font-size: 16px"
              matTooltip="Your pin may have been set by a staff member upon account creation, please update/change it here."></em>
        </mat-label>
        <mat-form-field appearance="outline" (click)="updatePin()">
          <input matInput type="password" formControlName="pin"/>
        </mat-form-field>
        <a class="pointer" (click)="updatePin()">Change PIN</a>
      </div>
      <div class="account-column">
        <h3 class="account-subtitle">Address</h3>
        <mat-label>Street Address</mat-label>
        <mat-form-field appearance="outline">
          <input matInput type="text" formControlName="address"/>
          <mat-error *ngIf="personalInfoForm.controls['address']?.invalid">
            {{getErrorMessage('address', personalInfoForm.controls['address'])}}
          </mat-error>
        </mat-form-field>
        <mat-label>Apartment, unit, suite, or floor #</mat-label>
        <mat-form-field appearance="outline">
          <input matInput type="text" formControlName="address2"/>
          <mat-error *ngIf="personalInfoForm.controls['address2']?.invalid">
            {{getErrorMessage('this field', personalInfoForm.controls['address2'])}}
          </mat-error>
        </mat-form-field>
        <mat-label>City</mat-label>
        <mat-form-field appearance="outline">
          <input matInput type="text" formControlName="city"/>
          <mat-error *ngIf="personalInfoForm.controls['city']?.invalid">
            {{getErrorMessage('city', personalInfoForm.controls['city'])}}
          </mat-error>
        </mat-form-field>
        <div class="account-state-column">
          <mat-label>State/Province</mat-label>
          <br/>
          <mat-form-field appearance="outline">
            <mat-select formControlName="state">
              <mat-option *ngFor="let state of STATES" [value]="state.value">{{
                  state.label
                }}</mat-option>
            </mat-select>
            <mat-error *ngIf="personalInfoForm.controls['state']?.invalid">
              {{getErrorMessage('state', personalInfoForm.controls['state'])}}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="account-zip-column">
          <mat-label>Zip Code</mat-label>
          <br/>
          <mat-form-field appearance="outline">
            <input matInput type="text" formControlName="zip"/>
            <mat-error *ngIf="personalInfoForm.controls['zip']?.invalid">
              {{getErrorMessage('zip code', personalInfoForm.controls['zip'])}}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="account-column">
        <h3 class="account-subtitle">Library Information</h3>
        <mat-label>Library Card Bar Code</mat-label>
        <mat-form-field appearance="outline">
          <input matInput type="text" formControlName="barcode"/>
        </mat-form-field>
        <mat-label>Home Branch</mat-label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="defaultBranch">
            <mat-option *ngFor="let branch of this.branchSelect"
                        [value]="branch.value">{{ branch.label }}</mat-option>
          </mat-select>
          <mat-error *ngIf="personalInfoForm.controls['defaultBranch']?.invalid">
            {{getErrorMessage('home branch', personalInfoForm.controls['defaultBranch'])}}
          </mat-error>
        </mat-form-field>
        <div class="account-checkbox pointer" [ngClass]="{ checked: personalInfoForm.value.optIn }" (click)="toggleCheckbox('optIn')">
          <span class="ri-check-line"></span>
          <span class="mat-small">Save Reading History</span>
        </div>
        <!--        <mat-label>Theme</mat-label>-->
        <!--        <mat-form-field appearance="outline">-->
        <!--          <mat-select formControlName="theme">-->
        <!--            <mat-option *ngFor="let themeOpt of themeService.allThemes" [value]="themeOpt.value" (click)="themeService.setTheme(themeOpt.value)">-->
        <!--              {{themeOpt.label}}-->
        <!--            </mat-option>-->
        <!--          </mat-select>-->
        <!--        </mat-form-field>-->
      </div>
    </div>
    <div class="clear-for-footer"></div>
    <div class="save-footer mat-elevation-z6">
      <div class="save-footer-content flex-row flex-center-end">
        <div class="spacer"></div>
        <div class="button-row-right">
          <button mat-stroked-button class="ButtonSmallBlack save-button-group button-cancel"
                  (click)="undo()" [disabled]="personalInfoForm.pristine">
            Undo
          </button>
          <button mat-raised-button color="primary" class="ButtonSmallBlack save-button-group save-button"
                  [disabled]="personalInfoForm.pristine || personalInfoForm.invalid" (click)="submit()">
            Save Changes
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
<ng-template #loading_spinner>
  <div class="flex-container">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
