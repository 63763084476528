import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {BehaviorSubject, Observable} from 'rxjs';
import {PatronCardPaymentMethod, PatronPaymentService} from '@raven';
import {AddPaymentMethodDialogComponent} from './components/add-payment-method-dialog/add-payment-method-dialog.component';

@Component({
  selector: 'rn-payment-information',
  templateUrl: './payment-information.component.html',
  styleUrls: ['./payment-information.component.scss'],
})
export class PaymentInformationComponent implements OnInit {

  cardButton: HTMLElement;
  paymentMethods$: Observable<PatronCardPaymentMethod[]>;
  loadingSubject = new BehaviorSubject(true);
  loading$ = this.loadingSubject.asObservable();

  constructor(private http: HttpClient,
              private dialog: MatDialog,
              private patronPaymentService: PatronPaymentService) {
  }

  ngOnInit() {
    this.paymentMethods$ = this.patronPaymentService.getAllPaymentMethods();
  }

  openAddCardDialog(): void {
    this.dialog.open(AddPaymentMethodDialogComponent, {maxWidth: '95vw'});
  }

}
