import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Subject, takeUntil} from 'rxjs';
import {map} from 'rxjs/operators';
import {CHECKOUT_STATUS, ListDataService} from '@raven';

@Component({
  selector: 'rn-checkout-filter',
  templateUrl: './checkout-status-filter.component.html',
  styleUrls: ['./checkout-status-filter.component.scss'],
})
export class CheckoutStatusFilterComponent implements OnInit {

  public checkoutStatusForm: FormGroup;
  readonly CHECKOUT_STATUS = CHECKOUT_STATUS;

  destroy$ = new Subject<boolean>();

  constructor(public listDataService: ListDataService, private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.checkoutStatusForm = this.fb.group({
      checkoutStatus: ['All Items', {validators: []}],
    });

    this.checkoutStatusForm.setValue({checkoutStatus: ''})

    this.checkoutStatusForm.valueChanges
      .pipe(map(value => value.checkoutStatus ? value.checkoutStatus : null))
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => this.listDataService.filter.next(value));
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
}
