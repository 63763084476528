export enum PATRON_NOTIFICATION_TYPE {
  SELF_REGISTRATION_RESIDENT_APPROVED = 'SELF_REGISTRATION_RESIDENT_APPROVED',
  SELF_REGISTRATION_NONRESIDENT_APPROVED = 'SELF_REGISTRATION_NONRESIDENT_APPROVED',
  SELF_REGISTRATION_OUT_OF_BOUNDS_NO_FEE = 'SELF_REGISTRATION_OUT_OF_BOUNDS_NO_FEE',
  SELF_REGISTRATION_INVALID_ADDRESS = 'SELF_REGISTRATION_INVALID_ADDRESS',
  SELF_REGISTRATION_DENIED = 'SELF_REGISTRATION_DENIED',
  CARD_RENEWAL_EXPIRING_SOON = 'CARD_RENEWAL_EXPIRING_SOON',
  CARD_RENEWAL_SUCCESS = 'CARD_RENEWAL_SUCCESS',
  CARD_RENEWAL_FAILED = 'CARD_RENEWAL_FAILED',
  CARD_RENEWAL_EXPIRED = 'CARD_RENEWAL_EXPIRED',
  ACCOUNT_BALANCE_SUSPENSION = 'ACCOUNT_BALANCE_SUSPENSION',
  ACCOUNT_LOST_OR_DAMAGED_ITEMS_SUSPENSION = 'ACCOUNT_LOST_OR_DAMAGED_ITEMS_SUSPENSION',
  ACCOUNT_SUSPENSION_RELEASED = 'ACCOUNT_SUSPENSION_RELEASED',
  ACCOUNT_ACTIVATED = 'ACCOUNT_ACTIVATED',
  MINOR_TO_ADULT_SOON = 'MINOR_TO_ADULT_SOON',
  MINOR_TO_ADULT = 'MINOR_TO_ADULT',
  HOLD_PLACED = 'HOLD_PLACED',
  HOLD_AVAILABLE = 'HOLD_AVAILABLE',
  HOLD_EXPIRE_SOON = 'HOLD_EXPIRE_SOON',
  HOLD_EXPIRE = 'HOLD_EXPIRE',
  HOLD_PAUSED = 'HOLD_PAUSED',
  HOLD_RESUMED = 'HOLD_RESUMED',
  ITEM_REQUEST_SUBMITTED = 'ITEM_REQUEST_SUBMITTED',
  ITEM_REQUEST_DENIED = 'ITEM_REQUEST_DENIED',
  ITEM_REQUEST_APPROVED = 'ITEM_REQUEST_APPROVED',
  ITEM_REQUEST_FULFILLED = 'ITEM_REQUEST_FULFILLED',
  ITEM_REQUEST_STAFF_COMMENT = 'ITEM_REQUEST_STAFF_COMMENT',
  PASSWORD_RESET = 'PASSWORD_RESET',
  PAYMENT_SUBMITTED = 'PAYMENT_SUBMITTED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PAYMENT_DUE = 'PAYMENT_DUE',
  PAYMENT_METHOD_EXPIRED = 'PAYMENT_METHOD_EXPIRED',
  CHECKOUT_OCCURED = 'CHECKOUT_OCCURED',
  CHECKOUT_OVERDUE = 'CHECKOUT_OVERDUE',
  CHECKOUT_OVERDUE_WITH_FINES = 'CHECKOUT_OVERDUE_WITH_FINES',
  CHECKOUT_RENEWED = 'CHECKOUT_RENEWED',
  CHECKOUT_CLAIM = 'CHECKOUT_CLAIM',
  CHECKOUT_CLAIM_FEE_ASSESED = 'CHECKOUT_CLAIM_FEE_ASSESED',
  CHECKOUT_CLAIM_MISSING_MARKED_LOST = 'CHECKOUT_CLAIM_MISSING_MARKED_LOST',
  CHECKOUT_CLAIM_MISSING_LOCATED = 'CHECKOUT_CLAIM_MISSING_LOCATED',
  USER_CREATED = 'USER_CREATED'
}

export class PatronNotification {
  id: number;
  patronId: number;
  notificationType: PATRON_NOTIFICATION_TYPE;
  message: string;
  emailSent: boolean;
  smsSent: boolean;
  viewed: boolean
  created: string;
}

export const PATRON_NOTIFICATION_TITLES = {
  [PATRON_NOTIFICATION_TYPE.SELF_REGISTRATION_RESIDENT_APPROVED]: "Application Approved",
  [PATRON_NOTIFICATION_TYPE.SELF_REGISTRATION_NONRESIDENT_APPROVED]: "Application Approved",
  [PATRON_NOTIFICATION_TYPE.SELF_REGISTRATION_OUT_OF_BOUNDS_NO_FEE]: '',
  [PATRON_NOTIFICATION_TYPE.SELF_REGISTRATION_INVALID_ADDRESS]: '',
  [PATRON_NOTIFICATION_TYPE.SELF_REGISTRATION_DENIED]: '',
  [PATRON_NOTIFICATION_TYPE.CARD_RENEWAL_EXPIRING_SOON]: 'Your account is expiring soon',
  [PATRON_NOTIFICATION_TYPE.CARD_RENEWAL_SUCCESS]: 'Your account has been renewed',
  [PATRON_NOTIFICATION_TYPE.CARD_RENEWAL_FAILED]: 'Your account has expired',
  [PATRON_NOTIFICATION_TYPE.CARD_RENEWAL_EXPIRED]: 'Your account has expired',
  [PATRON_NOTIFICATION_TYPE.ACCOUNT_BALANCE_SUSPENSION]: 'Your account has been suspended',
  [PATRON_NOTIFICATION_TYPE.ACCOUNT_LOST_OR_DAMAGED_ITEMS_SUSPENSION]: 'Your account has been suspended',
  [PATRON_NOTIFICATION_TYPE.ACCOUNT_SUSPENSION_RELEASED]: 'Your account has been restored',
  [PATRON_NOTIFICATION_TYPE.ACCOUNT_ACTIVATED]: 'Your account has been activated',
  [PATRON_NOTIFICATION_TYPE.MINOR_TO_ADULT_SOON]: 'Your account will expire on your birthday',
  [PATRON_NOTIFICATION_TYPE.MINOR_TO_ADULT]: 'Your account has expired',
  [PATRON_NOTIFICATION_TYPE.HOLD_PLACED]: 'Hold placed',
  [PATRON_NOTIFICATION_TYPE.HOLD_AVAILABLE]: 'Hold is available for pickup',
  [PATRON_NOTIFICATION_TYPE.HOLD_EXPIRE_SOON]: 'Hold is about to expire',
  [PATRON_NOTIFICATION_TYPE.HOLD_EXPIRE]: 'Hold has expired',
  [PATRON_NOTIFICATION_TYPE.HOLD_PAUSED]: 'Hold paused',
  [PATRON_NOTIFICATION_TYPE.HOLD_RESUMED]: 'Hold resumed',
  [PATRON_NOTIFICATION_TYPE.ITEM_REQUEST_SUBMITTED]: 'We have received your item request',
  [PATRON_NOTIFICATION_TYPE.ITEM_REQUEST_DENIED]: 'Item request denied',
  [PATRON_NOTIFICATION_TYPE.ITEM_REQUEST_APPROVED]: 'Item request approved',
  [PATRON_NOTIFICATION_TYPE.ITEM_REQUEST_FULFILLED]: 'Item request is available for pickup',
  [PATRON_NOTIFICATION_TYPE.ITEM_REQUEST_STAFF_COMMENT]: 'A staff member has commented on your item request',
  [PATRON_NOTIFICATION_TYPE.PASSWORD_RESET]: 'Your password has been reset',
  [PATRON_NOTIFICATION_TYPE.PAYMENT_SUBMITTED]: 'Payment received',
  [PATRON_NOTIFICATION_TYPE.PAYMENT_FAILED]: 'Payment has failed',
  [PATRON_NOTIFICATION_TYPE.PAYMENT_DUE]: 'Payment due',
  [PATRON_NOTIFICATION_TYPE.PAYMENT_METHOD_EXPIRED]: 'Payment on file is expired',
  [PATRON_NOTIFICATION_TYPE.CHECKOUT_OCCURED]: 'Item(s) have been checked out',
  [PATRON_NOTIFICATION_TYPE.CHECKOUT_OVERDUE]: 'Your checkout is overdue',
  [PATRON_NOTIFICATION_TYPE.CHECKOUT_OVERDUE_WITH_FINES]: 'Your checkout is overdue',
  [PATRON_NOTIFICATION_TYPE.CHECKOUT_RENEWED]: 'Checkout renewed',
  [PATRON_NOTIFICATION_TYPE.CHECKOUT_CLAIM]: 'We have received your checkout claim',
  [PATRON_NOTIFICATION_TYPE.CHECKOUT_CLAIM_FEE_ASSESED]: 'An item you\'ve returned is damaged',
  [PATRON_NOTIFICATION_TYPE.CHECKOUT_CLAIM_MISSING_MARKED_LOST]: 'Your checkout claim item has been marked as lost',
  [PATRON_NOTIFICATION_TYPE.CHECKOUT_CLAIM_MISSING_LOCATED]: 'Item marked as missing has been located',
  [PATRON_NOTIFICATION_TYPE.USER_CREATED]: 'User created'
};
