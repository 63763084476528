import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {Environment} from '../model/environment';
import {ResponseBase} from '../model/response-base/response-base';
import {CirculationItem} from '../model/circulation-item/circulation-item';

@Injectable({
  providedIn: 'root',
})
export class CirculationItemService {
  constructor(private environment: Environment,
              private http: HttpClient) {
  }

  /**
   * GET all circulation Items with organization ID specified
   */
  getForOrganization(organizationId: number, page: number, limit: number): Observable<ResponseBase<CirculationItem>> {
    return this.http.get<ResponseBase<CirculationItem>>(
      `${this.environment.apiUrl}/organizations/${organizationId}/circulation-items?page=${page}&limit=${limit}`);
  }

  /**
   * Get circulation item by status
   * @param organizationId org id
   * @param status ENum value for status on circulation item - this has to match the db value
   * @param page page num
   * @param limit limit value
   */
  getByStatus(organizationId: number, status: string, page: number, limit: number): Observable<ResponseBase<CirculationItem>> {
    const url = `${this.environment.apiUrl}/organizations/${organizationId}/circulation-items?status=${status}&page=${page}&limit=${limit}`;
    return this.http.get<ResponseBase<CirculationItem>>(url);
  }

  /**
   * GET all circulation-items ON-LOAN for organization
   */
  getOnLoanForOrganization(organizationId: number, page: number, limit: number): Observable<ResponseBase<CirculationItem>> {
    return this.http.get<ResponseBase<CirculationItem>>(
      `${this.environment.apiUrl}/organizations/${organizationId}/circulation-items/loaned?page=${page}&limit=${limit}`);
  }

  getOnHoldForOrganization(organizationId: number, page: number, limit: number): Observable<ResponseBase<CirculationItem>> {
    return this.http.get<ResponseBase<CirculationItem>>(
      `${this.environment.apiUrl}/organizations/${organizationId}/circulation-items/on-hold?page=${page}&limit=${limit}`);
  }

  /**
   * GET circulation Items where the name contains the search term
   */
  searchCirculationItemsByName(organizationId: number, term: string): Observable<CirculationItem[]> {
    if (!term.trim()) {
      // if not search term, return empty circulationItem array.
      return of([]);
    }
    return this.http
      .get<CirculationItem[]>(
        `${this.environment.apiUrl}/organizations/${organizationId}/circulation-items/?name=${term}`)
      .pipe(
        tap((_) => console.log(`found circulation Items matching "${term}"`))
      );
  }

  // /**
  //  * GET circulation Items by the parent CatalogRecord/Record id
  //  */
  // getCirculationItemsByParentItemId(
  //   organizationId: number,
  //   parentItemId: number,
  //   page: number,
  //   limit: number
  // ): Observable<ResponseBase<CirculationItem>> {
  //   const url = `${this.environment.apiUrl}/organizations/${organizationId}/circulation-items/parent-id/${parentItemId}?page=${page}&limit=${limit}`;
  //   return this.http
  //     .get<ResponseBase<CirculationItem>>(url, {headers: this.getHeaders()})
  //     .pipe(
  //       tap((_) =>
  //         console.log(
  //           `fetched circulation items by parent item id=${parentItemId}`
  //         )
  //       )
  //     );
  // }

  /**
   * GET withdrawn circulation Items by the parent CatalogRecord/Record id
   */
  getWithdrawnCirculationItemsByParentItemId(organizationId: number, parentItemId: number, page: number, limit: number): Observable<ResponseBase<CirculationItem>> {
    const url = `${this.environment.apiUrl}/organizations/${organizationId}/circulation-items/parent-id/${parentItemId}/withdrawn?page=${page}&limit=${limit}`;
    return this.http
      .get<ResponseBase<CirculationItem>>(url)
      .pipe(
        tap((_) =>
          console.log(
            `fetched circulation items by parent item id=${parentItemId}`
          )
        )
      );
  }

  getCirculationItemByBarcode(organizationId: number, barcode: number): Observable<CirculationItem> {
    const url = `${this.environment.apiUrl}/organizations/${organizationId}/circulation-items/barcode/${barcode}`;
    return this.http
      .get<CirculationItem>(url)
      .pipe(
        tap((_) =>
          console.log(`fetched circulation item by barcode #${barcode}`)
        )
      );
  }

}
