import {Component, Input, OnInit} from '@angular/core';
import {CatalogFacet, CatalogFacets} from '@raven';
import {Subject} from 'rxjs';

@Component({
  selector: 'rn-catalog-facets',
  templateUrl: './catalog-facets.component.html',
  styleUrls: [
    './catalog-facets.component.scss',
    '../../catalog-browse.component.scss',
  ],
})
export class CatalogFacetsComponent implements OnInit {
  @Input() facet: CatalogFacet;
  @Input() showCheckboxes = true;
  @Input() showSelectAll = false;
  @Input() searchTrigger: Subject<boolean>;

  displayCount = 5;

  constructor() {
  }

  ngOnInit(): void {
  }

  notifyChanged() {
    this.searchTrigger.next(true);
  }

  toFilterState(): CatalogFacets {
    const facetCopyAsFilter = {...this.facet};
    facetCopyAsFilter.values = facetCopyAsFilter.values.filter(v => v.selected);
    const r = {};
    if (facetCopyAsFilter.values.length > 0) {
      r[this.facet.name] = facetCopyAsFilter;
    }
    return r;
  }
}
