import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  // {
  //   path: '',
  //   // wrap this in the app layout component, which provides the nav + header + footer
  //   component: AppLayoutComponent,
  //   canActivate: [RegistrationGuard],
  //   canActivateChild: [RegistrationGuard],
  //   children: [
  //     {path: 'register', component: RegistrationAccountCreationComponent},
  //     {path: 'register-existing-card', component: RegisterExistingCardComponent},
  //
  //     {path: 'register/verification', component: RegistrationAccountCreationComponent},
  //     {path: 'register/patron-info', component: RegistrationPersonalInfoComponent, data: {navbarVisible: false}},
  //     {path: 'register/notifications', component: RegistrationNotificationPrefComponent, data: {navbarVisible: false}},
  //     {path: 'register/in-person', component: RegistrationAgeValidationComponent, data: {navbarVisible: false}},
  //   ],
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RegistrationRoutingModule {
}
