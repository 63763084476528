import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

/**
 * PATRON ADDRESS CHANGE DIALOG COMPONENT
 */
@Component({
  selector: 'rn-address-change-dialog',
  template: `
    <div class="dialog-wrapper flex-col">
      <h3>Update Address</h3>
      <div class="flex-col flex-start-start">
              <span class="Text-Style dialog-body">
                Please note that changing your address will trigger an “address validation” process
                to determine if your updated address is within the library’s district. As such your
                status may change from In-District to Out-Of-District or vice versa.
              </span>
        <span class="Text-Style dialog-body">
                Would you like to continue updating your address?
              </span>
      </div>
      <div class="dialog-button-row">
        <button mat-stroked-button class="save-button-group button-cancel" (click)="onNoClick()">
          No
        </button>
        <button mat-raised-button color="primary" class="save-button-group save-button" (click)="onYesClick()">
          Yes, update
        </button>
      </div>
    </div>
  `,
  styles: [`
      .dialog-wrapper {
          width: 650px;
      }

      .dialog-button-row {
          margin-top: 20px;
      }
  `],
})
export class AddressChangeDialog {
  constructor(public dialogRef: MatDialogRef<AddressChangeDialog>) {
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick(): void {
    this.dialogRef.close(true);
  }
}
