import {NgModule} from '@angular/core';
import {OverlayModule} from '@angular/cdk/overlay';
import {SharedModule} from '../shared/shared.module';
import {BalanceAndPayments} from './balance-and-payments/balance-and-payments';
import {InfoComponent} from './balance-and-payments/components/info.component';
import {BarcodePopoverComponent} from './balance-and-payments/components/barcode-popover.component';
import {ContactUsDialogComponent} from './balance-and-payments/components/contact-us-dialog.component';
import {ActivityFilterComponent} from './balance-and-payments/components/activity-filter.component';
import {MakeAPaymentFormComponent} from './balance-and-payments/components/make-a-payment-form.component';
import {AccountStatusContentComponent} from './balance-and-payments/components/account-status-content.component';
import {PaymentInformationComponent} from './payment-information/payment-information.component';
import {PaymentMethodComponent} from './payment-information/components/payment-method/payment-method.component';
import {AddPaymentMethodDialogComponent} from './payment-information/components/add-payment-method-dialog/add-payment-method-dialog.component';
import {PaymentConfirmationDialogComponent} from './balance-and-payments/components/payment-confirmation-dialog/payment-confirmation-dialog.component';
import {PaymentSetupDialogComponent} from './balance-and-payments/components/payment-setup-dialog/payment-setup-dialog.component';

@NgModule({
  imports: [OverlayModule, SharedModule],
  exports: [
    BalanceAndPayments,
    ActivityFilterComponent,
    PaymentInformationComponent,
  ],
  declarations: [
    BalanceAndPayments,
    InfoComponent,
    BarcodePopoverComponent,
    ContactUsDialogComponent,
    ActivityFilterComponent,
    MakeAPaymentFormComponent,
    AccountStatusContentComponent,
    PaymentInformationComponent,
    PaymentMethodComponent,
    AddPaymentMethodDialogComponent,
    PaymentConfirmationDialogComponent,
    PaymentSetupDialogComponent,
  ],
})
export class PatronBillingModule {
}
