import {Injectable, OnDestroy} from '@angular/core';
import {ReplaySubject, Subject} from 'rxjs';
import {CatalogFacets, CatalogQuery, MxConstants, Page, Sort} from '@raven';

@Injectable()
export class CatalogBrowseService implements OnDestroy {


  public pageSubject = new ReplaySubject<Page>(1);
  public page$ = this.pageSubject.asObservable();
  public querySubject = new ReplaySubject<CatalogQuery>(1);
  public query$ = this.querySubject.asObservable();
  public filterSubject = new ReplaySubject<CatalogFacets>(1);
  public filter$ = this.filterSubject.asObservable();
  public sortSubject = new ReplaySubject<Sort>(1);
  public sort$ = this.sortSubject.asObservable();

  page: Page = {page: 0, limit: 25};
  destroy$ = new Subject();

  constructor() {
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  filtersFromLocalStore(): { [key: string]: CatalogFacets } {
    const savedFiltersString = localStorage.getItem(MxConstants.CATALOG_SAVED_FILTERS_KEY);
    if (savedFiltersString) {
      return JSON.parse(savedFiltersString);
    } else {
      return {};
    }
  }

  filtersToLocalStore(filters: { [key: string]: CatalogFacets }) {
    localStorage.setItem(MxConstants.CATALOG_SAVED_FILTERS_KEY, JSON.stringify(filters));
  }

  updateFilters(filters: CatalogFacets) {
    this.filterSubject.next(filters);
  }

  updateQuery(query: CatalogQuery) {
    this.setPage(0);
    this.updateFilters({});
    //could reset sort here also, current Chatty site doesn't though
    this.querySubject.next(query);
  }

  updateSort(sort: Sort){
    this.setPage(0);
    this.sortSubject.next(sort);
  }

  setPage(page: number) {
    this.page = {limit: this.page.limit, page: page};
    this.pageSubject.next(this.page);
  }

  setPageLimit(limit: number) {
    this.page = {limit: limit, page: this.page.page};
    this.pageSubject.next(this.page);
  }

  setPageAndLimit(page: number, limit: number) {
    this.page = {page: page, limit: limit};
    this.pageSubject.next(this.page);
  }

}
