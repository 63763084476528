import {Component} from '@angular/core';

@Component({
  selector: 'rn-registration-side-panel',
  template: `
    <div class="register-panel-wrapper flex-1">
      <div class="register-img"></div>
      <div>
        <div class="register-title">
          We'll make your library experience simple!
        </div>
        <div class="register-checkmarks">
          <mat-icon>checkmark</mat-icon>
          <span>We make it easy to put books on hold.</span>
        </div>
        <br/>
        <div class="register-checkmarks">
          <mat-icon>checkmark</mat-icon>
          <span
            >You can quickly pay for late fees by adding a credit card.</span
          >
        </div>
        <br />
        <div class="register-checkmarks">
          <mat-icon>checkmark</mat-icon>
          <span
            >Lost your library card? No problem, quickly access library card and
            bar code here.</span
          >
        </div>
        <br />
        <div class="register-checkmarks">
          <mat-icon>checkmark</mat-icon>
          <span
            >Our responsive design means you can access your library account
            anywhere.</span
          >
        </div>
        <br />
      </div>
    </div>
  `,
  styles: [
    `
      @media screen and (max-width: 1279px) {
        .register-panel-wrapper {
          display: none;
        }
      }

      .register-panel-wrapper {
        height: 100%;
        background-color: #e2e4e1;
      }

      .register-img {
        width: 710px;
        height: 313px;
        background-image: url('https://storage.googleapis.com/millonex-assets/img/registration/registration@2x.png');
        background-size: 710px 313px;
        background-repeat: no-repeat;
        background-position: right;
      }

      .register-title {
        width: 580px;
        height: 120px;
        padding: 58px 0 36px 130px;
        font-family: P22Mackinac-Medium_6, serif;
        font-size: 48px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: -1.2px;
        color: #4f4949;
      }

      .register-checkmarks {
        width: 430px;
        margin-left: 130px;
        font-family: Maax, Roboto, Helvetica Neue, sans-serif;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: #202828;
      }

      .register-checkmarks > mat-icon {
        color: #007bbd;
        float: left;
        margin-right: 13px;
        display: inline;
      }

      .register-checkmarks > span {
        display: inline-block;
        width: 364px;
      }
    `,
  ],
})
export class RegistrationSidePanelComponent {
}
