export abstract class Environment {
  abstract readonly name: string;
  abstract readonly app: string;
  abstract readonly production: boolean;
  abstract readonly webUrl: string;
  abstract readonly apiUrl: string;
  abstract readonly graphUrl?: string;
  abstract readonly loginUrl: string;
  abstract readonly snackBarTimeout: number;
  abstract readonly schedulerLicenseKey: string;
  abstract readonly firebase: object;
  abstract readonly skipFireAuth: boolean;
  abstract readonly enableSentry: boolean;
  abstract readonly squareWebSdkUrl?: string;
}
