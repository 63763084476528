import {BreakpointObserver, Breakpoints, BreakpointState} from "@angular/cdk/layout";
import {Component, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {Router} from '@angular/router';
import {EMPTY, Observable, share, Subject, switchMap, takeUntil} from 'rxjs';
import {map} from 'rxjs/operators';
import moment from 'moment';
import {AuthService, ITEM_REQUEST_STATUS, ItemRequestService, NotificationService, PatronItemRequest, PatronItemRequestService} from '@raven';
import {DeleteItemRequestDialog} from "../dialogs/delete-item-request-dialog";

@Component({
  selector: 'rn-item-request-list',
  templateUrl: './item-request-list.component.html',
  styleUrls: ['./item-request-list.component.scss'],
})
export class ItemRequestListComponent implements OnInit {
  ITEM_REQUEST_STATUS = ITEM_REQUEST_STATUS;
  data$: Observable<any>;
  destroy$ = new Subject<boolean>();
  isMobile = false;
  desktopColumns = [
    'comment',
    'title',
    'author',
    'publicationDate',
    'requestDate',
    'status',
    'actions',
  ];
  mobileColumns = [
    'comment',
    'mobile',
    'actions'
  ]
  columnsToDisplay = this.desktopColumns;

  constructor(private authService: AuthService,
              private breakpointObserver: BreakpointObserver,
              private itemRequestService: ItemRequestService,
              private notificationService: NotificationService,
              private patronItemRequestService: PatronItemRequestService,
              private dialog: MatDialog,
              private router: Router) {
  }

  ngOnInit(): void {
    this.getData();
    this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(takeUntil(this.destroy$))
      .subscribe((breakpointState: BreakpointState) => {
        if (breakpointState.matches) {
          this.isMobile = true;
          this.columnsToDisplay = this.mobileColumns;
        } else {
          this.isMobile = false;
          this.columnsToDisplay = this.desktopColumns;
        }
      });
  }

  getData(): void {
    this.data$ = this.patronItemRequestService
      .getAllPatronRequests()
      .pipe(
        map((requests) => {
          return requests.map(this.extractViewFields);
        })
      )
      .pipe(
        map((requests) => {
          return {
            activeRequests: requests.filter((r) => r['status'] !== 'CLOSED'),
            closedRequests: requests.filter((r) => r['status'] === 'CLOSED'),
          };
        })
      )
      .pipe(share());
  }

  extractViewFields(patronItemRequest: PatronItemRequest): object {
    return {
      id: patronItemRequest.id,
      title: patronItemRequest.itemTitle,
      author: patronItemRequest.author,
      publicationDate: patronItemRequest.publicationDate ? moment(patronItemRequest.publicationDate) : moment.invalid(),
      requestDate: moment(patronItemRequest.requestDate),
      status: patronItemRequest.status,
      hasUnreadStaffComments: patronItemRequest.hasUnreadStaffComments,
    };
  }

  renderMidHeader(index, data) {
    return data === 'CLOSEDHEADER';
  }

  goToRequest(request: object) {
    this.router.navigate(['/item-request-details', request['id']]);
  }

  deleteItemRequest(request: PatronItemRequest): void {
    const dialogRef = this.dialog.open(DeleteItemRequestDialog, {
      data: {itemRequests: [request], cantDelete: 0},
      maxWidth: '95vw',
    });
    dialogRef
      .afterClosed()
      .pipe(
        switchMap((deleteConfirmation: boolean) => {
          if (deleteConfirmation) {
            return this.patronItemRequestService.cancel(request.id);
          }
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.notificationService.showSnackbarSuccess(
          'Request successfully deleted'
        );
        this.getData();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
}
