import {PatronAccount} from './patron-account';
import {CheckoutSession} from '@raven';
import {PatronGuarantor} from './patron-guarantor';
import {PatronAddress} from './patron-address';
import {PatronCategory} from './patron-category';
import {PatronRule} from './patron-rule';

export enum PATRON_STATUS {
  NEW = 'NEW',
  DENIED = 'DENIED',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  SUSPENDED = 'SUSPENDED',
}

export enum PATRON_EMAIL_TYPE {
  BUSINESS = 'BUSINESS',
  HOME = 'HOME',
  OTHER = 'OTHER',
}

export enum PATRON_PHONE_TYPE {
  HOME = 'HOME',
  MOBILE = 'MOBILE',
}

export enum PATRON_STANDING {
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
  SUSPENDING = 'SUSPENDING',
  COLLECTIONS = 'COLLECTIONS',
  PAYMENT_PLAN = 'PAYMENT_PLAN',
}

export class Patron {
  id?: number;
  googleUid?: string;
  organizationId?: number;
  branchId?: number;
  patronCategoryId?: number;
  status?: PATRON_STATUS = PATRON_STATUS.NEW;
  email?: string;
  barcode?: string;
  displayName?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  preferredName?: string;
  photoURL?: string;
  pinCode?: string;
  password?: string;
  initials?: string;
  salutation?: string;
  gender?: string;
  dateOfBirth?: string;
  accountExpiration?: string;
  preferredLanguage?: string;
  pinGenerated?: boolean;
  legalsAccepted?: boolean;
  signedLegalURI?: string;

  phone1?: string;
  phone1Type?: PATRON_PHONE_TYPE;

  address?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipCode?: string;

  emailVerified?: boolean;
  mobileNumberVerified?: boolean;
  optInReadingHistory?: boolean;
  unsubscribeAllNotifications?: boolean;

  timezone?: string;
  passwordResetToken?: string;
  tokenExpiration?: string;
  lastLogin?: string;

  addresses?: PatronAddress[];
  category?: PatronCategory;
  guarantors?: PatronGuarantor[];
  //  ledgers?: PatronLedger[];

  account?: PatronAccount;
  checkoutSession?: CheckoutSession;
  patronRule?: PatronRule;
  accountBalance?: number;
  maxHolds?: number;
  patronStatistics?: PatronStatistics;
  // for history tab
  lastLocalAccess?: string;

  hasNotificationPreferences?: boolean;
  preventCheckout?: boolean;
  underBalanceLimit?: boolean;
}

export class PatronStatistics {
  openHolds?: number = 0;
  openLoans?: number = 0;
  claimedReturnedCount?: number = 0;
}

export class PatronAlert {
  restrictions: string[] = [];
  warnings: string[] = [];
  // Count of issues that are worth noting to staff
  insightCount?: number = 0;
  restrictCheckout = false;
  restrictHolds = false;
  restrictRenew = false;
  restrictReasons: Array<string> = [];
}

export enum PATRON_RESTRICT_REASON {
  MAX_FEE_LIMIT = 'MAX_FEE_LIMIT',
  ACCOUNT_EXPIRED = 'ACCOUNT_EXPIRED',
}
