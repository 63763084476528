import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CirculationItemClaimedReturned, RoutesService} from '@raven';

/**
 * CLAIM RETURNED DIALOG COMPONENT
 */
@Component({
  selector: 'rn-claim-returned-dialog',
  template: `
    <div class="claim-dialog flex-col">
      <div class="claim-header flex-row flex-start-space-between">
        <div class="claim-title mat-display-2">Submit Claim</div>
        <button mat-button class="close-icon claim-x" [mat-dialog-close]="true">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="claim-header flex-col">
        <div class="claim-details flex-row-wrap">
          <div class="claim-header-field flex-row flex-1" style="max-width: 50%">
            <div class="flex-1 mat-small claim-details-label">Title:</div>
            <div class="flex-1 mat-small"><strong>{{data.title}}</strong></div>
          </div>
          <div class="claim-header-field flex-row flex-1" style="max-width: 50%">
            <div class="flex-1 mat-small claim-details-label">ISBN:</div>
            <div class="flex-1 mat-small"><strong>{{data.isbn}}</strong></div>
          </div>
        </div>
        <div class="claim-details flex-row-wrap">
          <div class="claim-header-field flex-row flex-1" style="max-width: 50%">
            <div class="flex-1 mat-small claim-details-label">Author:</div>
            <div class="flex-1 mat-small"><strong>{{data.author}}</strong></div>
          </div>
          <div class="claim-header-field flex-row flex-1" style="max-width: 50%">
            <div class="flex-1 mat-small claim-details-label">Bar Code:</div>
            <div class="flex-1 mat-small"><strong>{{data.barcode}}</strong></div>
          </div>
        </div>
      </div>
      <form [formGroup]="claimForm" novalidate>
        <mat-radio-group formControlName="claimType">
          <mat-radio-button value="RETURNED" class="claim-option"
                            [ngClass]="[(claimForm.controls.claimType?.value == 'RETURNED') ? 'claim-option-checked' : 'claim-option-unchecked']">
            <div class="claim-option-label">
              <h4 class="claim-option-label-action">I want to claim that this item has been returned.</h4>
              <div class="mat-small claim-option-label-text">
                <strong>I acknowledge</strong> that library staff will allocate 60 days during which the staff will search the library's collection at regular
                intervals for the item.
              </div>
            </div>
          </mat-radio-button>
          <mat-radio-button value="LOST" class="claim-option"
                            [ngClass]="[(claimForm.controls.claimType?.value == 'LOST') ? 'claim-option-checked' : 'claim-option-unchecked']">
            <div class="claim-option-label">
              <h4 class="claim-option-label-action">I want to claim that this item is lost or stolen.</h4>
              <div class="mat-small claim-option-label-text">
                <strong>I acknowledge</strong> that library staff will allocate 60 days during which staff and the patron share the responsibility of locating a
                missing library item. Staff will search the library's collection at regular intervals. You as a patron, in turn, are expected to thoroughly
                search
                your room, home, office, etc. for the item. If the item is not located at the end of the 60-day search period, the patron will be notified and
                may
                be required to assume responsibility for replacing the item.
              </div>
            </div>
          </mat-radio-button>
        </mat-radio-group>
        <div class="mat-small claim-comment-label">Comment</div>
        <mat-form-field appearance="outline" class="claim-comment-field">
          <textarea matInput formControlName="comment" maxlength="500" placeholder="Add a comment" class="claim-comment"></textarea>
        </mat-form-field>
        <div class="mat-small claim-length-hint">{{ length(claimForm.controls.comment?.value) }} / 500</div>
      </form>
      <button mat-raised-button color="primary" class="save-button-group save-button"
              [disabled]="claimForm.invalid" (click)="onSubmit()">Submit Claim
      </button>
    </div>
  `,
  styles: [`
    .claim-dialog {
      max-width: 746px;
      --side-margin: min(3vw, 32px);
    }

    .claim-header {
      width: 100%;
      background-color: var(--light-pearl);
    }

    .claim-header-field {
      min-width: 250px;
      padding: 0 0 16px 0;
    }

    .claim-title {
      padding: 20px var(--side-margin);
      margin-bottom: 0;
    }

    .claim-dialog .claim-header div.mat-display-2 {
      font-size: 36px;
    }

    .claim-x {
      padding: 20px var(--side-margin);
      background-color: var(--light-pearl);
    }

    .claim-details {
      //padding: 0 32px 16px;
      padding: 0 var(--side-margin)
    }

    .claim-details-label {
      max-width: 80px;
    }

    .claim-option {
      margin: 17px var(--side-margin) 0;
      border: 1px solid var(--dk-gray);
      padding: 13px 18px;
    }

    .claim-option.claim-option-unchecked {
      border-color: var(--med-gray-2)
    }

    .claim-option-label {
      white-space: normal;
        margin-left: 10px;
    }

    .claim-option-label h4 {
        margin-bottom: 0;
    }

    .claim-option-label-action {
        padding-bottom: 10px;
    }

    .claim-option-label-text {
        line-height: 18px;
    }

    .claim-comment-label {
        margin: 30px var(--side-margin) 10px;
    }

    .claim-comment-field {
        margin: 0 var(--side-margin);
        width: calc(100% - (2 * var(--side-margin)));
    }

    .claim-comment {
        min-height: 50px;
    }

    .claim-length-hint {
        margin: 8px var(--side-margin) 25px;
    }

    .save-button {
        margin: 0 var(--side-margin) var(--side-margin);
        width: calc(100% - (2 * var(--side-margin)));
        height: 65px;
    }
  `]
})
export class ClaimReturnedDialog {

  claimForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ClaimReturnedDialog>,
    public routesService: RoutesService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: {
      checkoutId: number;
      circulationItemId: number;
      title: string,
      author: string,
      isbn: string,
      barcode: string
    }) {
    this.claimForm = this.fb.group({
      claimType: [null, {validators: [Validators.required]}],
      comment: ['', {validators: []}],
    });
  }

  length(str): number {
    return str ? str.length : 0;
  }

  onSubmit(): void {
    const request: CirculationItemClaimedReturned = {
      checkoutId: this.data.checkoutId,
      claimType: this.claimForm.value.claimType,
      comment: this.claimForm.value.comment
    };
    this.dialogRef.close(request);
  }
}
