import {Component, Input, OnInit} from '@angular/core';
import {MaterialType} from '../../../model/material-type';

enum MaterialTypeIconSource {
  GOOGLE,
  MATERIAL_DESIGN_ICONS,
}

interface MaterialTypeIconConfig {
  source: MaterialTypeIconSource,
  iconString: string,
}

@Component({
  selector: 'rn-material-type-icon',
  templateUrl: './material-type-icon.component.html',
  styleUrls: ['./material-type-icon.component.scss'],
})
export class MaterialTypeIconComponent implements OnInit {
  @Input() materialType: MaterialType;

  materialTypeNameToIconConfig: { [key: string]: MaterialTypeIconConfig };

  constructor() {
    this.materialTypeNameToIconConfig = {
      'BKS': {source: MaterialTypeIconSource.MATERIAL_DESIGN_ICONS, iconString: 'book-open-page-variant-outline'},
      'NEW': {source: MaterialTypeIconSource.MATERIAL_DESIGN_ICONS, iconString: 'newspaper'},
      'PER': {source: MaterialTypeIconSource.MATERIAL_DESIGN_ICONS, iconString: 'newspaper'},
      'SER': {source: MaterialTypeIconSource.MATERIAL_DESIGN_ICONS, iconString: 'image-text'},
      'CDA': {source: MaterialTypeIconSource.MATERIAL_DESIGN_ICONS, iconString: 'disc'},
      'DVV': {source: MaterialTypeIconSource.MATERIAL_DESIGN_ICONS, iconString: 'disc'},
      'BTA': {source: MaterialTypeIconSource.MATERIAL_DESIGN_ICONS, iconString: 'video-vintage'},
      'UKN': {source: MaterialTypeIconSource.MATERIAL_DESIGN_ICONS, iconString: 'help-circle-outline'},
    }
  }

  ngOnInit(): void {
  }
}
