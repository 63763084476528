import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';

@Component({
  selector: 'rn-fallback-image',
  templateUrl: './fallback-image.component.html',
  styleUrls: ['./fallback-image.component.scss'],
})
export class FallbackImageComponent implements AfterViewInit {

  @Input() imageUrls: string[];
  @Input() alt: string;
  urlIdx = 0;
  @ViewChild('img') img: ElementRef;

  constructor() {
  }

  ngAfterViewInit() {
    const img = this.img.nativeElement as HTMLImageElement;
    img.addEventListener('load', () => {
      if (img.naturalWidth == 1 || img.naturalHeight == 1) {
        this.nextUrl();
      }
    });
    img.addEventListener('error', () => {
      this.nextUrl();
    });
    this.setStockImageStyles();
  }

  private nextUrl(): void {
    const initialUrl = this.imageUrls[this.urlIdx];
    if (this.urlIdx < (this.imageUrls.length - 1)) {
      this.urlIdx++;
    }
    // iterated until it finds a new url to try or runs out
    while ((this.urlIdx < (this.imageUrls.length - 1)) && this.imageUrls[this.urlIdx] == initialUrl) {
      this.urlIdx++;
    }
    this.setStockImageStyles();
  }

  private setStockImageStyles() {
    // the last image url should be one of our stock images which we can maintain aspect ratio and center vertically
    if (this.urlIdx == this.imageUrls.length - 1) {
      const img = this.img.nativeElement as HTMLImageElement;
      img.setAttribute('style', 'max-width: 100%; max-height: 277px; width: auto; height: auto; margin: auto 0;');
    }
  }

}
