import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Hold, HoldService, RoutesService} from '@raven';

/**
 * DELETE HOLD DIALOG COMPONENT
 */
@Component({
  selector: 'rn-delete-hold-dialog',
  template: `
    <div class="flex-col">
      <h3>Delete Hold{{ deletableHolds.length | plural }} ({{ deletableHolds.length }})</h3>
      <div class="flex-col flex-start-start">
        <ng-container *ngIf="cantDelete > 0">
          <div class="warn-box dialog-warn-box flex-row flex-center-start">
            <span>{{ cantDelete }} of the selected items cannot be deleted.</span>
          </div>
        </ng-container>
        <span class="Text-Style dialog-body">
                Are you sure you want to delete {{ deletableHolds.length }} item hold{{deletableHolds.length | plural}}?
              </span>
        <span class="Text-Style dialog-body">
                Deleting {{deletableHolds.length > 1 ? 'these items' : 'this item'}}
          is not a reversible action. Once it is deleted {{deletableHolds.length > 1 ? 'they' : 'it'}} cannot be recovered.
              </span>
      </div>
      <div class="dialog-button-row">
        <button mat-stroked-button class="save-button-group button-cancel" (click)="onNoClick()">
          No
        </button>
        <button mat-raised-button color="warn" class="save-button-group save-button" (click)="onDelete()">
          Yes, Delete
        </button>
      </div>
    </div>
  `,
  styles: [``],
})
export class DeleteHoldDialog {
  deletableHolds: Hold[];
  cantDelete: number;

  constructor(
    public dialogRef: MatDialogRef<DeleteHoldDialog>,
    private holdService: HoldService,
    public routesService: RoutesService,
    @Inject(MAT_DIALOG_DATA) public data: { holds: Hold[]; refreshHolds: () => void }
  ) {
    this.deletableHolds = HoldService.getDeletableHolds(data.holds);
    this.cantDelete = data.holds.length - this.deletableHolds.length;
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onDelete(): void {
    this.holdService.delete(
      this.deletableHolds.map((hold) => hold.id),
      {onSuccess: this.onSuccess.bind(this)}
    );
  }

  onSuccess(result: boolean): void {
    if (result) {
      this.data.refreshHolds();
      this.dialogRef.close(true);
    }
  }
}
