import {Component, Input, OnInit} from '@angular/core';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {Subject, takeUntil} from 'rxjs';
import {CatalogRecordAvailabilityForBranch} from '@raven';

@Component({
  selector: 'rn-catalog-details-branch-availability',
  templateUrl: './catalog-details-branch-availability.component.html',
  styleUrls: ['./catalog-details-branch-availability.component.scss'],
})
export class CatalogDetailsBranchAvailabilityComponent implements OnInit {
  @Input()
  branchAvailability: CatalogRecordAvailabilityForBranch;
  defaultColumns = ['call-number', 'shelf', 'status'];
  mobileColumns = ['mobile-expand'];
  lastCircItemId: number;
  destroy$ = new Subject();
  mobile = false;
  displayColumns = this.defaultColumns;

  constructor(
    private breakpointObserver: BreakpointObserver,
  ) {
  }

  ngOnInit(): void {
    if (this.branchAvailability && this.branchAvailability.circulationItems) {
      const items = this.branchAvailability.circulationItems;
      this.lastCircItemId = items[items.length - 1].id;
    }

    this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.mobile = true;
          this.displayColumns = this.mobileColumns
        } else {
          this.mobile = false;
          this.displayColumns = this.defaultColumns;
        }
      });
  }

  mobileWhen() {
    return this.mobile;
  }
}
