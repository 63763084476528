import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {NotificationService, PatronService} from '@raven';

/**
 * PIN UPDATE DIALOG COMPONENT
 */
@Component({
  selector: 'fn-pin-update-dialog',
  template: `
    <div mat-dialog-content>
      <mat-card-title> Update PIN</mat-card-title>
      <mat-card-content>
        <form [formGroup]="pinUpdateForm" (keydown.enter)="onSubmit()">
          <div class="flex-col flex-start-end" style="padding-top: 20px">
            <!-- new PIN -->
            <mat-label>New PIN</mat-label>
            <mat-form-field appearance="outline">
              <input matInput type="password" formControlName="pin" required maxlength="10"/>
              <mat-error *ngIf="pinUpdateForm.get('pin').hasError('required')">PIN is required</mat-error>
              <mat-error *ngIf="pinUpdateForm.get('pin').hasError('minLength')">PIN must be at least 4 numbers</mat-error>
              <mat-error *ngIf="pinUpdateForm.get('pin').hasError('maxLength')">PIN must be less than 11 numbers</mat-error>
            </mat-form-field>
          </div>
          <h5>PIN must be between 4 - 10 digits</h5>
                  <mat-card-actions align="end" style="margin-bottom: 0; margin-top: 15px;" class="button-row-right">
                      <button mat-stroked-button class="ButtonSmallBlack save-button-group button-cancel" (click)="onNoClick()" data-cy="on-hold-button">
                          Cancel
                      </button>
                      <button mat-raised-button color="primary" class="ButtonSmallBlack save-button-group save-button" [disabled]="!this.pinUpdateForm.valid"
                              (click)="onSubmit()">
                          Update PIN
                      </button>
                  </mat-card-actions>
              </form>
          </mat-card-content>
      </div>
  `,
  styles: [``],
})
export class PinUpdateDialog {
  pinUpdateForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<PinUpdateDialog>,
    private patronService: PatronService,
    private fb: FormBuilder,
    private notificationService: NotificationService
  ) {
  }

  ngOnInit() {
    this.pinUpdateForm = this.fb.group({
      pin: [
        '',
        {
          validators: [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(10),
          ],
        },
      ],
    });
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onSubmit(): void {
    const pin = this.pinUpdateForm.value.pin;

    // Check if we have a valid pin
    if (PinUpdateDialog.validPin(pin)) {
      this.patronService.updatePin(pin, {
        onSuccess: (success) => {
          if (success) {
            this.notificationService.showSnackbarSuccess('PIN has been updated');
          } else {
            this.notificationService.showSnackbarError('Unable to update PIN. Please try again later.');
          }
          this.dialogRef.close(success);
        },
        errorMessage: 'Unable to update PIN. Please try again later.'
      });
    } else {
      this.notificationService.showSnackbarError(
        'Invalid PIN entered. Length must be between 4 - 10 digits long and cannot contain any characters.'
      );
    }
  }

  private static validPin(pin: string): boolean {
    const onlyDigits = /^\d+$/.test(pin);
    return onlyDigits && pin.length > 3 && pin.length < 11;
  }
}
