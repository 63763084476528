<div class="catalog-home">
    <div class="catalog-home-banner hide-xs">
      <div class="mat-display-3 catalog-home-title">
        Explore our<br/>
        collection
      </div>
      <h1 class="catalog-home-subtitle">
        Search, browse and place items on<br/>
        hold with our library catalog.
      </h1>
    </div>
  <div class="catalog-mobile-header mat-display-1 hide-gt-xs">
    Catalog
  </div>
    <div class="search-bar">
      <div class="search-center flex-row" [formGroup]="searchForm">
        <mat-select formControlName="searchType">
          <mat-option value="keyword" selected>Keyword</mat-option>
          <mat-option value="title">Title</mat-option>
          <mat-option value="author">Author</mat-option>
          <mat-option value="isbn">ISBN</mat-option>
        </mat-select>
        <input (keydown.enter)="doSearch()" formControlName="searchTerm" placeholder="Search term"/>
        <button (click)="doSearch()">
          <img alt="magnifying glass" src="/assets/magnifying-glass.svg"/>
        </button>
        </div>
    </div>
  <div class="catalog-catagories flex-row-wrap flex-center-center hide-xs">
    <button mat-stroked-button class="ButtonSmallBlack" *ngFor="let category of PATRON_STATUS | keyvalue">
      {{ category.value }}
    </button>
  </div>
    <mat-expansion-panel hideToggle class="catalog-mobile-categories-panel">
      <mat-expansion-panel-header class="catalog-mobile-categories-title hide-gt-xs">
        <img alt="" src="/assets/browse.svg" class="browse-icon">
        <span class="browse-title">Browse Sections</span>
        <img alt="" src="/assets/chevron-bottom.svg" class="browse-chevron">
      </mat-expansion-panel-header>
      <div class="catalog-catagories flex-row-wrap flex-center-space-around">
        <button mat-stroked-button class="ButtonSmallBlack" *ngFor="let category of PATRON_STATUS | keyvalue">
          {{ category.value }}
        </button>
      </div>
    </mat-expansion-panel>

    <div *ngIf="!(loading$ | async); else loading_template">
        <div class="carosel-container">
            <rn-book-carousel [catalogRecords]="popularTitles" title="Popular Titles"></rn-book-carousel>
            <hr class="catalog-hr"/>
            <rn-book-carousel [catalogRecords]="recentlyAdded" title="Recently Added"></rn-book-carousel>
            <hr class="catalog-hr"/>
            <rn-author-carousel [authors]="popularAuthors" title="Popular Authors"></rn-author-carousel>
            <hr class="catalog-hr"/>
            <rn-book-carousel [catalogRecords]="booksOnOrder" title="On-Order Items"></rn-book-carousel>
            <hr class="catalog-hr"/>
            <rn-popular-subject-carousel [subjects]="popularSubjects" title="Popular Subjects"></rn-popular-subject-carousel>
        </div>
    </div>
    <rn-catalog-footer></rn-catalog-footer>
    <ng-template #loading_template>
        <div class="flex-container" style="position: relative">
            <mat-spinner></mat-spinner>
        </div>
    </ng-template>
</div>
