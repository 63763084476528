<div>
  <ul>
    <div *ngFor="let value of facet.values.slice(0, displayCount)">
      <li class="flex-row flex-center-start">
        <div class="flex-col">
          <input
            *ngIf="showCheckboxes"
            [checked]="value.selected"
            (change)="value.selected = !value.selected; notifyChanged()"
            type="checkbox"
          />
        </div>
        {{ value.displayValue }} ({{value.count}})
      </li>
    </div>

    <li class="view-control">
      <span
        *ngIf="displayCount < facet.values.length"
        (click)="displayCount = facet.values.length"
      >+ View More</span
      >
      <span *ngIf="displayCount === facet.values.length" (click)="displayCount = 5"
      >- View Less</span
      >
    </li>
  </ul>
</div>
